import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class ApiRequestService {

  constructor(private http: HttpClient, private url: string) { }
  
  public post<T>(body: T): Observable<T> {
    return this.http.post<T>(`${environment.url + this.url}`, body);
  }
  
  public put<T>(body: any, setId: boolean = true): Observable<T> {

    let id = setId ? body.id + '/': '';
    return this.http.put<T>(`${environment.url + this.url + id}`, body);
  }
  
  public putFormData<T>(body: any, id: number): Observable<T> {
    return this.http.put<T>(`${environment.url + this.url + id}/`, body);
  }
  
  public patch<T>(body: any, setId: boolean = true): Observable<T> {
    let id = setId ? body.id + '/': '' 
    return this.http.patch<T>(`${environment.url + this.url + id}`, body);
  }
  
  public get<T>(id: any = false): Observable<T> {
    id = id? id+'/': '';
    return this.http.get<T>(`${environment.url + this.url + id}`);
  }
  
  public delete(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.url + this.url + id}/`);
  }
  
  public deleteFilter(id: number, filter: string): Observable<any> {
    return this.http.delete<any>(`${environment.url + this.url + id}/?${filter}`);
  }
}
