import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
    monthsFull: Array<string> = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    monthsAbv: Array<string> = ['Jan.', 'Fev.', 'Mar.', 'Abr.', 'Mai.', 'Jun.', 'Jul.', 'Ago.', 'Set.', 'Out.', 'Nov.', 'Dez.'];
    weekDaysFull: Array<string> = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    weekDaysAbv: Array<string> = ['Dom.', 'Seg.', 'Ter.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.'];

    getDateString(current: Date = new Date(), d: string = 'd', m: string = 'M') {
        const day = d == 'd'? this.weekDaysAbv[current.getDay()]: this.weekDaysFull[current.getDay()], 
        date = current.getDate()<10? `0${current.getDate()}`: current.getDate(), 
        month = m == 'm'? this.monthsAbv[current.getMonth()]: this.monthsFull[current.getMonth()], 
        year = current.getFullYear(); 
        return `${day} ${date} ${month} ${year}`;
    }
}