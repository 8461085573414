import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BannerReadCommand } from 'src/app/commands/banner-read.command';
import { BannerReadModel } from 'src/app/models/banner-read.model';
import { UserModel } from 'src/app/models/user.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { EventsService } from 'src/app/services/utils/events.service';

@Component({
  selector: 'app-banner-dashboard',
  templateUrl: './banner-dashboard.component.html',
  styleUrls: ['./banner-dashboard.component.scss']
})
export class BannerDashboardComponent implements OnInit {
  @Input() banners: BannerReadModel[];

  banners_selected: BannerReadModel[] = [];

  constructor(private eventsService: EventsService,
    private bannerReadCommand: BannerReadCommand,
  ) {
  }

  ngOnInit(): void {
    this.rendeBanner();

    this.eventsService.get_event().subscribe((event: string) => {
      if (event == 'rendeBanner') this.rendeBanner();
    })
  }

  rendeBanner() {
    this.banners_selected = this.banners;
    let listExhibitBanners: Array<any> = JSON.parse(localStorage.getItem('exhibitBanners')) ?? [];

    this.banners_selected = this.banners_selected.filter(item => {
      let banner = listExhibitBanners.find(bn => bn.id == item.id && (this.diffHours((new Date(bn.date)), (new Date())) < 4));
      if(banner){
        return false;
      }
      return item.type == 3;
    });
  }

  close(banner: BannerReadModel){
    if(banner.expired_date){
      this.bannerReadCommand.setRead(banner.id);
    }else{
      let lastExhibitBanner = {id: banner.id, date: moment(new Date()).format('YYYY-MM-DD H:m:s')},
      listExhibitBanner: Array<any> = JSON.parse(localStorage.getItem('exhibitBanners')) ?? [];
      
      if(listExhibitBanner.length){
        listExhibitBanner.forEach((item, index) => {
          if(item.id == banner.id)
            listExhibitBanner[index] = lastExhibitBanner;
        });
      }else{
        listExhibitBanner.push(lastExhibitBanner);
      }
      localStorage.setItem('exhibitBanners', JSON.stringify(listExhibitBanner));
    }

    this.banners_selected = this.banners_selected.filter(item => item.id != banner.id);
  }

  diffHours(d1: any, d2: any) {
    const _MS_PER_HOUR = 1000 * 60 * 60;
    return Math.floor((d2 - d1) / _MS_PER_HOUR);
  }
}
