import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class PlanCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get() {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account/plan/");

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    getPublic(id: number = null) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account/plan/public/${id? id+'/': ''}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}