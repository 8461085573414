import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AlertModel } from 'src/app/models/alert.model';
import { AlertEvent } from 'src/app/services/utils/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertEvent: AlertEvent) { }
  
  async presentAlert(type: string, title: string, description: string, text_button: any = "", time: number = 5) {
    return new Promise(async (resolve) => {
        let alert: AlertModel = {
            description: description,
            time: time,
            type: type
        };
        this.alertEvent.setShowAlert(alert);

        resolve(true);
    });

}
}
