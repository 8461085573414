import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanCommand } from 'src/app/commands/plan.command';
import { TermUseCommand } from 'src/app/commands/term-use.command';
import { PlanModel } from 'src/app/models/plan.model';
import { ModalService } from 'src/app/services/utils/modal.service';
import { TermsComponent } from '../../terms/terms.component';
import { TermUseModel } from 'src/app/models/term-use.model';

@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrls: ['./card-plan.component.scss']
})
export class CardPlanComponent implements OnInit {
  plans: PlanModel[] = [];
  filter_plans: PlanModel[] = [];
  selected_plan: PlanModel;
  termUse: TermUseModel;

  constructor(
    private router: Router,
    private planCommand: PlanCommand,
    private termUseCommand: TermUseCommand,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    Promise.all([
      this.planCommand.get(),
      this.termUseCommand.get(),  
    ]).then(([result, term]: [any, TermUseModel]) => {
      if(term)
        this.termUse = term;
      this.plans = result.results;
      this.filterPlan('asset');
    });
  }

  openPlan(plan:PlanModel) {
    this.router.navigate(['pagamento', plan.id]);
  }

  openModal() {
    this.modalService.setContent(this.termUse);
    this.modalService.openModal(TermsComponent, '800px', '90vh');
  }

  filterPlan(type: string) {
    if(type == 'asset')
      this.filter_plans = this.plans.filter(item => !item.is_stock);
    else
      this.filter_plans = this.plans.filter(item => item.is_stock);

    this.selected_plan = this.filter_plans[0];
  }
}
