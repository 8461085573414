import { Component, Inject, OnInit } from '@angular/core';
import { UserCommand } from 'src/app/commands/user.command';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from 'src/app/components/alert/alert.service';
import { PlanModel } from 'src/app/models/plan.model';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AccountInfoComponent } from 'src/app/components/account-info/account-info.component';
import { WarningActiveComponent } from 'src/app/components/warning-active/warning-active.component';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { ModalVideoComponent } from 'src/app/components/modal-video/modal-video.component';
import { BlackArrowReleaseModel } from 'src/app/models/black-arrow-release.model';
import { UserModel } from 'src/app/models/user.model';
import { EventsService } from 'src/app/services/utils/events.service';
import { DateFormatService } from 'src/app/services/utils/date-format.service';
import { BannerReadCommand } from 'src/app/commands/banner-read.command';
import { BannerReadModel } from 'src/app/models/banner-read.model';
import { ModalBannerDashboardComponent } from 'src/app/components/modal-banner-dashboard/modal-banner-dashboard.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: UserModel;
  username: string = '';
  atual_plan: PlanModel = null;
  list_account_operation: AccountOperationModel[] = [];
  filter_accounts: AccountOperationModel[] = [];
  filter_blackarrow: BlackArrowReleaseModel[] = [];
  show_see_less: boolean = false;
  configuration: ConfigurationModel;
  typePlan: string = 'asset';
  welcome_back: boolean = false;
  show_account_type: string = 'enabled';
  banners: BannerReadModel[];

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private firebaseService: FirebaseService,
    private modalService: ModalService,
    private userCommand: UserCommand,
    public pagesContentService: PagesContentService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private configurationCommand: ConfigurationCommand,
    private eventsService: EventsService,
    public dateFormatService: DateFormatService,
    private bannerReadCommand: BannerReadCommand,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('home');

    this.getData();
    this.getConfiguration();

    this.eventsService.get_event().subscribe((event: string) => {
      if (event == 'show_button_credentials') 
        this.getData();
    })
  }

  getData() {
    if (localStorage.getItem('show_account_type')) {
      this.show_account_type = localStorage.getItem('show_account_type');
    }
    if (localStorage.getItem('atual_plan')) {
      this.atual_plan = JSON.parse(localStorage.getItem('atual_plan')) as PlanModel;
    }

    Promise.all([
      this.accountOperationCommand.get(),
      this.accountOperationCommand.blackArrowRelease(),
      this.userCommand.get(),
      this.bannerReadCommand.get()
    ]).then(([accounts, releases, user, banner]: [AccountOperationModel[], any, UserModel, any]) => {
      if(releases && releases.results) {
        this.filter_blackarrow = releases.results as BlackArrowReleaseModel[];
      }
      if (accounts) {
        this.list_account_operation = accounts;
        this.filterAccounts(this.show_account_type);
      }
      if(user){
        this.user = user;
        this.username = this.user.name.split(' ')[0];
        let date = new Date();
        date.setDate(date.getDate() -7 );
        this.welcome_back = this.user.last_login != null && (new Date(this.user.last_login) <= date);
      }

      if (banner && banner.results) {
        this.banners = banner.results;
        
        let tempBanners;

        tempBanners = this.banners.filter((bn) => {
          if (bn.type == 2) return bn;
        });

        if (tempBanners.length) {

          let dateLastViewTip: Date = new Date(JSON.parse(localStorage.getItem("dateLastViewTip")));

          if (dateLastViewTip != null) {
            dateLastViewTip.setHours(dateLastViewTip.getHours() + 4);

            if (new Date() < dateLastViewTip) {
              return;
            }
          }

          localStorage.setItem("dateLastViewTip", JSON.stringify(new Date()));

          this.modalService.setContent(tempBanners[0]);
          this.modalService.openModal(ModalBannerDashboardComponent, "600px");
        }
      }
    });
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });
  }

  filterAccounts(filter: string = 'enabled') {
    if(!this.list_account_operation)
      return;

    localStorage.setItem('show_account_type', filter);
    if(filter == 'enabled')
      this.filter_accounts = this.list_account_operation.filter(x => x.status == 2 || x.status == 8);
    if(filter == 'disabled')
      this.filter_accounts = this.list_account_operation.filter(x => x.status != 2 && x.status != 8);
    if(filter == 'all')
      this.filter_accounts = this.list_account_operation;
  }

  openInNewTab(route: string, account: AccountOperationModel) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    if(account.accept_value_retention || account.coupon_id)
      window.open(url, '_blank');
    else{
      this.modalService.setContent({url, account});
      this.modalService.openModal(WarningActiveComponent, '800px', '400px');
    }
  }

  openTutorial() {
    this.modalService.setContent({title: 'Bem vindo(a) ao Insider Invest', message: '', url: this.configuration.url_tutorial_platform});
    this.modalService.openModal(ModalVideoComponent, '700px');
  }

  viewCredentials(account: AccountOperationModel) {
    this.modalService.setContent(account);
    this.modalService.openModal(AccountInfoComponent, '800px', '400px');
  }
}
