<div class="content-body">
    <div class="button-close" (click)="closeModal()"><i class="fa-solid fa-xmark over-text"></i></div>
    <div class="content-body-title" *ngIf="type == 1">Agendamento confirmado</div>
    <div class="content-body-title" *ngIf="type == 2">Início do teste confirmado</div>
    <div class="content-body-text over-text">
        <span *ngIf="type == 1">
            Seu agendamento foi confirmado para o dia {{schedule| date: 'dd/MM/yyyy'}}. Na data programada sua conta será disponibilizada, para ter acesso a ela, basta acessar a aba "Ativas"
        </span>
        <span *ngIf="type == 2">
            Seu agendamento para dar início hoje no seu teste foi confirmado. Para consultar os dados da sua conta e de configuração do início do teste, basta acessar a aba "Ativas"
        </span>
    </div>
</div>