import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { GenerateNewPasswordComponent } from './pages/generate-new-password/generate-new-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { UserDataComponent } from './pages/user-data/user-data.component';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import localePt from '@angular/common/locales/pt';// IMPORTANT
import { registerLocaleData } from '@angular/common';
import { Interceptor } from './interceptor/http.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { AlertComponent } from './components/alert/alert.component';
import { AccountConfirmComponent } from './pages/account-confirm/account-confirm.component';
import { ModalService } from './services/utils/modal.service';
import { ConfirmDeleteComponent } from './pages/user-data/confirm-delete/confirm-delete.component';
import { EvolutionBarComponent } from './components/evolution-bar/evolution-bar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CleanCurrencyPipe } from './services/pipes/clean-currency.pipe';
import { FirebaseModule } from './firebase.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ResetInfoComponent } from './components/reset-info/reset-info.component';
import { MetavisionComponent } from './pages/metavision/metavision.component';
import { WarningDashboardComponent } from './components/warning-dashboard/warning-dashboard.component';
import { SupportInfoComponent } from './components/support-info/support-info.component';
import { ActiveNotificationsComponent } from './pages/active-notifications/active-notifications.component';
import { ActiveAlgotradingComponent } from './pages/active-augotrading/active-algotrading.component';
import { UserRegisterComponent } from './pages/user-register/user-register.component';
import { BannerDashboardComponent } from './components/banner-dashboard/banner-dashboard.component';
import { UpdateMetavisionComponent } from './pages/update-metavision/update-metavision.component';
import { BannerModalComponent } from './components/banner-modal/banner-modal.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MetaVisionTravandoComponent } from './pages/faq/meta-vision-travando/meta-vision-travando.component';
import { HabilitarAlgotradingComponent } from './pages/faq/habilitar-algotrading/habilitar-algotrading.component';
import { ButtonIconLabelComponent } from './components/button-icon-label/button-icon-label.component';
import { CardPlanComponent } from './pages/home/card-plan/card-plan.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { PlansComponent } from './pages/plans/plans.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentMessageComponent } from './pages/payment/payment-message/payment-message.component';
import { LogAccountComponent } from './components/log-account/log-account.component';
import { EnabledAccountComponent } from './pages/enabled-account/enabled-account.component';
import { CreditCardComponent } from './pages/credit-card/credit-card.component';
import { HistoryTransactionsComponent } from './pages/history-transactions/history-transactions.component';
import { HideInfoPipe } from './services/pipes/hide-info.pipe';
import { ConfirmDeleteCardComponent } from './pages/credit-card/confirm-delete-card/confirm-delete-card.component';
import { PixModalComponent } from './pages/history-transactions/pix-modal/pix-modal.component';
import { RulesObjectivesComponent } from './pages/rules-objectives/rules-objectives.component';
import { TableRulesObjectivesComponent } from './components/table-rules-objectives/table-rules-objectives.component';
import { AssetsAllowedComponent } from './pages/assets-allowed/assets-allowed.component';
import { TableAssetsComponent } from './components/table-assets/table-assets.component';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
import { EnabledAccountManualComponent } from './pages/enabled-account-manual/enabled-account-manual.component';
import { ModalPayErrorComponent } from './components/modal-pay-error/modal-pay-error.component';
import { TableOperationsComponent } from './components/table-operations/table-operations.component';
import { ImportComponent } from './pages/import/import.component';
import { SupportButtonComponent } from './components/support-button/support-button.component';
import { PixComponent } from './pages/payment/pix/pix.component';
import { BoletoComponent } from './pages/payment/boleto/boleto.component';
import { HistoryImportComponent } from './pages/history-import/history-import.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { WithdrawHistoryComponent } from './pages/withdraw/withdraw-history/withdraw-history.component';
import { RequestModalComponent } from './pages/withdraw/request-modal/request-modal.component';
import { ConfirmRequestComponent } from './pages/withdraw/confirm-request/confirm-request.component';
import { AccountLeverageComponent } from './pages/account-leverage/account-leverage.component';
import { EnabledAccountMobileComponent } from './pages/enabled-account-mobile/enabled-account-mobile.component';
import { ConsistencyComponent } from './pages/consistency/consistency.component';
import { ConsistencyApprovedComponent } from './pages/consistency-approved/consistency-approved.component';
import { DrawdownComponent } from './pages/drawdown/drawdown.component';
import { ModalAccountPaymentComponent } from './components/modal-account-payment/modal-account-payment.component';
import { SidenavWebComponent } from './components/sidenav-web/sidenav-web.component';
import { PerformanceRulesObjectivesComponent } from './components/performance-rules-objectives/performance-rules-objectives.component';
import { DownloadComponent } from './pages/download/download.component';
import { WarningActiveComponent } from './components/warning-active/warning-active.component';
import { ModalBannerDashboardComponent } from './components/modal-banner-dashboard/modal-banner-dashboard.component';
import { PaymentBlackFridayComponent } from './pages/payment-black-friday/payment-black-friday.component';
import { ConfirmScheduleComponent } from './pages/home/confirm-schedule/confirm-schedule.component';
import { RequestScheduleComponent } from './pages/home/request-schedule/request-schedule.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { CertificateComponent } from './pages/certificate/certificate.component';
import { ListChampionshipComponent } from './pages/championship/list-championship/list-championship.component';
import { RuleChampionshipComponent } from './pages/championship/rule-championship/rule-championship.component';
import { RankChampionshipComponent } from './pages/championship/rank-championship/rank-championship.component';
import { InscriptionComponent } from './pages/championship/inscription/inscription.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';
import { ReceiptModalComponent } from './pages/withdraw/receipt-modal/receipt-modal.component';
import { NgxPrintModule } from 'ngx-print';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPortuguesePaginatorIntl } from './services/utils/portuguese-paginator-initl';

registerLocaleData(localePt);

@NgModule({ 
    declarations: [
        AppComponent,
        LoginComponent,
        RecoverPasswordComponent,
        GenerateNewPasswordComponent,
        ChangePasswordComponent,
        UserDataComponent,
        SimpleHeaderComponent,
        AuthHeaderComponent,
        HomeComponent,
        NotFoundComponent,
        MaintenanceComponent,
        AlertComponent,
        AccountConfirmComponent,
        ConfirmDeleteComponent,
        EvolutionBarComponent,
        SidenavComponent,
        SidenavWebComponent,
        CleanCurrencyPipe,
        ResetInfoComponent,
        MetavisionComponent,
        WarningDashboardComponent,
        BannerDashboardComponent,
        SupportInfoComponent,
        ActiveNotificationsComponent,
        ActiveAlgotradingComponent,
        UserRegisterComponent,
        UpdateMetavisionComponent,
        BannerModalComponent,
        FaqComponent,
        MetaVisionTravandoComponent,
        HabilitarAlgotradingComponent,
        ButtonIconLabelComponent,
        CardPlanComponent,
        AccountInfoComponent,
        PerformanceComponent,
        PlansComponent,
        PaymentComponent,
        PaymentMessageComponent,
        LogAccountComponent,
        EnabledAccountComponent,
        EnabledAccountManualComponent,
        EnabledAccountMobileComponent,
        CreditCardComponent,
        HistoryTransactionsComponent,
        HideInfoPipe,
        ConfirmDeleteCardComponent,
        PixModalComponent,
        RulesObjectivesComponent,
        TableRulesObjectivesComponent,
        AssetsAllowedComponent,
        TableAssetsComponent,
        ModalVideoComponent,
        ModalPayErrorComponent,
        TableOperationsComponent,
        ImportComponent,
        SupportButtonComponent,
        PixComponent,
        BoletoComponent,
        HistoryImportComponent,
        TermsComponent,
        AssetsComponent,
        TermsConditionsComponent,
        WithdrawHistoryComponent,
        RequestModalComponent,
        ConfirmRequestComponent,
        AccountLeverageComponent,
        ConsistencyComponent,
        ConsistencyApprovedComponent,
        DrawdownComponent,
        ModalAccountPaymentComponent,
        PerformanceRulesObjectivesComponent,
        DownloadComponent,
        WarningActiveComponent,
        ModalBannerDashboardComponent,
        PaymentBlackFridayComponent,
        ConfirmScheduleComponent,
        RequestScheduleComponent,
        CertificateComponent,
        ListChampionshipComponent,
        RuleChampionshipComponent,
        RankChampionshipComponent,
        InscriptionComponent,
        UnsubscribeComponent,
        ReceiptModalComponent,
    ],
    bootstrap: [AppComponent], 
    imports: [
        FirebaseModule,
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        MaterialModule,
        BrowserAnimationsModule,
        FormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        RecaptchaV3Module,
        RecaptchaModule,
        RecaptchaFormsModule,
        NgxPrintModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID, useValue: 'pt-BR'
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lcw5bMqAAAAABvn47vskT6nj5uOlADLaNBQPk94'
        },
        {
            provide: MatPaginatorIntl,
            useValue: getPortuguesePaginatorIntl()
        },
        ModalService,
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
