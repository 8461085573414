import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-confirm-schedule',
  templateUrl: './confirm-schedule.component.html',
  styleUrls: ['./confirm-schedule.component.scss']
})
export class ConfirmScheduleComponent {
  type: number;
  schedule: string;
  constructor(
    private modalService: ModalService,
  ) {
    const {type, schedule}= this.modalService.getContent(); //type: 1 - agendado, 2 - inicio imediato
    this.type = type;
    this.schedule = schedule;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
