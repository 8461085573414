import { Component, Input, OnInit } from '@angular/core';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'evolution-bar',
  templateUrl: './evolution-bar.component.html',
  styleUrls: ['./evolution-bar.component.scss']
})
export class EvolutionBarComponent implements OnInit {
  @Input() value: number = 0;
  @Input() minLabel: string = '0'
  @Input() maxLabel: string = '100'
  @Input() color: string = 'primary';
  
  progress: string = `0%`;

  constructor(
    private sidenavService: SidenavService,
  ){}

  ngOnInit(){
    this.sidenavService.getProgressBar().subscribe(loading => {
      if(!loading){
        if(this.value > 100)  this.value = 100;
        setTimeout(() => {
          this.progress = `calc(${this.value}% ${this.value != 0? '+ 2px': ''})`;
        }, 500);
      }
    })
  }
}
