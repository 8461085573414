import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserPasswordCommand } from 'src/app/commands/user-password.command';
import { UserNewPasswordModel } from 'src/app/models/user-new-password.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-generate-new-password',
  templateUrl: './generate-new-password.component.html',
  styleUrls: ['./generate-new-password.component.scss']
})
export class GenerateNewPasswordComponent implements OnInit {

  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  messageError: string = '';
  token: string;

  constructor(
    public inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    private routeInfo: ActivatedRoute,
    private firebaseService: FirebaseService,
    private userPasswordCommand: UserPasswordCommand,) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('gerar-nova-senha')
    this.routeInfo.queryParams.subscribe((params: any) => {
      if (params) {
        this.token = params.token;
      }
    });
    this.formValidation = this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(8)]],
      password2: [null, [Validators.required]],
    }, { validator: this.inputFormService.matchingPasswords('password', 'password2') });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  submit() {
    this.firebaseService.eventClick('submit-new-password', 'gerar-nova-senha');
    this.submitted = true;
    this.messageError = '';

    if (this.formValidation.valid) {

      let userNewPasswordModel: UserNewPasswordModel = this.formValidation.value as UserNewPasswordModel;
      userNewPasswordModel.token = this.token;
      this.userPasswordCommand.generateNewPassword(userNewPasswordModel).then((result: any) => {
        if(result && result.message) {
          this.messageError = result.message;
        }
      });
    }
  }

}
