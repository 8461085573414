<section class="popular">
    <div class="first-item"></div>
    <div *ngFor="let plan of plans" [class.popular-active]="plan.is_popular"><span *ngIf="plan.is_popular">Mais popular</span></div>
</section>
<div class="card">
    <section class="card-header">
        <div class="first-item">Tamanho da conta</div>
        <div *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">$ {{plan.value_account | number:'1.2-2' }}</div>
    </section>
    <mat-accordion class="mat-accordion">
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(0)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[0]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[0]" src="assets/icons/show-less.svg" alt="">
                            <span>Meta de lucro</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">
                            $ {{plan.profit_target | number:'1.2-2'}}
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content" *ngFor="let plan of plans;let isLast = last">
                <strong>No plano {{plan.title}}</strong> sua meta de lucro é de $ {{plan.profit_target | number:'1.2-2' }}. Você recebe uma
                conta de $ {{plan.value_account | number:'1.2-2' }}, para que você possa ser aprovado
                a sua conta deverá alcançar o valor de $ {{ sum(plan.profit_target, plan.value_account) | number:'1.2-2' }}.
                <hr *ngIf="!isLast">
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(1)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[1]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[1]" src="assets/icons/show-less.svg" alt="">
                            <span>Perda máxima (Drawdown)</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">
                            -$ {{plan.trailing_drawdown | number:'1.2-2' }}
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content" *ngFor="let plan of plans; let isLast = last">
                A perda máxima no <strong>plano {{plan.title}}</strong> é de $ {{plan.trailing_drawdown | number:'1.2-2' }}, é
                importante se atentar pois a perda máxima segue as regras de Drawdown, ou seja, sempre que você alcançar
                valores mais altos no saldo da sua conta, o limite mínimo da conta será atualizado.

                <p>
                    Por exemplo, uma conta n plano {{plan.title}} inicia-se com o saldo de $ {{plan.value_account | number:'1.2-2' }} e a perda máxima é de {{plan.trailing_drawdown | number:'1.2-2' }},
                    ou seja, o limite mínimo da conta é $ {{sum(plan.value_account, -plan.trailing_drawdown) | number:'1.2-2' }}.
                    Se você alcançar um saldo acima de $ {{plan.value_account | number:'1.2-2' }},
                    como por exemplo, $ {{sum(plan.value_account,500) | number:'1.2-2' }}, o limite
                    mínimo que sua conta pode alcançar passa a ser $ {{sum(sum(plan.value_account, -plan.trailing_drawdown), 500) | number:'1.2-2' }}.
                    Lembre-se, que o limite mínimo é atualizado sempre que a conta atinge valores mais altos.
                </p>
                <hr *ngIf="!isLast">
            </div>
            <div class="rules-content">
                <p>
                    <a [routerLink]="'/drawdown'" target="_blank">Clique aqui</a> para visualizar um exemplo completo sobre o Drawdown.
                </p>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(2)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[2]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[2]" src="assets/icons/show-less.svg" alt="">
                            <span>Perda máxima diária</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">
                            -$ {{plan.limit_loss_day | number:'1.2-2'}}
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content" *ngFor="let plan of plans; let isLast = last">
                A perda máxima diária no <strong>plano {{plan.title}}</strong> é de -$ {{plan.limit_loss_day | number:'1.2-2' }},
                Ou seja, durante o seu dia de operação você não poderá atingir o limite de perdas diária, mesmo que no
                próximo trade você consiga recuperar o valor, a perda máxima sendo alcançada o teste será finalizado.
                <hr *ngIf="!isLast">
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(3)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[3]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[3]" src="assets/icons/show-less.svg" alt="">
                            <span>Mínimo de dias operados</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">
                            {{plan.minimum_days_operation }}
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para ser um trader INSIDER você precisa ter pelo menos {{minDaysOp()}} operados. 
                A aprovação só é realizada após a meta ser alcançada ou mantida após os
                {{minDaysOp()}} operados.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(4)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[4]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[4]" src="assets/icons/show-less.svg" alt="">
                            <span>Máximo de dias sem operar</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">{{plan.limit_days_not_operation}}</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content" *ngIf="plans">
                Para se tornar um trader INSIDER você não poderá ficar mais que {{maxDaysNotOp()}} sem operar.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(5)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[5]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[5]" src="assets/icons/show-less.svg" alt="">
                            <span>Não realizar swing trade</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">Não realizar</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para evitar operações de swing trade não intencionais, os traders devem estar cientes dos horários de
                fechamento dos mercados em dias de feriado ou qualquer evento que possa afetar os horários normais
                de negociação. É de responsabilidade do trader evitar operações realizadas fora dos horários
                convencionais de mercado ou durante feriados.
                <br><br>
                Para visualizar o horário de abertura e fechamento de cada ativo que você deseja operar, clique no
                botão abaixo:
                <div class="assets">
                    <button class="button-primary" [routerLink]="'/ativos-permitidos'">Visualizar ativos permitidos</button>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(6)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[6]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[6]" src="assets/icons/show-less.svg" alt="">
                            <span>Consistência</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">80%</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Para que você possa ser aprovado o seu nível de consistencia precisa ser igual ou estar acima de 80%. O
                nível de consistência é calculado da seguinte maneira:
                <br><br>
                Obtenha os 4 melhores dias operados:<br>
                PRIMEIRO MELHOR DIA<br>
                SEGUNDO MELHOR DIA<br>
                TERCEIRO MELHOR DIA<br>
                QUARTO MELHOR DIA<br>
                <br><br>
                A <strong>soma</strong> entre o SEGUNDO, TERCEIRO e QUARTO melhor dia precisa ser equivalente a pelo
                menos 80% do resultado do seu PRIMEIRO MELHOR DIA

                <p>
                    <a [routerLink]="'/consistencia'" target="_blank">Clique aqui</a> para visualizar um exemplo completo sobre o nível de consistência.
                </p>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(7)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[7]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[7]" src="assets/icons/show-less.svg" alt="">
                            <span>Consistência Aprovado</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">Clique para visualizar</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                Após aprovado deve ser capaz de demonstrar que continua operando com consistência. Caso seja aprovado
                antes do limite mínimo de dias para aprovação, os próximos Trades devem ser feitos com pelo menos 60%
                dos lotes da quantidade de lotes que você usou no seu melhor trade no ativo em que operar, e a duração
                das operações deve ser de pelo menos 50% da duração do seu melhor trade realizado naquele ativo em que
                operar.
                <p>
                    <a [routerLink]="'/consistencia-aprovado'" target="_blank">Clique aqui</a> para visualizar um exemplo completo sobre a regra consistência aprovado.
                </p>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(8)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[8]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[8]" src="assets/icons/show-less.svg" alt="">
                            <span>Insider Investe ativado</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">Deve estar ativado em todas operações</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                O Insider Investe é responsável por enviar as suas operações para o nosso servidor em tempo real. Para que o
                seu teste seja válido é <strong>essencial</strong> que ele esteja devidamente configurado na sua conta
                de operações e esteja sempre ativado no Meta Trader 5 durante o periodo que suas operações forem
                realizadas. <a [routerLink]="'/configurar-conta'">Clique aqui</a> saber como configurar o Insider Investe.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-expansion-panel" hideToggle>
            <mat-expansion-panel-header (click)="updateExpanded(9)">
                <mat-panel-title>
                    <div class="rule-header-result">
                        <div class="rule-header-result-description color-medium first-item">
                            <img *ngIf="!expanded[9]" src="assets/icons/show-more.svg" alt="">
                            <img *ngIf="expanded[9]" src="assets/icons/show-less.svg" alt="">
                            <span>Ativos e quantidade de contratos permitidos</span>
                        </div>
                        <div class="rule-header-result-goal" *ngFor="let plan of plans" [class.is-popular]="plan.is_popular">Clique aqui para visualizar</div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="rules-content">
                <div>

                    Para se tornar um trader INSIDER é preciso realizar operações somente através dos ativos e
                    quantidade de contratos permitidos,
                    qualquer outro ativo operado que não esteja na lista poderá resultar em reprovação.

                    <div *ngIf="hasStock()">
                        <br><br>
                        No âmbito do plano de ações, as contas disponíveis não possuem alavancagem. É de
                        responsabilidade exclusiva do trader calcular os lotes que podem ser operados com base em seu
                        saldo atual. Esta responsabilidade inclui a avaliação cuidadosa das posições que o trader
                        pretende assumir, assegurando que estas não ultrapassem o saldo disponível na conta. Para
                        calcular o tamanho de sua posição é necessário multiplicar a quantidade de contratos com o valor
                        atual da ação. Essa regra leva em consideração todas as posições que o trader deseja abrir,
                        todas elas somadas não deve ultrapassar o saldo atual da conta. <a [routerLink]="'/poder-de-compra'" target="_blank">Clique aqui</a> para saber mais sobre o poder de compra.
                    </div>
                    <br><br>
                    Clique no botão
                    abaixo e consulte a lista de ativos para o seu plano.

                </div>
                <div class="assets">
                    <button class="button-primary" [routerLink]="'/ativos-permitidos'">Visualizar ativos permitidos</button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>