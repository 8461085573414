<app-simple-header></app-simple-header>
<div class="content">
    <div class="maintenance">
        <div class="title">Desculpe!</div>
        <div class="sub-title">Nosso site está em manuteção</div>
        <img src="/assets/images/maintenance.png">
        <div class="sub-title" [innerHTML]="textMaintenance"></div>
        <div class="sub-title">Agradecemos sua compreensão</div>
    </div>
</div>
