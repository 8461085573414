<div id="print-section">
    <div class="receipt">
        <section class="body-content">
            <div class="body-logo">
                <img src="assets/images/logo_insider.png" alt="">
            </div>
            <div class="body-text">
                <span class="welcome">
                    Olá <span class="name" *ngIf="user">{{name()}}</span>,
                </span>
                <span class="desc">
                    Esse é o seu recibo da solicitação realizada em <span class="emph">{{withdraw.completion_date??'2025-01-01' | date: 'dd/MM/yyyy'}}</span>
                </span>
                <span class="section-title">Detalhamento</span>
                <div class="detail">
                    <div class="item">
                        <span class="emph">Valor solicitado (USD)</span>
                        <span>{{withdraw.value | currency: '$'}}</span>
                    </div>
                    <div class="item">
                        <span>Taxa de saque (USD)</span>
                        <span>{{taxWithdraw('USD') | currency: '$'}}</span>
                    </div>
                    <div class="item">
                        <span>Cotação dolar (BRL)</span>
                        <span>{{withdraw.dolar_quote??0 | currency: 'R$'}}</span>
                    </div>
                    <div class="line"></div>
                    <div class="item">
                        <span class="emph">Valor solicitado (BRL)</span>
                        <span>{{saque() | currency: 'R$'}}</span>
                    </div>
                    <div class="item">
                        <span>Taxa de saque (BRL)</span>
                        <span>{{taxWithdraw('BRL') | currency: 'R$'}}</span>
                    </div>
                    <div class="line"></div>
                    <div class="item">
                        <span>Subtotal</span>
                        <span>{{subTotal() | currency: 'R$'}}</span>
                    </div>
                    <div class="item">
                        <span>IR. (15%)</span>
                        <span>{{income() | currency: 'R$'}}</span>
                    </div>
                    <div class="item">
                        <span>Insider Invest (%)</span>
                        <span>{{taxInsider() | currency: 'R$'}}</span>
                    </div>
                    <div class="line"></div>
                    <div class="item">
                        <span>Total</span>
                        <span>{{total() | currency: 'R$'}}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="download">
    <button 
        [useExistingCss]="true"
        printSectionId="print-section"
        ngxPrint>
        <i class="fa-solid fa-download"></i> Baixar recibo
    </button>
</div>