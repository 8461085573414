export class PlanExpiration {
    static positive = [
        {
            title: "Último aviso: Renove sua conta para continuar seu progresso!",
            button: "Renovar agora",
            description: "Olá [name], você está positivo e isso te deixa mais próximo da sua aprovação! Com apenas 1 dia até a expiração da sua conta, é hora de renovar. Renove agora e mantenha seu progresso intacto. Para renovar agora mesmo basta clicar no botão abaixo!"
        },
        {
            title: "Faltam 2 dias: Renove agora e não perca suas conquistas!",
            button: "Renovar agora",
            description: "Olá [name], o tempo está se esgotando! Com apenas 2 dias até a expiração da sua conta, é crucial renovar agora. Mantenha seu progresso incacto. Para renovar agora mesmo basta clicar no botão abaixo!"
        },
        {
            title: "Faltam 3 dias: Renove sua conta e mantenha o ritmo!",
            button: "Renovar agora",
            description: "Olá [name], está quase na hora de renovar sua conta de negociação! Com apenas 3 dias restantes, você pode continuar no caminho da aprovação. Renove agora e continue com o seu progresso sem perder o ritmo!"
        },
        {
            title: "Faltam 4 dias: Não perca a chance de manter o seu progresso!",
            button: "Renovar agora",
            description: "Olá [name], com apenas 4 dias restantes para sua conta expirar, queremos te lembrar que ainda é tempo de manter o seu progresso! Renove agora e mantenha seu progresso intacto. Para renovar agora mesmo basta clicar no botão abaixo!"
        },
        {
            title: "Faltam 5 dias: Renove sua conta e continue com seu progresso!",
            button: "Renovar agora",
            description: "Olá [name], faltam apenas 5 dias para sua conta expirar! Você está indo muito bem e pode continuar com boas chances de ser aprovado. Renove agora e mantenha seu progresso intacto. Para renovar agora mesmo basta clicar no botão abaixo!"
        }
    ];

    static negative = [
        {
            title: "Último dia: Sua conta de negociação expira hoje!",
            description: "Olá [name], sua conta está prestes a expirar, mas você pode recomeçar do zero com uma conta totalmente nova! Renove agora e receba uma nova conta de negociação para negociar.",
            button: "Renovar agora"
        },
        {
            title: "Faltam 2 dias: Renove sua conta e recomece do 0!",
            description: "Olá [name], sua conta está negativa, mas ainda há tempo para renovar! Com apenas 2 dias até a expiração, renove agora e receba uma nova conta de negociação.",
            button: "Renovar agora"
        },
        {
            title: "Faltam 3 dias: Recarregue suas chances, renove sua conta!",
            description: "Olá [name], faltam apenas 3 dias restantes para sua conta expirar, renove agora e receba uma nova conta de negociação. Recomece sua jornada com confiança.",
            button: "Renovar agora"
        },
        {
            title: "Faltam 4 dias: Recomece do zero, renove sua conta agora!",
            description: "Olá [name], sua conta está negativa, mas você pode dar a volta por cima! Com apenas 4 dias restantes, renove sua conta e comece do zero. Ao renovar, você receberá uma nova conta de negociação.",
            button: "Renovar agora"
        },
        {
            title: "Faltam 5 dias: Renove sua conta e recomece do 0!",
            description: "Olá [name], faltam apenas 5 dias para sua conta de negociação expirar, mas não se preocupe! Renove agora e recomece do 0. Ao renovar, você receberá uma nova conta para recomeçar o seu teste. Não perca essa chance!",
            button: "Renovar agora"
        }
    ]
}