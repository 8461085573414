import { Injectable } from "@angular/core";
import { AlertComponent } from "src/app/components/alert/alert.component";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";
import { WithdrawModel } from "../models/withdraw.model";

@Injectable({
    providedIn: 'root'
})
export class WithdrawCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get(account_operation_id: number = null) {
        this.sidenavService.enableProgressBar();
        let filter = account_operation_id? `?account_operation_id=${account_operation_id}`: '';
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/withdraw/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    available(account_operation_id: number = null) {
        this.sidenavService.enableProgressBar();
        let filter = account_operation_id? `?account_operation_id=${account_operation_id}`: '';
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/withdraw-available/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    create(withdraw: WithdrawModel) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/account-operation/withdraw/");

        return new Promise(async (resolve) => {

            this.api.post<WithdrawModel>(withdraw).subscribe({
                next: async (result: WithdrawModel) => {
                    if (result) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}