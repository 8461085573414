import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AlertModel } from "src/app/models/alert.model";

@Injectable({
    providedIn: 'root'
})
export class AlertEvent {
    private loading: boolean = false;
    private alert: AlertModel = null;
    private requestClose: boolean = false;
    private isAlertMobile: boolean = false;
    private hasAlert: boolean = false;

    setShowAlert(alert: AlertModel) {
        this.isAlertMobile = window.innerWidth <= 750;
        this.loading = true;
        this.alert = alert;
    }

    setCloseAlert() {

        this.requestClose = true;

        setTimeout(() => {
            this.loading = false;
            this.requestClose = false;
            this.alert = null;
        }, 3000);
    }

    getStatus() {
        return this.loading;
    }

    getAlert() {
        return this.alert;
    }

    getRequestClose() {
        return this.requestClose;
    }

    getAlertMobile() {
        return this.isAlertMobile;
    }

    setHasAlert(hasAlert: boolean){
        this.hasAlert = hasAlert;
    }

    getHasAlert(){
        return this.hasAlert;
    }
}