import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserCommand } from 'src/app/commands/user.command';
import { UserRegisterModel } from 'src/app/models/user-register.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent {

  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  token: string;

  success_add: boolean = false;
  first_name: string = '';

  constructor(
    public inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    private routeInfo: ActivatedRoute,
    private firebaseService: FirebaseService,
    private userCommand: UserCommand,) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('user-register')
    this.routeInfo.queryParams.subscribe((params: any) => {
      if (params) {
        this.token = params.token;
      }
    });
    this.formValidation = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      name: ['', [Validators.required]],
      phone_number: [null, [Validators.required]],
      coupon: [null, [Validators.required]],
    });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  submit() {
    this.firebaseService.eventClick('submit-register', 'user-register');
    this.submitted = true;  

    if (this.formValidation.valid) {

      let userRegister: UserRegisterModel = this.formValidation.value as UserRegisterModel;
      this.first_name = userRegister.name.split(' ')[0];
      userRegister.email = userRegister.email.toLowerCase();

      this.userCommand.register(userRegister).then((result: boolean) => {
        this.success_add = result;
      });
    }
  }

}
