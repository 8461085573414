import { Component, OnInit } from '@angular/core';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-metavision',
  templateUrl: './metavision.component.html',
  styleUrls: ['./metavision.component.scss']
})
export class MetavisionComponent implements OnInit {

  configuration: ConfigurationModel;
  user: UserModel;
  progressBar: number = 0;
  step: number = 1;
  
  constructor(private configurationCommand: ConfigurationCommand) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user')) as UserModel;

    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });

    this.progressBar = (this.step/8)*100;
  }

  download_file() {
    window.open(this.configuration.file_metavision, '_blank');
  }

  nextStep(){
    if(this.step < 8)
      this.step++;
    this.progressBar = (this.step/8)*100;
  }

  backStep(){
    if(this.step > 1)
      if(this.step == 5)
        this.step = 2;
      else
        this.step--;
    this.progressBar = (this.step/8)*100;
  }

  skipStep(){
    this.step = 5;
    this.progressBar = (this.step/8)*100;
  }
}
