import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { Subscription } from 'rxjs';
import { TournamentCommand } from 'src/app/commands/tournament.command';
import { TournamentModel } from 'src/app/models/tournament.model';
import { UserRegisterModel } from 'src/app/models/user-register.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrl: './inscription.component.scss'
})
export class InscriptionComponent {
  private subscription: Subscription;
  tournament: TournamentModel;

  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  token: string;
  message: string = '';

  success_add: boolean = false;
  first_name: string = '';

  constructor(
    public inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    private routeInfo: ActivatedRoute,
    private firebaseService: FirebaseService,
    private tournamentCommand: TournamentCommand,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
  ) { }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.formValidation = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      name: ['', [Validators.required]],
      phone_number: [null, [Validators.required]],
      document_number: ['', [Validators.required, this.inputFormService.validateDocument]],
      tournament_id: [null, [Validators.required]],
    });
    this.firebaseService.viewScreen('register-tournament')
    this.routeInfo.queryParams.subscribe((params: any) => {
      if (params) {
        this.token = params.token;
      }
    });

    this.routeInfo.params.subscribe(async (params: any) => {
      if (params.id) {
        this.formValidation.patchValue({tournament_id: params.id});
        this.tournamentCommand.getPublic(params.id, false).then((result: TournamentModel) => {
          if(result){
            this.tournament = result;
          }else{
            this.router.navigateByUrl('/not-found');
          }
        });
      }
    });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  doCaptchValidation() {
    this.subscription = this.recaptchaV3Service.execute('register').subscribe({
      next: (token: string) => {
        this.submit(token);
      },
      error: (error) => console.log(error)
    });
  }

  submit(token: string) {
    this.firebaseService.eventClick('submit-register', 'register-tournament');
    this.submitted = true;  

    if (this.formValidation.valid) {

      let userRegister: UserRegisterModel = this.formValidation.value as UserRegisterModel;
      this.first_name = userRegister.name.split(' ')[0];
      userRegister.email = userRegister.email.toLowerCase();
      userRegister.recaptcha_token = token;

      this.tournamentCommand.post(userRegister).then((result: any) => {
        if(result) {
          this.success_add = result;
          this.message = result;
        }
      });
    }
  }
}
