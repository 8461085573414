<app-auth-header page="assets-allowed"></app-auth-header>

<div class="content">
    <div class="title">
        Ativos permitidos para negociações
    </div>
    <section class="row-content">
        <div class="card-filter">
            <div class="card">
                <span class="desc">Selecione a conta de negociação para visualizar os ativos e quantidade de contratos permitidos para cada um deles</span>
                <span class="desc desc-alert"><span class="color-danger">* </span>Em caso de operações realizadas em ativos fora da lista ou quantidade de contratos acima do permitido, haverá encerramento da operação e <strong>PERCA</strong> de capital referente ao spread ou variação do mercado no momento do encerramento</span>
                <div class=" filter">
                    <div class="select">
                        <mat-form-field>
                            <mat-select placeholder="Conta de negociação" [(ngModel)]="account">
                                <mat-option *ngFor="let account of listAccounts" [value]="account.account">
                                    <span>{{account.account}} - </span>
                                    <span *ngIf="account.status == 2 && account.activated && account.type == 1">Em Teste</span>
                                    <span *ngIf="account.status == 2 && account.activated && account.type == 2">Em monitoramento</span>
                                    <span *ngIf="account.status == 4">Aprovado</span>
                                    <span *ngIf="account.status == 8">Aguardando aprovação</span>
                                    <span *ngIf="account.status == 9">Aguardando assinatura</span>
                                    <span *ngIf="account.status == 2 && !account.activated">Aguardando configuração</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3 && account.status != 8">Desativada</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="button-primary" (click)="seach()">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div class="plan_title" *ngIf="account_operation">
        <div>
            <div class="title">
                {{account_operation.plan.title}}&nbsp;
                <span *ngIf="!account_operation.plan.is_stock">(Índices e derivativos)</span>
                <span *ngIf="account_operation.plan.is_stock">(Ações Americanas)</span>
            </div>
            <div class="subtitle">
                <span>Saldo inicial da conta: </span>
                <span class="value">{{account_operation.plan.value_account | currency: '$'}}</span>
            </div>
        </div>
        <div class="input">
            <input class="input-box" type="text" placeholder="Procurar" #filter (keyup)="filterAssets(filter.value)">
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>
    </div>
    <span class="desc desc-alert" *ngIf="account_operation && account_operation.plan.is_stock">
        <span class="color-danger">* </span> No âmbito do plano de ações, as contas disponíveis não possuem alavancagem. É de
        responsabilidade exclusiva do trader calcular os lotes que podem ser operados com base em seu
        saldo atual. Esta responsabilidade inclui a avaliação cuidadosa das posições que o trader
        pretende assumir, assegurando que estas não ultrapassem o saldo disponível na conta. 
        <br><br>
        Para calcular o tamanho de sua posição é necessário multiplicar a quantidade de contratos com o valor
        atual da ação. Essa regra leva em consideração todas as posições que o trader deseja abrir,
        todas elas somadas não deve ultrapassar o saldo atual da conta. <a [routerLink]="'/poder-de-compra'" target="_blank">Clique aqui</a> para saber mais sobre o poder de compra.
        <br><br>
    </span>
    <section class="row-content table-assets" *ngIf="account_operation">
        <app-table-assets *ngIf="listAssets" [assets]="listAssets" [showContract]="!account_operation.plan.is_stock" [(page)]="page"></app-table-assets>
    </section>
</div>