<div class="content-body">
    <img class="close-modal" (click)="closeModal()" src="assets/icons/close.svg">

    <div class="content-body-title">Log de Contas</div>
    <section class="content-body-info" *ngIf="!logs">Nenhum histórico de Log encontrado.</section>
    <section class="content-body-info" *ngIf="logs">
        <div class="content-body-info-item" *ngFor="let log of logs">
            <div class="align-start">{{log.title}}</div>
            <div class="align-center color-medium">{{log.asset}}</div>
            <div class="align-center color-medium">{{log.date | date: 'dd/MM/yyyy'}}</div>
            <div class="align-center color-medium" *ngIf="log.type == 1">Demo</div>
            <div class="align-center color-medium" *ngIf="log.type == 2">Real</div>
            <div class="align-center color-medium">{{log.value | currency: '$'}}</div>
        </div>
    </section>
</div>