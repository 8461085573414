<app-simple-header></app-simple-header>

<div class="content">

    <div class="card">
        <div class="card-title">Recuperação de senha</div>

        <form [formGroup]="formValidation">
            <div class="input">
                <input class="input-box" type="text" placeholder="Informe seu email" formControlName="email"
                    [class.input-box-error]="submitted && (errorCtr.email.errors?.required || errorCtr.email.errors?.pattern)"
                    (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">

                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.required">
                    O campo e-mail é obrigatório
                </div>
                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.pattern">
                    Preencha corretamente o campo
                </div>
            </div>
        </form>
        <button class="button-primary" (click)="submit()">Recuperar senha</button>
    </div>

</div>