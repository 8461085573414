<app-simple-header page="payment"></app-simple-header>

<div class="content" *ngIf="plans">
    <div class="banner"><img src="assets/images/banner_black_friday.png"></div>
    <mat-accordion>
        <mat-expansion-panel expanded disabled #xpdPlatform>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Insider Investe - Plataforma
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="platforms">
                <div class="step">
                    <span class="detail">Insider Investe - Mesa proprietária</span>
                    <span class="warning">Selecione a plataforma que deseja utilizar:</span>
                </div>
                <span class="detail">Plataformas</span>
                <div class="card-platform" [class.active]="platform_selected == 'MT5'" (click)="changePlatform('MT5')">
                    <div class="logo"><img src="assets/icons/metatrader_BF.svg" /></div>
                    <div class="description">
                        <span class="title">Meta trader 5</span>
                        <span class="value">{{metaTraderValue | currency: 'R$'}}</span>
                        <span class="obs"><span class="color-danger">*</span> Disponibilizado imediatamente após a confirmação do pagamento</span>
                    </div>
                </div>
                <div class="card-platform" [class.active]="platform_selected == 'BA'" (click)="changePlatform('BA')">
                    <div class="logo"><img src="assets/icons/blackarrow_BF.svg" /></div>
                    <div class="description">
                        <span class="title">Black Arrow</span>
                        <span class="value">{{blackArrowValue | currency: 'R$'}}</span>
                        <span class="obs"><span class="color-danger">*</span> Disponibilizado em até 24 horas no horário comercial após a confirmação do pagamento</span>
                    </div>
                </div>
            </div>
            <div class="footer-buttons">
                <button class="button-primary" (click)="next(xpdPlan)">Avançar</button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel #xpdPlan disabled>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Insider Investe - Planos
                    </div>
                </mat-panel-title>
                
            </mat-expansion-panel-header>
            <div class="step">
                <span class="detail">Insider Investe - Mesa proprietária</span>
                <span class="warning">Selecione os planos desejados:</span>
            </div>
            <div class="plans">
                <div class="promo-description">
                    <span class="promo-title">
                        Black Friday Insider Investe - Desconto
                        <span *ngIf="platform_selected == 'MT5'"> com progressão</span>
                        <span *ngIf="platform_selected == 'BA'"> em planos na plataforma Black Arrow</span>
                    </span>
                    <span class="promo-desc" *ngIf="platform_selected == 'MT5'">Escolha até 5 planos que desejar, e quanto mais planos você escolher, maior será o seu desconto, podendo chegar em até 50% em cada plano</span>
                    <span class="promo-desc" *ngIf="platform_selected == 'BA'">30% de desconto nos planos utilizando a plataforma Black Arrow</span>
                </div>

                <div class="promo-meter" *ngIf="platform_selected == 'MT5'">
                    <span class="warning">Desconto aplicado</span>
                    <div class="meter">
                        <span id="d1" class="discount">30%</span>
                        <span id="d2" class="discount">35%</span>
                        <span id="d3" class="discount">40%</span>
                        <span id="d4" class="discount">45%</span>
                        <span id="d5" class="discount">50%</span>
                        <span class="indicator d{{selectedPlans.length}}"><i class="fa-solid fa-caret-down"></i></span>
                    </div>
                </div>

                <div class="list-plan" *ngFor="let item of ordered_plans">
                    <div class="type-plan">
                        <img src="assets/icons/world_icon.svg" *ngIf="!item.is_stock">
                        <img src="assets/icons/usa_flag.svg" *ngIf="item.is_stock">
                        <span class="detail" *ngIf="!item.is_stock">Índices e derivativos</span>
                        <span class="detail" *ngIf="item.is_stock">Ações Americanas</span>
                    </div>
                    <div class="list-header">
                        <span class="warning">PLANO</span>
                        <span class="warning qtd" *ngIf="platform_selected == 'MT5'">QUANTIDADE</span>
                        <span class="warning" *ngIf="platform_selected == 'MT5'">VALOR</span>
                    </div>
                    <div class="list-item" *ngFor="let plan of item.plans">
                        <div class="plan">
                            <span class="name">{{plan.title}} ({{valueAccount(plan.value_account)}})</span>
                            <span class="start-value warning">De: <s>{{plan.value_plan | currency: 'R$'}}</s></span>
                            <span class="sale-value warning" *ngIf="platform_selected == 'MT5'">Por até: <span class="price">{{plan.value_plan * 0.5 | currency: 'R$'}}</span></span>
                            <span class="sale-value warning" *ngIf="platform_selected == 'BA'">Por: <span class="price">{{plan.value_plan * 0.7 | currency: 'R$'}}</span></span>
                        </div>
                        <div class="input-count" *ngIf="platform_selected == 'MT5'">
                            <i class="fa-solid fa-minus" (click)="changeQTD(qtd, -1, plan.id)"></i>
                            <div class="input">
                                <input class="input-box" type="number" #qtd [value]="countPlanSelected(plan.id)" readonly>
                            </div>
                            <i class="fa-solid fa-plus" (click)="changeQTD(qtd, 1, plan.id)"></i>
                        </div>
                        <span class="plan-value" *ngIf="platform_selected == 'MT5'">{{plan.value_plan * discount[selectedPlans.length] | currency: 'R$'}}</span>
                        <span class="" *ngIf="platform_selected == 'BA'"></span>
                        <span class="plan-value blackarrow" *ngIf="platform_selected == 'BA'" (click)="selectPlanBA(xpdAccount, plan)">Comprar</span>
                    </div>
                    <div class="list-item" *ngIf="platform_selected == 'MT5' && item.is_stock">
                        <div class="plan">
                            <span class="name">Total</span>
                        </div>
                        <span class="plan-value"></span>
                        <span class="plan-value">{{getFinalValue() | currency: 'R$'}}</span>
                    </div>
                </div>
            </div>
            <div class="footer-buttons">
                <button class="button-secondary" (click)="back(xpdPlatform)">Voltar</button>
                <button class="button-primary" (click)="next(xpdAccount)" *ngIf="platform_selected == 'MT5'" [disabled]="selectedPlans.length == 0">Avançar</button>
                <div *ngIf="platform_selected == 'BA'"></div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel #xpdAccount disabled>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Insider Investe - Dados da conta
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="step">
                <span class="detail">Insider Investe - Mesa proprietária</span>
                <span class="warning">Para continuar com o pagamento, informe seus dados pessoais:</span>
            </div>
            <form [formGroup]="formAccount">
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe seu nome completo"
                        [class.input-box-error]="submittedAccount && errorAccountCtr.name.errors?.required" formControlName="name"
                        (focus)="focus('focus', 'name')" (blur)="focus('unfocus', 'name')">
                    <span class="error-form" *ngIf="submittedAccount && errorAccountCtr.name.errors?.required">
                        O campo nome é obrigatório
                    </span>
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="email" placeholder="Informe seu email" 
                    [class.input-box-error]="submittedAccount && errorAccountCtr.email.errors?.required" formControlName="email"
                        (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
                    <span class="error-form" *ngIf="submittedAccount && errorAccountCtr.email.errors?.required">
                        O campo email é obrigatório
                    </span>
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="email" placeholder="Confirme seu email" 
                    [class.input-box-error]="submittedAccount && errorAccountCtr.email_confirm.errors?.required" formControlName="email_confirm"
                        (focus)="focus('focus', 'email_confirm')" (blur)="focus('unfocus', 'email_confirm')">
                    <span class="error-form" *ngIf="submittedAccount && errorAccountCtr.email_confirm.errors?.required">
                        O campo confirmação de email é obrigatório
                    </span>
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="CPF" 
                    [class.input-box-error]="submittedAccount && errorAccountCtr.document.errors?.required" formControlName="document"
                        (focus)="focus('focus', 'document')" (blur)="focus('unfocus', 'document')"
                        mask="000.000.000-00||00.000.000/0000-00">
                    <span class="error-form" *ngIf="submittedAccount && errorAccountCtr.document.errors?.required">
                        O campo confirmação de email é obrigatório
                    </span>
                </div>
                <div class="input">
                    <input class="input-box" type="text" placeholder="Informe seu telefone"
                        [class.input-box-error]="submittedAccount && errorAccountCtr.phone.errors?.required" formControlName="phone"
                        (focus)="focus('focus', 'phone')" (blur)="focus('unfocus', 'phone')"
                        mask="(00) 0000-0000||(00) 0 0000-0000">
                    <span class="error-form" *ngIf="submittedAccount && errorAccountCtr.phone.errors?.required">
                        O campo telefone é obrigatório
                    </span>
                </div>
            </form>
            <div class="footer-buttons">
                <button class="button-secondary" (click)="back(xpdPlan)">Voltar</button>
                <button class="button-primary" (click)="next(xpdBuy, formAccount)">Avançar</button>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel  #xpdBuy disabled>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Insider Investe - Dados da compra
                    </div>
                </mat-panel-title>
                
            </mat-expansion-panel-header>
            <div class="step">
                <span class="detail">Insider Investe - Mesa proprietária</span>
                <span class="warning">Selecione a forma de pagamento e preencha os campos abaixo para confirmar a sua compra:</span>
            </div>
            <form [formGroup]="formBuy">
                <div class="payment-type">
                    <div class="card-payment" [class.active]="pay_method == 'CARTAO'" (click)="changePayment('CARTAO')">
                        <img src="assets/icons/credit.svg"/>
                        <span>Cartão de crédito</span>
                    </div>
                    <div class="card-payment" [class.active]="pay_method == 'PIX'" (click)="changePayment('PIX')">
                        <img src="assets/icons/pix_logo.svg"/>
                        <span>Pix</span>
                    </div>
                    <div class="card-payment" [class.active]="pay_method == 'BOLETO'" (click)="changePayment('BOLETO')">
                        <img src="assets/icons/boleto.svg"/>
                        <span>Boleto</span>
                    </div>
                </div>
    
                <div class="payment-credit" *ngIf="pay_method == 'CARTAO'">
                    <span class="detail">Dados do cartão</span>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o numero do cartão" 
                        [class.input-box-error]="submitted && errorBuyCtr.card_number.errors?.required" formControlName="card_number"
                            (focus)="focus('focus', 'card_number')" (blur)="focus('unfocus', 'card_number')"
                            mask="0000 0000 0000 0000">
                        <span class="error-form" *ngIf="submitted && errorBuyCtr.card_number.errors?.required">
                            O campo numero do cartão é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o nome do titular"
                            [class.input-box-error]="submitted && errorBuyCtr.card_name.errors?.required" formControlName="card_name"
                            (focus)="focus('focus', 'card_name')" (blur)="focus('unfocus', 'card_name')">
                        <span class="error-form" *ngIf="submitted && errorBuyCtr.card_name.errors?.required">
                            O campo nome do titular é obrigatório
                        </span>
                    </div>
                    <div class="card-back">
                        <div class="select">
                            <mat-form-field [class.input-box-error]="submitted && errorBuyCtr.card_month.errors?.required">
                                <mat-select placeholder="Mês de vencimento" formControlName="card_month"
                                    (focus)="focus('focus', 'card_month')" (blur)="focus('unfocus', 'card_month')">
                                    <mat-option [value]="'01'">Janeiro</mat-option>
                                    <mat-option [value]="'02'">Fevereiro</mat-option>
                                    <mat-option [value]="'03'">Março</mat-option>
                                    <mat-option [value]="'04'">Abril</mat-option>
                                    <mat-option [value]="'05'">Maio</mat-option>
                                    <mat-option [value]="'06'">Junho</mat-option>
                                    <mat-option [value]="'07'">Julho</mat-option>
                                    <mat-option [value]="'08'">Agosto</mat-option>
                                    <mat-option [value]="'09'">Setembro</mat-option>
                                    <mat-option [value]="'10'">Outubro</mat-option>
                                    <mat-option [value]="'11'">Novembro</mat-option>
                                    <mat-option [value]="'12'">Dezembro</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="error-form" *ngIf="submitted && errorBuyCtr.card_month.errors?.required">
                                O campo mês é obrigatório
                            </span>
                        </div>
                        <div class="select">
                            <mat-form-field [class.input-box-error]="submitted && errorBuyCtr.card_year.errors?.required">
                                <mat-select placeholder="Ano de vencimento" formControlName="card_year"
                                    (focus)="focus('focus', 'card_year')" (blur)="focus('unfocus', 'card_year')">
                                    <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="error-form" *ngIf="submitted && errorBuyCtr.card_year.errors?.required">
                                O campo ano é obrigatório
                            </span>
                        </div>
                        <div class="input input-space-min">
                            <input class="input-box" type="text" placeholder="Informe o CVV"
                                [class.input-box-error]="submitted && errorBuyCtr.card_cvv.errors?.required" formControlName="card_cvv"
                                (focus)="focus('focus', 'card_cvv')" (blur)="focus('unfocus', 'card_cvv')">
                            <span class="error-form" *ngIf="submitted && errorBuyCtr.card_cvv.errors?.required"
                            mask="000">
                                O campo CVV é obrigatório
                            </span>
                        </div>
                    </div>
    
                    <div class="save-card">
                        <mat-checkbox formControlName="save_card">Salvar cartão para compras futuras</mat-checkbox>
                    </div>
                </div>

                <div class="payment-credit" *ngIf="pay_method == 'CARTAO'">
                    <div class="detail installment" *ngIf="installment_count">Parcelamento</div>
                    <div class="split" *ngIf="installment_count">
                        <div class="select input-space-min">
                            <mat-form-field [class.input-box-error]="submitted && errorBuyCtr.installment_count.errors?.required">
                                <mat-select placeholder="Parcelas" formControlName="installment_count"
                                    (focus)="focus('focus', 'installment_count')" (blur)="focus('unfocus', 'installment_count')">
                                    <mat-option *ngFor="let item of rangeList()" [value]="item">Em {{item}}x de {{(getFinalValue()/item) | currency: 'R$'}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="error-form" *ngIf="submitted && errorBuyCtr.installment_count.errors?.required">
                                O campo Estado é obrigatório
                            </span>
                        </div>
                    </div>
                </div>
                
                <div class="payment-code" *ngIf="pay_method == 'PIX' || pay_method == 'BOLETO'">
                    <div class="warning" *ngIf="pay_method == 'PIX'">
                        <p>Ao prosseguir com a configuração da sua conta de negociação, você receberá um código de barras e um código Pix exclusivos. O código de barras pode ser escaneado usando qualquer aplicativo de pagamento Pix, enquanto o código Pix pode ser copiado e colado no seu aplicativo bancário.</p>
                        <p>Por favor, observe que o código gerado tem uma validade de 15 minutos. Realize o pagamento dentro deste período para evitar inconvenientes.</p>
                        <p>Após a confirmação do seu pagamento, sua conta de negociação será ativada dentro de até 30 minutos. Após a configuração, enviaremos uma confirmação por e-mail.</p>
                        <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco através do e-mail suporte&#64;insiderinveste.com.br.</p>
                    </div>
                    <div class="warning" *ngIf="pay_method == 'BOLETO'">
                        <p>Ao prosseguir com a configuração da sua conta de negociação, um boleto será gerado automaticamente com vencimento para daqui a 5 dias.</p>
                        <p>Após a configuração, enviaremos uma confirmação por e-mail.</p>
                        <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco.</p>
                    </div>
                </div>
            </form>
    
            <div class="global warning" *ngIf="pay_method == 'GLOBAL'">
                <span class="detail">Pagamentos do exterior</span>
                <p>Para pagamentos realizados no exterior, entre em contato com o nosso suporte através do e-mail suporte&#64;insiderinveste.com.br, que disponibilizaremos um link exclusivo para realizar o pagamento em outra moeda.</p>
            </div>
    
            <div class="buy-detail" *ngIf="pay_method != 'GLOBAL'">
                <span class="detail">Detalhes da compra</span>
                <div class="buy">
                    <div class="">
                        <span class="title">Black Friday - Insider Investe </span>
                    </div>
                    <span class="price" *ngIf="selectedPlans">{{getFinalValue() | currency: 'R$'}}</span>
                </div>
            </div>
    
            <div class="terms">
                <form [formGroup]="formBuy">
                    <mat-checkbox formControlName="accept_term">Declaro que li e concordo com os</mat-checkbox>&nbsp;<strong (click)="openModal()">Termos e Condições</strong>
                    <span class="error-form" *ngIf="submitted && errorBuyCtr.accept_term.errors?.required">
                        O aceite dos termos e condições é obrigatório
                    </span>
                </form>
            </div>
            <div class="footer-buttons">
                <button class="button-secondary" (click)="back(xpdAccount)">Voltar</button>
                <button class="button-primary" (click)="submit()" [disabled]="disableSubmitButton">Comprar agora</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>