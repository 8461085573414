import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CouponModel } from 'src/app/models/coupon.model';
import { OneOffChargeModel } from 'src/app/models/one-off-charge.model';
import { PixModel } from 'src/app/models/pay.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss']
})
export class PixComponent {
  pay_pix: PixModel;
  plan: PlanModel;
  payment: PaymentModel;
  coupon: CouponModel;
  charge: OneOffChargeModel;
  nav: any;
  loading: boolean = false;
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private location: Location,
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.pay_pix = this.nav.extras.state? this.nav.extras.state.pay_pix as PixModel: null;
    this.plan = this.nav.extras.state? this.nav.extras.state.plan as PlanModel: null;
    this.payment = this.nav.extras.state? this.nav.extras.state.payment as PaymentModel: null;
    this.coupon = this.nav.extras.state? this.nav.extras.state.coupon as CouponModel: null;
    this.charge = this.nav.extras.state? this.nav.extras.state.charge as OneOffChargeModel: null;
    this.blackArrowValue = this.plan.value_black_arrow;

    if(!this.pay_pix || !this.plan)
      this.location.back();
  }

  copyPix() {
    this._snackBar.open('Código PIX copiado com sucesso.', '',  {duration: 2000})
  }

}
