import { Component } from '@angular/core';
import { PlanCommand } from 'src/app/commands/plan.command';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-rules-objectives',
  templateUrl: './rules-objectives.component.html',
  styleUrls: ['./rules-objectives.component.scss']
})
export class RulesObjectivesComponent {
  listPlans: PlanModel[] = [];
  filter_plans: PlanModel[];
  plan: PlanModel;

  constructor(
    private planCommand: PlanCommand,
  ){ }

  ngOnInit() {
    this.planCommand.getPublicAll().then((result: any) => {
      if (result) {
        this.listPlans = result.results;
        this.filterPlan('asset');
      }
    });
  }

  filterPlan(type: string) {
    this.filter_plans = null;
    setTimeout(() => {
      if(type == 'asset')
        this.filter_plans = this.listPlans.filter(item => !item.is_stock);
      else
        this.filter_plans = this.listPlans.filter(item => item.is_stock);
      
      this.changePlan(this.filter_plans[0]);
    }, 300);
  }

  changePlan(plan: PlanModel) {
    this.plan = null;
    setTimeout(() => {
      this.plan = plan;
    }, 100);
  }

  mobileFilterPlan() {
    return this.plan? [this.plan]: [];
  }
}
