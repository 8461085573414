import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const token: string = localStorage.getItem("token") as string;
        const isMaintenance: boolean = (localStorage.getItem('maintenance') as string) == 'Y';

        if (isMaintenance) {
            this.router.navigateByUrl("/manutencao");
            return false
        } else {
            if (!token) {
                this.router.navigateByUrl("/login");
                return false
            }
            return true;
        }
    }
}
