import { Component } from '@angular/core';
import { TournamentCommand } from 'src/app/commands/tournament.command';
import { TournamentModel } from 'src/app/models/tournament.model';

@Component({
  selector: 'app-list-championship',
  templateUrl: './list-championship.component.html',
  styleUrl: './list-championship.component.scss'
})
export class ListChampionshipComponent {
  listTournamentActive: TournamentModel[] = [];
  listTournamentExpired: TournamentModel[] = [];

  constructor(
    private tournamentCommand: TournamentCommand,
  ) { }

  ngOnInit() {
    this.tournamentCommand.get().then((result: any) => {
      if(result && result.results){
        this.listTournamentActive = result.results.filter(item => this.compareDate(item.end_date));
        this.listTournamentExpired = result.results.filter(item => !this.compareDate(item.end_date));
      }
    });
  }

  compareDate(date: string): boolean {
    const endDate = new Date(`${date}T00:00`), currentDate = new Date();
    return currentDate < endDate;
  }
}
