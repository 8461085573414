import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-confirm-request',
  templateUrl: './confirm-request.component.html',
  styleUrls: ['./confirm-request.component.scss']
})
export class ConfirmRequestComponent {

  constructor(
    private modalService: ModalService,
  ) {}

  action(value: boolean = false) {
    this.modalService.closeModal(value);
  }
}
