import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Injectable({
    providedIn: 'root'
})
export class RenderChartLineService {


    constructor() { }

    public renderSimple(container: string, data: any, height: number = 50, maxValue:any = null, minValue:any = null, fillColor: boolean = true, forceNegativeColor: boolean = false, dates: any) {

        const isPositive = data[data.length - 1][1] >= 0;
        let options: any = this.createModelChart(fillColor, forceNegativeColor, data, dates, height);
        options.chart.height = height;

        options.series = [{
            type: 'area',
            name: 'Saldo',
            data: data,
        }];
        if(minValue){
            options.series.push({
                type: 'area',
                name: 'Saldo',
                data: minValue,
                color: "#FD4949",
                fillColor: 'transparent',
            });
        }
        if(maxValue){
            options.series.push({
                type: 'area',
                name: 'Saldo',
                data: maxValue,
                color: "#00FC84",
                fillColor: 'transparent',
            });
        }

        if (forceNegativeColor) {
            options.series[0].zones = [{
                value: 0,
                color: this.getColorGraph('#ff4949', 'rgba(255, 73, 73, 0)', forceNegativeColor)
            }]
        } else {
            }
        
        return Highcharts.chart(container, options);
    }

    private createModelChart(fillColor: boolean = true, forceNegativeColor: boolean = false, data: any, dates: any, height: number) {
        return {
            chart: {
                zoomType: 'x',
                backgroundColor: 'transparent',
                spacingLeft: 0,
                spacingRight: 0,
                height: height
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                visible: true,
                lineWidth: 4,
                tickLength: 0,
                lineColor: '#9a9a9a',
                labels: {
                    style: {
                        color: "#777777"
                    },
                    formatter: function () {
                        return dates[this.value];
                    }
                }
            },
            yAxis: {
                labels: {
                    style: {
                        color: "#777777"
                    },
                    enabled: true
                }, title: '',
            },
            plotOptions: {
                area: {
                    fillColor: !forceNegativeColor ? this.getColorGraph() : null,
                    marker: {
                        radius: 0
                    },
                    lineWidth: fillColor ? 1 : 2,
                    states: {
                        hover: {
                            lineWidth: 1
                        }
                    },
                    threshold: null
                }
            },
            tooltip: {
                formatter: function () {
                    var formatter = new Intl.NumberFormat('pt-br', {
                        currency: 'BRL',
                        minimumFractionDigits: 2
                    });

                    return `${this.point.name} <br> <b>${localStorage.getItem('locale_user') ?? '$'} ${formatter.format(this.point.y)}</b>`;
                }
            },
        } as any;
    }


    getColorGraph(color: string = null, rgba: string = null, isNegativeColor: boolean = false) {

        return {
            linearGradient: {
                x1: 0,
                y1: !isNegativeColor ? 0 : 1,
                x2: 0,
                y2: !isNegativeColor ? 1 : 0
            },
            stops: [
                [0, color == null ? Highcharts.getOptions().colors[0] : color],
                [1, rgba == null ? Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba') : rgba]
            ]
        };
    }
}
