import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BannerReadCommand } from 'src/app/commands/banner-read.command';
import { BannerReadModel } from 'src/app/models/banner-read.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-banner-modal',
  templateUrl: './banner-modal.component.html',
  styleUrls: ['./banner-modal.component.scss']
})
export class BannerModalComponent {

  constructor(
    private modalService: ModalService,
    private bannerReadCommand: BannerReadCommand,
    @Inject(MAT_DIALOG_DATA) public data: {banner: BannerReadModel}
  ) { }

  closeModal() {
    this.modalService.closeModal();

    
    this.bannerReadCommand.setRead(this.data.banner.id);
  }
}
