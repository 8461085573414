import { Component } from '@angular/core';
import { AccountLogModel } from 'src/app/models/account-log.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-log-account',
  templateUrl: './log-account.component.html',
  styleUrls: ['./log-account.component.scss']
})
export class LogAccountComponent {
  logs: AccountLogModel[] = [];

  constructor(private modalService: ModalService,) {

  }

  ngOnInit(): void {
    this.logs = this.modalService.getContent() as AccountLogModel[];
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
