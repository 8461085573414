import { Component } from '@angular/core';
import { UserCommand } from 'src/app/commands/user.command';
import { UserModel } from 'src/app/models/user.model';
import { WithdrawModel } from 'src/app/models/withdraw.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-receipt-modal',
  templateUrl: './receipt-modal.component.html',
  styleUrl: './receipt-modal.component.scss'
})
export class ReceiptModalComponent {
  user: UserModel;
  withdraw: WithdrawModel;

  constructor(
    private modalService: ModalService,
    private userCommand: UserCommand,
  ) {}

  ngOnInit(): void {
    this.userCommand.get().then((result: UserModel) => this.user = result);
    this.withdraw = this.modalService.getContent();
    this.modalService.setContent(null);
  }

  name() {
    return this.user.name.split(' ')[0];
  }

  taxWithdraw(currency: string){
    if(currency == this.withdraw.currency)
      return this.withdraw.withdraw_rate_usd??0;
    
    if(currency == 'USD')
      return ((this.withdraw.withdraw_rate_usd??0)/(this.withdraw.dolar_quote??0));

    return ((this.withdraw.withdraw_rate_usd??0)*(this.withdraw.dolar_quote??0));
  }

  saque(){
    if(this.withdraw.currency == 'USD')
      return ((this.withdraw.value??0)*(this.withdraw.dolar_quote??0));
    return (this.withdraw.value??0);
  }

  subTotal(){
    return this.saque() - this.taxWithdraw('BRL');
  }

  income(){
    return this.subTotal()*((this.withdraw.income_tax??0)/100);
  }

  taxInsider(){
    return this.subTotal()*((this.withdraw.insider_invest_tax??0)/100);
  }

  total(){
    return this.subTotal() - this.income() - this.taxInsider();
  }
}
