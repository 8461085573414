import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImportCommand } from 'src/app/commands/import.command';
import { AlertService } from 'src/app/components/alert/alert.service';
import { ImportFileModel } from 'src/app/models/import-file.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {

  accept: string = ".csv,.xlsx,.xls";
  loading: boolean = false;
  account_operation: number;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private firebaseService: FirebaseService,
    private alert: AlertService,
    private importCommand: ImportCommand,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (params: any) => {
      if (params.id) {
        this.account_operation = params.id;
      } else {
        this.account_operation = null
      }
    });
  }

  goBack() {
    this.location.back();
  }

  onDragOver(event: any) {
    event.preventDefault();
    const dropZone = document.getElementById("file-drop-zone");
    dropZone.classList.add("file-drop-zone-active");
  }

  onDragLeave(event: any) {
    event.preventDefault();
    const dropZone = document.getElementById("file-drop-zone");
    dropZone.classList.remove("file-drop-zone-active");
  }

  onDrop(event: any) {
    this.firebaseService.eventClick(`on-drop`, 'import-file');
    event.preventDefault();
    const dropZone = document.getElementById("file-drop-zone");
    dropZone.classList.remove("file-drop-zone-active");
    const file = event.dataTransfer.files[0];

    const fileExtension = file.name.split('.').pop();
    if (this.validateFormatFile(fileExtension.toLowerCase())) {
      this.importFile(file);
    }
  }

  validateFormatFile(fileExtension: string) {
    if (fileExtension == 'csv' || fileExtension == 'xls' || fileExtension == 'xlsx') {
      return true;
    } else {
      this.alert.presentAlert("error", "", "Informe um arquivo do tipo cvs, xls ou xlsx");
      return false;
    }
  }

  handleUpload(event: any) {
    try {
      const file = event.target.files[0];
      this.firebaseService.eventClick(`click-select-file`, 'import-file');

      const fileExtension = file.name.split('.').pop();
      if (this.validateFormatFile(fileExtension.toLowerCase())) {
        this.importFile(file);
      }

    } catch (error) {
      console.log("import-spreadsheets-page", { local: 'handleUpload', error: error });
    }
  }

  importFile(file: any) {
    if (file) {
      let importFile: ImportFileModel = new ImportFileModel();
      importFile.file = file;
      importFile.file_type = 5;
      importFile.account_operation = this.account_operation;

      this.importCommand.import(importFile).then((result: boolean) => {
        if(result)
          this.goBack();
      }).catch((error) => {
        console.log(error)
      });
    }
  }
}
