import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/utils/modal.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-modal-account-payment',
  templateUrl: './modal-account-payment.component.html',
  styleUrls: ['./modal-account-payment.component.scss']
})
export class ModalAccountPaymentComponent {
  accounts: {id: number, account: string, end_date_plan: string}[];
  account: string = null;
  type: number = 1;
  platform: string = 'MT5';

  constructor(
    private modalService: ModalService,
    private alertComponent: AlertService,
  ) { }

  ngOnInit() {
    const {accounts, type, platform} = this.modalService.getContent();
    this.accounts = accounts;
    if(this.accounts.length == 1)
      this.account = this.accounts[0].id.toString();
    this.type = type;
    this.platform = platform;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal({accept: false});
  }

  confirm() {
    if(!this.type)
      return this.alertComponent.presentAlert("warning", "Preencha os campos corretamente", "Preencha os campos corretamente");
    if(this.type != 1 && !this.account)
      return this.alertComponent.presentAlert("warning", "Preencha os campos corretamente", "Preencha os campos corretamente");

    this.modalService.closeModal({accept: true, type: this.type, account: this.account});
  }
}
