<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        {{title}}
    </div>
    <div class="subtitle">
        <span [innerHtml]="subtitle " class="over-text"></span>
    </div>
    <button class="button-primary" *ngIf="success" (click)="redirectAccount()">
        <span>Acessar minha conta</span>
    </button>
</div>