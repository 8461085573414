import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class PerformanceCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get(account: string = null) {
        this.sidenavService.enableProgressBar();

        let filter: string = '';

        if (account) {
            filter = `?account=${account}`;
        }
        
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/operating/performance/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        //this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    operations(account: number = null) {
        this.sidenavService.enableProgressBar();

        let filter: string = '';

        if (account) {
            filter = `?account=${account}`;
        }
        
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/operating/operatings/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    page(pg = '') {
        this.sidenavService.enableProgressBar();
        const params = pg.split('?');
        if(params){
            const api = new ApiRequestService(this.http, `/operating/operatings/?${params[1]}`);
            return new Promise((resolve) => {
    
                api.get<any>().subscribe({
                    next: (result: any) => {
    
                        resolve(result);
                        this.sidenavService.disableProgressBar();
                    },
                    error: (err) => {
                        this.requestError.handleError(err);
                        resolve(null);
                        this.sidenavService.disableProgressBar();
                    }
                });
            });
        }else{
            return new Promise((resolve) => {
                resolve(null);
                this.sidenavService.disableProgressBar();
            });
        }
    }
}