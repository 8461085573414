import { Injectable } from "@angular/core";
import moment from "moment";
import { AccountOperationModel } from "src/app/models/account-operation.model";
import { OperationModel, WeekOperationModel } from "src/app/models/dashboard.model";
import { WithdrawModel } from "src/app/models/withdraw.model";

@Injectable({
    providedIn: 'root'
})

export class FormatDataChartService {
    month: string[] = ['', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    formatEvolution(operations: OperationModel[], valueAccount: number){
        if(operations){
            let sum = valueAccount;
            let result: any[] = [['Saldo inicial', sum]]
            result = result.concat(operations.map(item => [item.operation_date? moment(item.operation_date).format("DD/MM/YYYY"):(`${this.month[item.month]}/${item.year}`), sum += Number(item.value)]));

            return result;
        }
        return [];
    }

    formatDay(operations: OperationModel[]){
        if(operations){
            let sum = 0,
            ops: any = operations.map(item => [item.asset, sum += Number(item.value)]);
            return [['', 0]].concat(ops);
        }
        return [];
    }

    formatWeek(operations: WeekOperationModel[]){
        if(operations){
            return operations.map(item => [moment(item.date).format("DD/MM/YYYY"), Number(item.total)]);
        }
        return [];
    }

    consGraph(value: number, len: number, description: string){
        let data = [];
        for(let i=0; i< len + 1;i++){
            data.push([description, Number(value)]);
        }
        return data;
    }

    limitLoss(ops_evolution: Array<Array<any>>, account_operation: AccountOperationModel, listWithdraw: WithdrawModel[], description: string) {

        let data = [];

        let value_account = Number(account_operation.plan.value_account)
        let value_trailing_drawdown = value_account - Number(account_operation.plan.trailing_drawdown)
        let plan_trailing_drawdown = Number(account_operation.plan.trailing_drawdown)
        let account_balance = value_account
        
        ops_evolution.forEach((op) => {
            const [day, month, year] = op[0].split('/').map(Number);
            const dateOP = new Date(year, month - 1, day);

            account_balance = Number(op[1])
            listWithdraw = listWithdraw.filter(x => !x.calculated)
            
            if ((account_balance - plan_trailing_drawdown) > value_trailing_drawdown && value_trailing_drawdown < value_account) {

                value_trailing_drawdown = account_balance - plan_trailing_drawdown

                if (value_trailing_drawdown >= value_account)
                    value_trailing_drawdown = value_account
            }

            if (listWithdraw) {
                for (let index = 0; index < listWithdraw.length; index++) {
                    if (new Date(listWithdraw[index].created_at.split("T")[0]) <= dateOP){
                        value_trailing_drawdown = value_trailing_drawdown + Number(listWithdraw[index].value)
                        listWithdraw[index].calculated = true;
                    }
                }
            }                    

            data.push([description, value_trailing_drawdown]);
        });
        
        return data;
    }

    limitPix(ops_evolution: Array<Array<any>>, limit: number, listWithdraw: WithdrawModel[]) {
        let data = [], indexWithdraw = 0;
        ops_evolution.reverse();
        ops_evolution.forEach((item) => {
            let date = this.transformDate(item[0]);
            data.push(["Limite de saque", limit]);
            
            if( indexWithdraw < listWithdraw.length && date < new Date(listWithdraw[indexWithdraw].created_at)){
                limit -= listWithdraw[indexWithdraw].value;
                indexWithdraw++;
            }
        });
        
        ops_evolution.reverse();
        return data.reverse();
    }

    transformDate(date: string) {
        if(!date.includes('/'))
            return new Date(0);
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}T00:00`);
    }
}