import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { AngularFirePerformance } from '@angular/fire/compat/performance';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    arrayTraces: any[] = [];

    constructor(
        private angularMessaging: AngularFireMessaging,
        private angularAnalytics: AngularFireAnalytics,
        private angularPerformance: AngularFirePerformance) { }

    viewScreen(page: string) {
        this.angularAnalytics.setCurrentScreen(page);
    }

    eventClick(description: string, page: string) {
        this.angularAnalytics.logEvent("event_click", {
            button_name: description.replace("_", "-"),
            page: page,
            platform: "web",
        });
    }

    eventContent(type: string, page: string, content: any) {
        this.angularAnalytics.logEvent("view_content", {
            event_type: type,
            page: page,
            platform: "web",
            event_content: content
        });
    }

    async startTracePerformance(name: string) {
        const trace = await this.angularPerformance.trace('GET /account/app-configuration');
        await trace.start();

        this.arrayTraces.push({ trace: trace, name: name });
    }

    async stopTracePerformance(name: string) {
        let pos: number = this.arrayTraces.findIndex(x => x.name == name);

        if (pos >= 0) {
            await this.arrayTraces[pos].trace.stop();
            this.arrayTraces = this.arrayTraces.splice(pos, 0);
        }
    }

    getToken() {
        return new Promise(async (resolve, reject) => {
            if (Notification.permission == 'granted') {
                await this.angularMessaging.getToken.subscribe((token: any) => {
                    resolve(token);
                });
            }
            else {
                resolve('');
            }
        })
    }
}