import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-enabled-account-manual',
  templateUrl: './enabled-account-manual.component.html',
  styleUrls: ['./enabled-account-manual.component.scss']
})
export class EnabledAccountManualComponent {
  configuration: ConfigurationModel;
  user: UserModel;
  
  constructor(
    private configurationCommand: ConfigurationCommand,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('user')) as UserModel;

    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;

      if (this.configuration.url_tutorial_meta_vision_manual)
        this.configuration.url_tutorial_meta_vision_manual = this.getSafeUrlYoutube(this.configuration.url_tutorial_meta_vision_manual)
    });
  }

  download_file(url: string) {
    window.open(url, '_blank');
  }


  getSafeUrlYoutube(url: any) {
    if (!url) return;
    if (typeof url === "object") return url; //Url has already undergone treatment

    if (url.includes('watch?v=')) url = url.replace('watch?v=', 'embed/');
    if (url.includes('youtu.be')) url = url.replace('youtu.be', 'youtube.com/embed');
    if (url.includes('&')) url = url.substring(0, url.indexOf('&'));
    if (url.includes('vimeo.com') && !url.includes('player.vimeo.com')) url = url.replace('vimeo.com', 'player.vimeo.com/video');

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
