import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PixModel } from 'src/app/models/pay.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-pix-modal',
  templateUrl: './pix-modal.component.html',
  styleUrls: ['./pix-modal.component.scss']
})
export class PixModalComponent {
  pix: PixModel;

  constructor(
    private modalService: ModalService,
    private _snackBar: MatSnackBar,
  ) {}
  
  ngOnInit() {
    this.pix = this.modalService.getContent() as PixModel;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }

  copyPix() {
    this._snackBar.open('Código PIX copiado com sucesso.', '',  {duration: 2000})
  }
}
