import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { WithdrawCommand } from 'src/app/commands/withdraw.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { RequestModalComponent } from '../request-modal/request-modal.component';
import { AlertService } from 'src/app/components/alert/alert.service';
import { WithdrawAvailableModel, WithdrawModel } from 'src/app/models/withdraw.model';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { ConfirmRequestComponent } from '../confirm-request/confirm-request.component';

@Component({
  selector: 'app-withdraw-history',
  templateUrl: './withdraw-history.component.html',
  styleUrls: ['./withdraw-history.component.scss']
})
export class WithdrawHistoryComponent {
  loading: boolean = false;
  listWithDraw: WithdrawModel[] = [];
  listAccounts: AccountOperationModel[] = [];
  account_operation: AccountOperationModel;
  account_operation_selected: AccountOperationModel;
  withdraw_value: string = '';
  withdraw_error: boolean = false;
  avaliable: WithdrawAvailableModel;
  showForm: boolean = false;
  interval_date: {init_date: Date, end_date: Date, last_month: Date} = null;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private withdrawCommand: WithdrawCommand,
    private router: Router,
    private inputFormsService: InputFormsService,
    private modalService: ModalService,
    private alertComponent: AlertService,
    private contentPageService: PagesContentService,
    private activatedRoute: ActivatedRoute,
  ){ }

  ngOnInit() {
    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('saque');

      if(page_block){
        this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
        this.router.navigate(['home']);
      }
    },1000);
    this.setIntervalDate();
    this.accountOperationCommand.get().then((result: AccountOperationModel[]) => {
      if (result) {
        this.listAccounts = result.filter(account => account.type == 2 && account.status == 2);
        if(this.listAccounts.length>0) {

          this.account_operation_selected = this.listAccounts[0];
          this.seach(this.account_operation_selected)
        }
      }
    });
  }

  seach(account: AccountOperationModel) {
    this.account_operation = null;
    this.listWithDraw = [];
    this.withdraw_value = '';
    setTimeout(() => {
      this.account_operation = account;
      Promise.all([
        this.withdrawCommand.get(this.account_operation.id),
        this.withdrawCommand.available(this.account_operation.id),
      ]).then((data: any) => {
        let result = data[0], available = data[1];
        if(result && result.results)
          this.listWithDraw = result.results;
        if(available && available.data)
          this.avaliable = available.data;
      });
    }, 100);
  }

  confirmRequest() {
    if(this.avaliable && this.avaliable.withdraw_available > 0 && this.showForm){
      this.modalService.openModal(ConfirmRequestComponent, '400px', null, null, (confirm: boolean) => {
        if(confirm)
          this.request()
      });
    }

  }

  request() {
    const value = this.avaliable.withdraw_available;
    this.modalService.setContent({value: value, account_operation: this.account_operation.id});
    this.modalService.openModal(RequestModalComponent, '800px', null, null, (result: boolean) => {
      if(result)
        setTimeout(() => {
          this.seach(this.account_operation);
        }, 1000);  
    });
  }

  setIntervalDate() {
    const today = new Date();
    let init_month = new Date(new Date().setDate(1));
    let five_day = new Date(new Date().setDate(5));
    
    this.showForm = init_month <= today && today <= five_day;
    if(!this.showForm){
      init_month.setMonth(init_month.getMonth()+1);
      five_day.setMonth(five_day.getMonth()+1);
    }
    this.interval_date = {
      init_date: init_month,
      end_date: five_day,
      last_month: new Date(new Date().setDate(0))
    }
  }
}
