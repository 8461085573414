import { Component } from '@angular/core';
import { TermUseModel } from 'src/app/models/term-use.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  termUse: TermUseModel = null;
  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.termUse = this.modalService.getContent() as TermUseModel;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
