import { Component } from '@angular/core';
import { TermUseCommand } from 'src/app/commands/term-use.command';
import { TermUseModel } from 'src/app/models/term-use.model';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {
  termUse: TermUseModel;

  constructor(
    private termUseCommand: TermUseCommand,
  ){ }

  ngOnInit() {
    this.termUseCommand.get().then((result: TermUseModel) => {
      this.termUse = result;
    });
  }
}
