import { Component } from '@angular/core';
import { SidenavService } from './sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoginCommand } from 'src/app/commands/login.command';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from '../alert/alert.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { ResetInfoComponent } from '../reset-info/reset-info.component';
import { ResetInfoCommand } from 'src/app/commands/reset-info.command';
import { ResetInfoModel } from 'src/app/models/reset-info.model';
import { SupportInfoComponent } from '../support-info/support-info.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  retract: boolean = false;
  itemSelected: string = 'home';

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private loginCommand: LoginCommand,
    public pagesContentService: PagesContentService,
    private alertComponent: AlertService,
    private resetInfoCommand: ResetInfoCommand,
    private modalService: ModalService,
  ){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        const path = event.url.split('?')[0].split('/')[1];
        if(path)
          this.itemSelected = path
      }
    });
  }

  closeMenu(){
    this.sidenavService.onSidenavToggle();
    this.sidenavService.getSidenavToogle().subscribe(
      value => {
        this.retract = value;
      }
    );
  }

  openPage(page: string){

    let page_block = this.pagesContentService.getBlock(page);

    if (page_block) {
      this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message, '', 3);
    } else {
      this.itemSelected = page;
      this.sidenavService.onSidenavToggle();
      this.router.navigate([`/${page}`]);
    }
    
  }

  logout(){
    const token:string = localStorage.getItem("token") as string;
    this.loginCommand.logout(token); 
    localStorage.clear();
    this.router.navigate(['/login']);
    this.closeMenu();
  }

  support() {
    this.modalService.openModal(SupportInfoComponent)
  }
}
