import { Component, Input } from '@angular/core';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { PerformanceModel } from 'src/app/models/performance.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-table-rules-objectives',
  templateUrl: './table-rules-objectives.component.html',
  styleUrls: ['./table-rules-objectives.component.scss']
})
export class TableRulesObjectivesComponent {
  @Input() plans: PlanModel[];
  expanded = [false, false, false, false, false, false, false, false, false, false];

  updateExpanded(position) {
    this.expanded[position] = !this.expanded[position];
    for (let index = 0; index < this.expanded.length; index++) {
      this.expanded[index] = index == position ? this.expanded[index] : false;
    }
  }

  sum(a: any, b: any) {
    return Number(a) + Number(b);
  }

  maxDaysNotOp() {
    let countDay = 100;
    this.plans.forEach(item => {
      countDay = item.limit_days_not_operation <= countDay? item.limit_days_not_operation: countDay;
    });
    return countDay;
  }

  minDaysOp() {
    let countDay = 0;
    this.plans.forEach(item => {
      countDay = item.minimum_days_operation > countDay? item.minimum_days_operation: countDay;
    });
    return countDay;
  }

  hasStock() {
    return this.plans.some(item => item.is_stock);
  }
}
