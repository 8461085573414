import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WithdrawCommand } from 'src/app/commands/withdraw.command';
import { AlertService } from 'src/app/components/alert/alert.service';
import { WithdrawModel } from 'src/app/models/withdraw.model';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent {
  withdraw: WithdrawModel;
  view: string = 'pix';

  formPix: UntypedFormGroup;
  formDeposit: UntypedFormGroup;
  submitted: boolean = false;

  constructor(
    private withdrawCommand: WithdrawCommand,
    private modalService: ModalService,
    private inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    private alertComponent: AlertService,
  ) { }

  ngOnInit() {
    this.withdraw = this.modalService.getContent() as WithdrawModel;
    this.modalService.setContent(null);

    this.formPix = this.formBuilder.group({
      pix: ['', [Validators.required,]],
    });

    this.formDeposit = this.formBuilder.group({
      bank: [null, [Validators.required]],
      agency: [null, [Validators.required]],
      type_transfer: [1, [Validators.required]],
      account: [null, [Validators.required]],
    });
  }

  closeModal(value: boolean = false) {
    this.modalService.closeModal(value);
  }

  changeView(view:string) {
    this.view = view;
    this.submitted = false;
  }

  get errorCtrlPix() {
    return this.formPix.controls;
  }

  get errorCtrlDeposit() {
    return this.formDeposit.controls;
  }

  focus(type: string, element: string, form: any = null) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, form, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  submit(type: string) {
    this.submitted = true;

    let withdraw: WithdrawModel = {
      account_operation: this.withdraw.account_operation,
      value: this.withdraw.value,
      type_payment: type == 'pix'? 1: 2,
      pix: null,
      note: null,
      account: null,
      agency: null,
      bank: null,
      type_transfer: null,
    } as WithdrawModel;
    if(type == 'pix' && this.formPix.valid){
      withdraw.pix = this.formPix.value.pix;
      this.withdrawCommand.create(withdraw).then((result: boolean) => {
        if(result){
          this.alertComponent.presentAlert("success", "Sucesso", "Solicitação enviada", '', 3);
          this.closeModal(true);
        }else
          this.alertComponent.presentAlert("error", "Erro na solicitação", "Não foi possível realizar a solicitação.", '', 3);
      });
    }
    if(type == 'deposit' && this.formDeposit.valid){
      withdraw.account = this.formDeposit.value.account;
      withdraw.agency = this.formDeposit.value.agency;
      withdraw.bank = this.formDeposit.value.bank;
      withdraw.type_transfer = this.formDeposit.value.type_transfer;
      this.withdrawCommand.create(withdraw).then((result: boolean) => {
        if(result){
          this.alertComponent.presentAlert("success", "Sucesso", "Solicitação enviada", '', 3);
          this.closeModal(true);
        }else
          this.alertComponent.presentAlert("error", "Erro na solicitação", "Não foi possível realizar a solicitação.", '', 3);
      });
    }

  }
}
