import { Component } from '@angular/core';
import { PayErrorModel } from 'src/app/models/pay.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-modal-pay-error',
  templateUrl: './modal-pay-error.component.html',
  styleUrls: ['./modal-pay-error.component.scss']
})
export class ModalPayErrorComponent {
  messages: PayErrorModel[] = [];

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.messages = this.modalService.getContent() as PayErrorModel[];
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
