import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CouponModel } from 'src/app/models/coupon.model';
import { OneOffChargeModel } from 'src/app/models/one-off-charge.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-payment-message',
  templateUrl: './payment-message.component.html',
  styleUrls: ['./payment-message.component.scss']
})
export class PaymentMessageComponent {
  plan: PlanModel;
  type: string = 'success';
  payment: PaymentModel;
  coupon: CouponModel;
  message: string;
  loading: boolean = false;
  nav: any;
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;

  constructor(
    private router: Router,
  ){
    this.nav = this.router.getCurrentNavigation();
    this.plan = this.nav.extras.state ? this.nav.extras.state.plan as PlanModel : null;
    this.type = this.nav.extras.state ? this.nav.extras.state.type as string : null;
    this.payment = this.nav.extras.state ? this.nav.extras.state.payment as PaymentModel : null;
    this.coupon = this.nav.extras.state ? this.nav.extras.state.coupon as CouponModel : null;
    this.coupon = this.nav.extras.state ? this.nav.extras.state.coupon as CouponModel : null;
    this.message = this.nav.extras.state ? this.nav.extras.state.message_error as string : null;

    if (this.plan)
      this.blackArrowValue = this.plan.value_black_arrow;
  }

  resend() {
    this.router.navigate(['pagamento', this.plan.id], {state: {payment: this.payment}})
  }

  openSalesPage() {
    window.open('https://insiderinveste.com')
  }
}
