import { Component, OnInit } from '@angular/core';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  textMaintenance: string = '';

  constructor(
    private firebaseService: FirebaseService,
    private configurationCommand: ConfigurationCommand,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('manutencao');

    this.textMaintenance = 'Estamos trabalhando em novas atualizações e melhorias no site. Dentro de alguns minutos, o acesso será restabelecido.';

    this.getConfiguration()
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      if (result)
        this.textMaintenance = result.text_maintence;
      else
        this.textMaintenance = 'Estamos trabalhando em novas atualizações e melhorias no site. Dentro de alguns minutos, o acesso será restabelecido.';

    });
  }
}