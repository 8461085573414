<app-auth-header></app-auth-header>

<div class="content">
    <section>
        <div class="title">FAQ</div>
    </section>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Meu saldo está divergente com a plataforma, o que fazer?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <section>
                    <div class="subtitle">Meu saldo está divergente com a plataforma, o que fazer?</div>
                    <div class="over-text">Caso você perceba uma divergência no saldo exibido em sua conta e na
                        plataforma, recomendamos os seguintes passos para identificar e resolver o problema:</div>
                    <div class="over-text margin-top">
                        <strong>1 - Verifique a configuração do Insider Investe Real Time: </strong>
                        <span>Certifique-se de que o Insider Investe Real Time está devidamente adicionado ao gráfico na
                            plataforma MetaTrader 5. Ele é essencial para a sincronização dos dados da conta com nosso
                            sistema.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>2 - Considere o tempo de sincronização: </strong>
                        <span>O Insider Investe Real Time pode levar até 10 minutos para sincronizar as informações com
                            o servidor. Recomendamos que aguarde este período para que os dados sejam atualizados
                            corretamente.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>3 - Confirme que a plataforma foi encerrada corretamente: </strong>
                        <span>Caso a plataforma tenha sido fechada antes da sincronização ser concluída, os dados podem
                            não ter sido enviados ao servidor. Abra novamente a plataforma, garanta que o Insider Investe Real Time
                            esteja funcionando no gráfico e aguarde o tempo necessário para a sincronização.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>4 - Persistência do erro: </strong>
                        <span>Se, após seguir todos os passos acima, a divergência ainda persistir, entre em contato com
                            o nosso time de suporte. Estamos à disposição para ajudá-lo a resolver qualquer problema e
                            garantir que sua experiência seja a melhor possível.</span>
                    </div>
                    <div class="over-text margin-top">
                        Lembre-se: manter o Insider Investe Real Time ativo é fundamental para evitar problemas de sincronização e
                        garantir que todas as informações estejam atualizadas de forma confiável.
                    </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Ao renovar a conta o saldo será resetado?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <section>
                    <div class="subtitle">Ao renovar a conta o saldo será resetado?</div>
                    <div class="over-text">Sim, o saldo será resetado em algumas situações, mas é importante considerar
                        as condições específicas:</div>
                    <div class="over-text margin-top">
                        <strong>1 - Renovação com cupom promocional: </strong>
                        <span>Se a renovação foi realizada utilizando um cupom promocional que garante a continuidade da
                            mensalidade com o mesmo valor pago no primeiro mês, não será possível solicitar uma nova
                            conta durante a renovação. Nesse caso, o cliente continuará operando com a mesma
                            conta.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>2 - Renovação regular (sem cupom): </strong>
                        <span>Para contas adquiridas sem o uso de cupons promocionais, o cliente terá a opção de:</span>
                        <br>
                        <span><strong>&nbsp; &nbsp; &nbsp; &nbsp;• Manter a mesma conta: </strong>Continuar com o saldo e
                            o histórico atuais, inclusive em caso de saldo negativo.</span>
                        <br>
                        <span><strong>&nbsp; &nbsp; &nbsp; &nbsp;• Solicitar uma nova conta:</strong> Receber uma conta
                            zerada no ato da renovação.</span>
                    </div>
                    <div class="over-text margin-top">Essa flexibilidade é válida para garantir que a renovação atenda
                        às necessidades e objetivos do cliente. Recomendamos revisar os termos de renovação antes do
                        pagamento para esclarecer quaisquer dúvidas.</div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Levei um stop por ter atingido a perda máxima diária, serei reprovado?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-panel-description>
                <section>
                    <div class="subtitle">Levei um stop por ter atingido a perda máxima diária, serei reprovado?</div>
                    <div class="over-text">Não, alcançar o limite de perda máxima diária não resulta automaticamente na reprovação. No entanto, é importante observar as seguintes condições:</div>
                    <div class="over-text margin-top">
                        <strong>1 - Atingir o limite: </strong>
                        <span>Caso você atinja a perda máxima diária e encerre suas operações imediatamente, sem ultrapassar o valor da sua perda máxima diária sua conta não será reprovada.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>2 - Ultrapassar o limite:</strong>
                        <span>Ultrapassar o limite permitido para a perda máxima diária resultará na reprovação da conta.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>3 - Continuar operando após atingir o limite: </strong>
                        <span>Mesmo que o limite não tenha sido ultrapassado, continuar operando na tentativa de recuperar perdas também resultará na reprovação, conforme estabelecido no regulamento.</span>
                    </div>

                    <div class="over-text margin-top">
                        <span>Recomendamos que você consulte o regulamento para mais detalhes:</span><br>
                        <a href="https://app.insiderinveste.com.br/termos-e-condicoes" target="_blank">Clique aqui para acessar o regulamento</a>
                        <br>
                        <a href="https://app.insiderinveste.com.br/ativos-permitidos" target="_blank">Veja a lista completa de regras e objetivos</a>
                    </div>

                    <div class="over-text margin-top">
                        Seguir as regras e limites estabelecidos é essencial para garantir seu progresso e sucesso em nossa mesa.
                    </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Qual a periodicidade de sincronização da plataforma BlackArrow?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <section>
                    <div class="subtitle">Qual a periodicidade de sincronização da plataforma BlackArrow?</div>
                    <div class="over-text">A sincronização da plataforma BlackArrow ocorre diariamente. O processo de importação das operações é realizado até as <strong>10:00 horas da manhã</strong>, momento em que são registradas e importadas as operações realizadas no <strong>dia anterior.</strong></div>
                    <div class="over-text margin-top">
                        Caso você note alguma divergência após este horário, entre em contato com nosso time de suporte para verificações adicionais.
                    </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Após bater a meta de saldo quando recebo a conta real?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Após bater a meta de saldo quando recebo a conta real?</div>
                    <div class="over-text">Após atingir a meta de saldo, sua conta passará por uma análise realizada pelo nosso time técnico. Durante esse processo, verificamos se todos os requisitos de aprovação foram devidamente cumpridos.</div>
                    <div class="over-text margin-top">Esse procedimento pode levar até <strong>5 dias úteis</strong>, e, ao final da análise, sua conta real será liberada, caso seja aprovada.</div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Posso operar mais de um ativo ao mesmo tempo?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Posso operar mais de um ativo ao mesmo tempo?</div>
                    <div class="over-text">Sim, é possível operar mais de um ativo simultaneamente, mas existem algumas regras importantes a serem seguidas:</div>
                    
                    <div class="over-text margin-top">
                        <strong>1 - Planos de índices e derivativos:</strong><br>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp;• Não é permitido operar todos os ativos com o lote máximo permitido ao mesmo tempo.</span><br>
                        <span>&nbsp; &nbsp; &nbsp; &nbsp;• Você deve gerenciar o lote de suas operações. Por exemplo, se estiver utilizando 50% dos contratos permitidos em um ativo, ao abrir uma ordem em outro ativo, você terá apenas os outros 50% da margem de contratos permitidos disponíveis para operar nesse segundo ativo.</span>
                    </div>

                    <div class="over-text margin-top">
                        <strong>2 - Índices de ações:</strong><br>
                        <span>É necessário observar o limite do poder de compra da conta. A soma das operações em aberto não pode ultrapassar o valor total do poder de compra disponível. Para mais detalhes e exemplos práticos, <a target="_blank" href="https://app.insiderinveste.com.br/poder-de-compra">clique aqui</a>.</span>
                    </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Quando inicia a validade da conta?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Quando inicia a validade da conta?</div>
                    <div class="over-text">A validade da conta se inicia no ato da confirmação do pagamento, com as seguintes exceções:</div>
                    <div class="over-text margin-top">
                        <strong>1 - Compras realizadas com cupons promocionais específicos que permitem agendamento: </strong><br>
                        <span>Alguns cupons permitem agendar a data de início do teste. Nesse caso, a validade será contada a partir da data agendada.</span>
                    </div>
                    <div class="over-text margin-top">
                        <strong>2 - Outras formas de aquisição: </strong><br>
                        <span>Para qualquer outra forma de compra, a contagem da validade inicia imediatamente após a confirmação do pagamento.</span>
                    </div>
                    <div class="over-text margin-top">Caso tenha dúvidas sobre a ativação ou as condições de validade, entre em contato com o nosso suporte!</div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Posso operar pelo celular?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Posso operar pelo celular?</div>
                    <div class="over-text">Sim, é possível operar pelo celular:</div>
                    <div class="over-text margin-top">
                        <strong>1 - Plataforma Black Arrow: </strong> <br>
                        <span>A Black Arrow está disponível para dispositivos móveis, permitindo que você acompanhe e gerencie suas operações diretamente pelo celular.</span>  
                    </div>
                    <div class="over-text margin-top">
                        <strong>2 - Plataforma MetaTrader: </strong> <br>
                        <span>É permitido operar pelo celular, mas é obrigatório que um computador esteja aberto com o Insider Investe Real Time devidamente configurado enquanto você estiver operando.</span>  <br>
                        <span>Lembre-se: o Insider Investe Real Time é essencial para garantir a sincronização e o correto funcionamento das operações.</span>
                    </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Ao ser aprovado, preciso pagar mensalidade?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Ao ser aprovado, preciso pagar mensalidade?</div>
                    <div class="over-text margin-top">
                        Não, após ser aprovado na mesa proprietária, não é necessário pagar a mensalidade referente ao valor do plano.
                    </div>
                    <div class="over-text margin-top">
                        No entanto, se o seu plano for utilizando a plataforma <strong>Black Arrow</strong>, para manter ativa a plataforma, é necessário realizar o pagamento mensal da assinatura no valor de <strong>R$ 120,20</strong>.
                     </div>
                     <div class="over-text margin-top">
                        Certifique-se de manter o pagamento em dia para garantir a continuidade do acesso à plataforma.
                     </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Posso perder o acesso à conta real?
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            
            <mat-panel-description>
                <section>
                    <div class="subtitle">Posso perder o acesso à conta real?</div>
                    <div class="over-text margin-top">
                        Sim, na conta real é necessário manter o cumprimento das regras. Qualquer descumprimento das normas estabelecidas em nosso regulamento resultará no encerramento imediato da conta real.
                    </div>
                    <div class="over-text margin-top">
                        Entretanto, após ser aprovado, caso o trader perca acesso à conta real, oferecemos uma condição exclusiva através do nosso programa de aprovação permanente. Este programa permite que o trader retorne diretamente à conta real sem a necessidade de passar novamente pelo processo de avaliação.
                     </div>
                </section>
            </mat-panel-description>
        </mat-expansion-panel>
    </mat-accordion>
</div>