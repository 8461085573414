import { Component } from '@angular/core';

@Component({
  selector: 'app-habilitar-algotrading',
  templateUrl: './habilitar-algotrading.component.html',
  styleUrls: ['./habilitar-algotrading.component.scss']
})
export class HabilitarAlgotradingComponent {

}
