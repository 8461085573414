<div class="header">
    <div>
        <div class="header-mobile">
            <img src="assets/images/logo_white.png" class="logo" alt="Insider Invest" [routerLink]="'/home'">
        </div>
    </div>

    <section class="items-header header-web" [class.retract]="!retract">
        <button class="button-primary new-account" [routerLink]="'/planos'" *ngIf="pagesContentService.isVisible('button-reset')">
            Nova conta Insider
        </button>
    </section>

    <section class="items-header header-mobile">
        <div class="item" (click)="toggleMenu()">
            <i class="fa-solid fa-bars"></i>
        </div>
    </section>
    <section class="items-header header-web logout-web">
        <div class="item item-user" mat-icon-button [matMenuTriggerFor]="menu">
            <div class="img-profile" *ngIf="user">
                <span *ngIf="!user.profile_image" class="initials">{{initialsName()}}</span>
                <img *ngIf="user.profile_image" [src]="user.profile_image" alt="profile picture" class="profile">
            </div>
            <div class="img-profile" *ngIf="!user">
                <i class="fa-solid fa-user"></i>
            </div>
            <div class="welcome">
                <span>Olá,</span>
                <span class="name" *ngIf="user">{{name()}}</span>
            </div>
            <div class="menu-button">
                <i class="fa-solid fa-chevron-down"></i>
            </div>
        </div>
        <mat-menu #menu="matMenu">
            <span class="menu-title">Minha conta</span>
            <section class="user" *ngIf="user">
                <span class="name">{{name()}}</span>
                <span class="email">{{user.email}}</span>
            </section>
            <button mat-menu-item class="button-primary" (click)="faq()">
                <i class="fa-solid fa-question"></i>
                <span>Perguntas frequentes</span>
            </button>
            <button mat-menu-item class="button-primary" (click)="support()">
                <i class="fa-solid fa-headset"></i>
                <span>Suporte</span>
            </button>
            <button mat-menu-item class="button-primary"  (click)="selectPage('minha-conta')"
                *ngIf="pagesContentService.isVisible('minha-conta')">
                <i class="fa-solid fa-user"></i>
                <span>Meus dados</span>
            </button>
            <button mat-menu-item class="button-transparent" (click)="logout()">
                <i class="fa-solid fa-sign-out"></i>
                <span>Sair</span>
            </button>
        </mat-menu>
    </section>
</div>