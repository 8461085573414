import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-support-info',
  templateUrl: './support-info.component.html',
  styleUrls: ['./support-info.component.scss']
})
export class SupportInfoComponent {
  constructor(
    private modalService: ModalService) { }

  closeModal() {
    this.modalService.closeModal();
  }

  openLink() {
    window.open("https://wa.me/5562994788905?text=Olá, estou precisando de ajuda do suporte.", '_blank');
  }
}
