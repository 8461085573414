<div class="card" *ngIf="account">
  <div class="cnt">
    <table class="table-default">
      <thead>
        <tr class="tr-header">
          <th class="table-header"></th>
          <th class="table-header"><span>Abertura</span></th>
          <th class="table-header"><span>Ativo</span></th>
          <th class="table-header"><span>Contratos</span></th>
          <th class="table-header"><span>Tipo</span></th>
          <th class="table-header"><span>Entrada</span></th>
          <th class="table-header"><span>Saída</span></th>
          <th class="table-header"><span>Pontos</span></th>
          <th class="table-header"><span>Lucro</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let op of listOperations">
          <td>
            <span *ngIf="op.value >= 0" class="gain">GAIN</span>
            <span *ngIf="op.value < 0" class="loss">LOSS</span>
          </td>
          <td><span>{{op.date | date:'dd/MM/yyyy'}} {{op.hour | mask: '00:00'}}</span></td>
          <td><span class="asset" translate="no">{{op.asset}}</span></td>
          <td><span translate="no">{{op.contracts}}</span></td>
          <td>
              <div class="sell" *ngIf="op.operation_type == 1">Venda</div>
              <div class="buy" *ngIf="op.operation_type == 2">Compra</div>
          </td>
          <td><span>{{op.input_value}}</span></td>
          <td><span>{{op.output_value}}</span></td>
          <td><span translate="no">{{op.points}}</span></td>
          <td class="value">
            <span>
              <span [ngClass]="{'color-primary-medium': op.value >= 0, 'color-danger': op.value < 0}">{{op.value | currency: '$'}}</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="paginator" *ngIf="pagination.status">
    <button class="button-primary" [disabled]="!pagination.prev" (click)="alterPage(pagination.prev)"><i class="fa-solid fa-chevron-left"></i></button>
    <button class="button-primary" [disabled]="!pagination.next" (click)="alterPage(pagination.next)"><i class="fa-solid fa-chevron-right"></i></button>
  </div>
</div>