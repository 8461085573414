import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";
import { AlertService } from "../components/alert/alert.service";
import { UserRegisterModel } from "../models/user-register.model";

@Injectable({
    providedIn: 'root'
})
export class TournamentCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
        private alert: AlertService,
    ) {
    }

    get(id: number = null) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account-operation/tournament/");

            this.api.get<any>(id).subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    getPublic(id: number = null, started: boolean = true) {
        let filter = id?`${id}/`: '';
        filter = started!=null? `${filter}?started=${started? 'True': 'False'}`: filter;

        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/tournament/public/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    post(tournament: UserRegisterModel) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {

            this.api = new ApiRequestService(this.http, "/account-operation/tournament/register/");

            this.api.post<any>(tournament).subscribe({
                next: (result: any) => {
                    if(result.message)
                        resolve(result.message);
                    else
                        resolve('Seu cadastro foi enviado com sucesso.');
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(false);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    getRanking(id: number, limit: number = 15) {

        return new Promise((resolve) => {
            this.sidenavService.enableProgressBar();
            
            this.api = new ApiRequestService(this.http, `/account-operation/tournament/ranking/${id}/?limit=${limit}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.sidenavService.disableProgressBar();

                    if (result && (id || result.results)) {
                        resolve(result);
                    }
                    else {
                        resolve(null);
                    }
                },
                error: (err) => {
                    this.requestError.handleError(err);
                    this.sidenavService.disableProgressBar();
                    resolve(null);
                }
            });
        });
    }

    pageRanking(id: number, pg = '') {
        const params = pg.split('?');
        if(params){
            const api = new ApiRequestService(this.http, `/account-operation/tournament/ranking/${id}/?${params[1]}`);
    
            return new Promise((resolve) => {
                this.sidenavService.enableProgressBar();

                api.get<any>().subscribe({
                    next: (result: any) => {
                        this.sidenavService.disableProgressBar();

                        if (result && result.results) {
                            resolve(result);
                        }
                        else {
                            resolve(null);
                        }
                    },
                    error: (err) => {
                        this.requestError.handleError(err);
                        this.sidenavService.disableProgressBar();
                        resolve(null);
                    }
                });
            });
        }else{
            return new Promise((resolve) =>{
                resolve(null);
            });
        }
    }
}