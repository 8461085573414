import { Component } from '@angular/core';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { ImportCommand } from 'src/app/commands/import.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { ImportModel } from 'src/app/models/import.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-history-import',
  templateUrl: './history-import.component.html',
  styleUrls: ['./history-import.component.scss']
})
export class HistoryImportComponent {
  listImport: ImportModel[] = [];
  listAccounts: AccountOperationModel[] = [];
  account_operation: AccountOperationModel;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private importCommand: ImportCommand,
    private firebaseService: FirebaseService,
  ){ }

  ngOnInit() {
    this.accountOperationCommand.get().then((result: AccountOperationModel[]) => {
      if (result) {
        this.listAccounts = result;
      }
    });
  }

  seach(account: AccountOperationModel) {
    this.account_operation = account;
    this.importCommand.get(Number(account.account)).then((result: any) => {
      this.listImport = result.results
    });
  }

  downloadFile(importModel: ImportModel) {
    this.firebaseService.eventClick(`download-file`, 'import-log');
    this.firebaseService.eventContent('download-file', 'import-log', { type: importModel.file_type, status: importModel.status });
  }

  diffDates(date: string) {
    var date1 = new Date(date)
    var date2 = new Date()
    var oneDay = 24 * 60 * 60 * 1000;
    var diffDays = Math.abs((date1.getTime() - date2.getTime()) / (oneDay));

    return diffDays;
  }


  delete(id: number) {
    this.firebaseService.eventClick(`delete-import`, 'import-log');

    /* this.alertComponent.presentDeleteAlert("Excluir", "Deseja realmente excluir essa importação?", () => {
      this.importCommand.delete(id, Number(this.account_operation.account)).then((result) => {
        this.seach(this.account_operation);
      })
    }); */
  }
}
