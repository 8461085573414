import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { PlanCommand } from 'src/app/commands/plan.command';
import { AlertService } from 'src/app/components/alert/alert.service';
import { ModalPayErrorComponent } from 'src/app/components/modal-pay-error/modal-pay-error.component';
import { SidenavService } from 'src/app/components/sidenav/sidenav.service';
import { AddressModel } from 'src/app/models/address.model';
import { PayModel, PixModel } from 'src/app/models/pay.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { TermsComponent } from '../terms/terms.component';
import { TermUseCommand } from 'src/app/commands/term-use.command';
import { TermUseModel } from 'src/app/models/term-use.model';
import { CouponModel } from 'src/app/models/coupon.model';
import { CouponCommand } from 'src/app/commands/coupon.command';
import { OneOffChargeCommand } from 'src/app/commands/one-off-charge.command';
import { OneOffChargeModel } from 'src/app/models/one-off-charge.model';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { ModalAccountPaymentComponent } from 'src/app/components/modal-account-payment/modal-account-payment.component';
import { UserModel } from 'src/app/models/user.model';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ReCaptchaV3Service }  from 'ng-recaptcha-2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  private subscription: Subscription;
  loading: boolean = false;
  user: UserModel;

  pay_method: string = 'CARTAO';
  formValidation: UntypedFormGroup;
  formCoupon: UntypedFormGroup;
  submitted: boolean = false;
  disableSubmitButton: boolean = true;
  applied: boolean = false;
  coupon: CouponModel;
  charge: OneOffChargeModel;

  platform_selected: string = 'MT5';
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;
  
  years: string[] = Array(21).fill(0).map((x,i)=>(new Date().getFullYear()+i).toString());
  plan: PlanModel;
  termUse: TermUseModel;

  payment: PaymentModel;
  nav: any;
  token_recaptcha_v2: string;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private alertComponent: AlertService,
    private activatedRoute: ActivatedRoute,
    private contentPageService: PagesContentService,
    private firebaseService: FirebaseService,
    private inputFormService: InputFormsService,
    private modalService: ModalService,
    private sidenavService: SidenavService,
    private router: Router,
    private accountOperationCommand: AccountOperationCommand,
    private couponCommand: CouponCommand,
    private oneOffChargeCommand: OneOffChargeCommand,
    private planCommand: PlanCommand,
    private paymentCommand: PaymentCommand,
    private termUseCommand: TermUseCommand,
    private configurationCommand: ConfigurationCommand,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.payment = this.nav.extras.state? this.nav.extras.state.payment as PaymentModel: null;

    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    if(queryParams.platform)
      this.changePlatform(queryParams.platform);
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.firebaseService.viewScreen('pagamento');
    this.sidenavService.enableProgressBar();
    this.sidenavService.getProgressBar().subscribe(
      value => {
        this.loading = value;
      }
    );
    this.user = JSON.parse(localStorage.getItem('user')) as UserModel;
    this.formValidation = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      email_confirm: ['', [Validators.required]],
      document: ['', [Validators.required, this.inputFormService.validateDocument]],
      phone: ['', [Validators.required]],
      payment_type: ['CARTAO', [Validators.required]],
      card_number: [null, [Validators.required]],
      card_name: [null, [Validators.required]],
      card_month: [null, [Validators.required]],
      card_year: [null, [Validators.required]],
      card_cvv: [null, [Validators.required]],
      plan_id: [null, [Validators.required]],
      accept_term: [false, [Validators.required, Validators.requiredTrue]],
      save_card: [false, []],
      installment_count: [1, []],
      ip: ['172.0.0.1', [Validators.required]],
    });

    this.formCoupon = this.formBuilder.group({
      coupon: ['', [Validators.required]],
    });

    this.activatedRoute.params.subscribe((params: any) => {
      const charge_id = this.activatedRoute.snapshot.queryParamMap.get('charge_id');
      const coupon = this.activatedRoute.snapshot.queryParamMap.get('coupon');

      Promise.all([
        this.planCommand.getPublic(params.id),
        this.termUseCommand.get(),  
        this.oneOffChargeCommand.get(charge_id),
        this.configurationCommand.get(),
      ]).then(([result, term, oneOffCharge, config]: [PlanModel, TermUseModel, OneOffChargeModel, any]) => {
        if (result) {
          if (!oneOffCharge && parseInt(charge_id) > 0) {
            this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'charge-not-found', payment: null}});
          }

          if(!charge_id){
            let page_visible = !this.contentPageService.isVisible('black-friday');
          
            if(page_visible){
              this.router.navigate(['black-friday']);
            }
          }

          this.charge = oneOffCharge;
          this.plan = result;
          this.blackArrowValue = this.plan.value_black_arrow;
          this.formValidation.patchValue({plan_id: this.plan.id});
          if(this.payment)
            this.formValidation.patchValue(this.payment);
          if(this.user)
            this.formValidation.patchValue({
              name: this.user.name,
              email: this.user.email,
              email_confirm: this.user.email,
              document: this.user.document_number,
              phone: this.user.phone_number,
            });
          if(coupon) {
            this.formCoupon.patchValue({coupon: coupon});
            setTimeout(() => {
              this.applyCoupon(true);
            }, 500);
          }
        } else {
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'plan-not-found', payment: null}});
        }
        if(term)
          this.termUse = term;
      });
    });

    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('pagamento');
    
      if(page_block){
        this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
        this.router.navigate(['home']);
      }
    },1000);
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  get errorCtrCoupon() {
    return this.formCoupon.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  updateMask(event, field: string) {
    this.inputFormService.updateMask(this.formValidation, field, event);
  }

  toUpper(){
    if(this.formCoupon.valid)
      this.formCoupon.patchValue({coupon: String(this.formCoupon.value.coupon).toUpperCase()});
  }

  async submit() {
    this.firebaseService.eventClick('buy-plan', 'pagamento');
    this.submitted = true;
    this.disableSubmitButton = true;

    if (this.formValidation.valid) {
      let payment: PaymentModel = {
        name: this.formValidation.value.name,
        email: this.formValidation.value.email,
        email_confirm: this.formValidation.value.email_confirm,
        document: this.formValidation.value.document,
        phone: this.formValidation.value.phone,
        payment_type: this.formValidation.value.payment_type,
        card_number: this.formValidation.value.card_number,
        card_name: this.formValidation.value.card_name,
        card_month: this.formValidation.value.card_month,
        card_year: this.formValidation.value.card_year,
        card_cvv: this.formValidation.value.card_cvv,
        plan_id: this.formValidation.value.plan_id,
        ip: this.formValidation.value.ip,
        platform: this.charge? 'MT5': this.platform_selected,
        accept_term: this.formValidation.value.accept_term,
        save_card: this.formValidation.value.save_card,
        coupon: this.coupon? this.coupon.name: null,
        term_id: this.termUse? this.termUse.id: null,
        charge_id: this.charge? this.charge.id: null,
        installment_count: this.formValidation.value.installment_count,
        type: 1,
        recaptcha_token_v2: this.token_recaptcha_v2
      } as PaymentModel;
      this.plan.value_plan = this.getFinalValue();

      if(payment.charge_id || (this.coupon && this.coupon.release_schedule)){
        this.doCaptchValidation(payment);
      } else {
        this.accountOperationCommand.getPublic(payment.email, ((payment.platform == 'MT5')? payment.plan_id: null), ((payment.platform == 'MT5')? 1: 2)).then((result: any) => {
          if(result) {
            if(payment.platform == 'BA')
              payment.type = 2;
            this.modalService.setContent({accounts: result, type: payment.type, platform: payment.platform});
            this.modalService.openModal(ModalAccountPaymentComponent, "600px", null, null, (data) => {
              if(data && data.accept){
                payment.type = data.type;
                if(payment.platform == 'MT5'){
                  if(data.type == 2)
                    payment.account_operation_reset = data.account;
                  if(data.type == 3)
                    payment.account_operation = data.account;
                }

                this.doCaptchValidation(payment);
              }else{
                this.disableSubmitButton = false;
              }
            });
          } else {
            this.doCaptchValidation(payment);
          }
        });
      }
    }else{
      this.disableSubmitButton = false;
    }
  }

  doCaptchValidation(payment: PaymentModel) {
    this.subscription = this.recaptchaV3Service.execute('payment').subscribe({
      next: (token: string) => {
        payment.recaptcha_token = token;
        this.confirmPayment(payment);
      },
      error: (error) => console.log(error)
    });
  }

  confirmPayment(payment: PaymentModel) {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.paymentCommand.pay(payment, JSON.stringify(queryParams)).then((result: PayModel) => {
      if(!result.valid){
        this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
        return;
      }
      
      if(this.pay_method == 'CARTAO'){
        if(result.credit_card_status == 'CONFIRMED'){
          this.router.navigate(['/pagamento-confirmado'], {state: {plan: this.plan, type: 'success', payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
          return;
        }
        if(result.credit_card_status == null){
          this.modalService.setContent(result.credit_card_error);
          this.modalService.openModal(ModalPayErrorComponent, "600px");
          return;
        }
        this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
        return;
      }
      
      if(this.pay_method == 'PIX'){
        if(!result.pix)
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
        else
          this.router.navigate([`/cobranca/pix`], {state: {plan: this.plan, pay_pix: result.pix, payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
        return;
      }
      if(this.pay_method == 'BOLETO'){
        if(!result.ticket)
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
        else
          this.router.navigate([`/cobranca/boleto`], {state: {plan: this.plan, boleto: result.ticket, payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
        return;
      }
    });
    this.disableSubmitButton = false;
  }

  changePayment(type: string){
    this.pay_method = type;
    this.formValidation.patchValue({payment_type: this.pay_method});
    const keys = ['card_number', 'card_name', 'card_month', 'card_year', 'card_cvv'];
    if(type == 'CARTAO'){
      keys.forEach(key => {
        this.formValidation.controls[key].setValidators([Validators.required]);
        this.formValidation.controls[key].updateValueAndValidity();
      });
    }else{
      keys.forEach(key => {
        this.formValidation.controls[key].setValidators([]);
        this.formValidation.controls[key].updateValueAndValidity();
      });
    }
  }

  changePlatform(type: string) {
    this.platform_selected = type;
  }

  getFinalValue(withoutPlatform: boolean = false) {
    let value: number = this.charge ? Number(this.charge.value) : Number(this.plan.value_plan);
    
    if(this.coupon) {
      if(this.coupon.type_discount == 1)
        value *= (1 - (this.coupon.value_discount/100));
      else
        value -= Number(this.coupon.value_discount);
    }

    if(withoutPlatform)
      return value;

    if(this.platform_selected == 'BA')
      return value+Number(this.blackArrowValue);
    return value+Number(this.metaTraderValue);
  }

  openModal() {
    this.modalService.setContent(this.termUse);
    this.modalService.openModal(TermsComponent, '800px', '90vh');
  }

  applyCoupon(isUrlCoupon: boolean = false) {
    this.applied = true;
    if(this.formCoupon.valid)
      this.couponCommand.post(this.formCoupon.value.coupon, this.formValidation.value.email, this.plan.id, !isUrlCoupon).then((result: CouponModel) => {
        if(result){
          this.coupon = result;
          this.coupon.name = this.formCoupon.value.coupon;
        }else{
          if(isUrlCoupon){
            this.formCoupon.patchValue({coupon: null});
            this.applied = false;
          }
        }
      });
  }

  removeCoupon() {
    this.coupon = null;
  }

  rangeList(value: number = 1): Array<number> {
    if(!this.charge && this.plan.installment_count)  value = this.plan.installment_count;
    if(this.charge && this.charge.installment_count)  value = this.charge.installment_count;
    return Array(value).fill(0).map((x, i) => i+1);
  }

  onCaptchaResolved(token) {
    this.token_recaptcha_v2 = token;
    this.disableSubmitButton = false;
  }
}
