import { Component } from '@angular/core';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {

  configuration: ConfigurationModel;
  
  constructor(
    private configurationCommand: ConfigurationCommand,
  ) { }

  ngOnInit(): void {

    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });
  }

  downloadMT5() {
    window.open('https://www.metatrader5.com/pt/download', '_blank');
  }
}
