import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";

import { HttpClient } from "@angular/common/http";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { AlertService } from "../components/alert/alert.service";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class UtilsCommand {
    private api: ApiRequestService;

    constructor(
        private alert: AlertService,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) { }

    async deleteImg(id: number, type: number) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/util/delete-image/");

        return new Promise((resolve) => {
            this.api.post<any>({id, type}).subscribe({
                next: (result: any) => {    
                    if (result) {
                        this.alert.presentAlert("success", "Imagem excluida", "Sua imagem foi excluida com sucesso", "Fechar");
                        resolve(true);
                    }
                    else {
                        this.alert.presentAlert("error", "Ops!", "Erro ao excluir imagem, tente novamente mais tarde", "Fechar");
                        resolve(false);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    this.requestError.handleError(err, 'delete');
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}