<app-auth-header page="importar"></app-auth-header>

<div class="content">
    <div >
        <section >
            <div class="section-header">
                <div class="title">Importar operações - BlackArrow</div>
                <button class="button-secondary" *ngIf="false">Tutorial Black Arrow</button>
            </div>
            <div class="over-text">Exporte o arquivo direto da plataforma, e garanta que ele não tenha nenhuma alteração para manter a integridade das informações.</div>
            <div class="color-over">Clique no campo abaixo, ou arraste o seu arquivo sobre ela para realizar a importação.</div>

            <div class="import">
                <div id="file-drop-zone" class="file-drop-zone animate__animated animate__fadeIn"
                    (dragover)="onDragOver($event)" (drop)="onDrop($event)" (dragleave)="onDragLeave($event)">
                    <input id="fileDropRef" type="file" #fileDropRef (change)="handleUpload($event)" [accept]="accept">
                    <img src="assets/icons/file-excel.svg"/>
                    <span class="text-drag">Solte o arquivo aqui</span>
                    <span class="text-click">ou clique no ícone acima</span>
                </div>
                <button class="button-outline" (click)="goBack()">Cancelar</button>
            </div>
        </section>
    </div>
</div>