import { Component, Inject, OnInit } from '@angular/core';
import { UserCommand } from 'src/app/commands/user.command';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from 'src/app/components/alert/alert.service';
import { PlanModel } from 'src/app/models/plan.model';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AccountInfoComponent } from 'src/app/components/account-info/account-info.component';
import { WarningActiveComponent } from 'src/app/components/warning-active/warning-active.component';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { ModalVideoComponent } from 'src/app/components/modal-video/modal-video.component';
import { BlackArrowReleaseModel } from 'src/app/models/black-arrow-release.model';
import { UserModel } from 'src/app/models/user.model';
import { EventsService } from 'src/app/services/utils/events.service';
import { DateFormatService } from 'src/app/services/utils/date-format.service';
import { BannerReadCommand } from 'src/app/commands/banner-read.command';
import { BannerReadModel } from 'src/app/models/banner-read.model';
import { ModalBannerDashboardComponent } from 'src/app/components/modal-banner-dashboard/modal-banner-dashboard.component';
import { AccountScheduleModel } from 'src/app/models/account-schedule';
import * as moment from 'moment';
import { ConfirmScheduleComponent } from './confirm-schedule/confirm-schedule.component';
import { RequestScheduleComponent } from './request-schedule/request-schedule.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: UserModel;
  username: string = '';
  atual_plan: PlanModel = null;
  list_account_operation: AccountOperationModel[] = [];
  filter_accounts: AccountOperationModel[] = [];
  filter_schedule: AccountScheduleModel[] = [];
  filter_blackarrow: BlackArrowReleaseModel[] = [];
  show_see_less: boolean = false;
  configuration: ConfigurationModel;
  typePlan: string = 'asset';
  welcome_back: boolean = false;
  show_account_type: string = 'enabled';
  banners: BannerReadModel[];
  minDate: Date;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private firebaseService: FirebaseService,
    private modalService: ModalService,
    private userCommand: UserCommand,
    public pagesContentService: PagesContentService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private configurationCommand: ConfigurationCommand,
    private eventsService: EventsService,
    public dateFormatService: DateFormatService,
    private bannerReadCommand: BannerReadCommand,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('home');

    this.getData();
    this.getConfiguration();

    this.eventsService.get_event().subscribe((event: string) => {
      if (event == 'show_button_credentials') 
        this.getData();
    })
  }

  getData() {
    if (localStorage.getItem('show_account_type')) {
      this.show_account_type = localStorage.getItem('show_account_type');
    }
    if (localStorage.getItem('atual_plan')) {
      this.atual_plan = JSON.parse(localStorage.getItem('atual_plan')) as PlanModel;
    }

    Promise.all([
      this.accountOperationCommand.get(),
      this.accountOperationCommand.blackArrowRelease(),
      this.accountOperationCommand.getSchedule(),
      this.userCommand.get(),
      this.bannerReadCommand.get()
    ]).then(([accounts, releases, schedule, user, banner]: [AccountOperationModel[], any, AccountScheduleModel[], UserModel, any]) => {
      if(releases && releases.results) {
        this.filter_blackarrow = releases.results as BlackArrowReleaseModel[];
      }
      if (schedule) {
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate()+1);
        this.filter_schedule = schedule;
        this.filterAccounts(this.show_account_type);
      }else if(this.show_account_type == 'schedule') {
        this.show_account_type = 'enabled'
      }
      if (accounts) {
        this.list_account_operation = accounts;
        this.filterAccounts(this.show_account_type);
      }
      if(user){
        this.user = user;
        this.username = this.user.name.split(' ')[0];
        let date = new Date();
        date.setDate(date.getDate() -7 );
        this.welcome_back = this.user.last_login != null && (new Date(this.user.last_login) <= date);
      }

      if (banner && banner.results) {
        this.banners = banner.results;
        
        let tempBanners;

        tempBanners = this.banners.filter((bn) => {
          if (bn.type == 2) return bn;
        });

        if (tempBanners.length) {

          let dateLastViewTip: Date = new Date(JSON.parse(localStorage.getItem("dateLastViewTip")));

          if (dateLastViewTip != null) {
            dateLastViewTip.setHours(dateLastViewTip.getHours() + 4);

            if (new Date() < dateLastViewTip) {
              return;
            }
          }

          localStorage.setItem("dateLastViewTip", JSON.stringify(new Date()));

          this.modalService.setContent(tempBanners[0]);
          this.modalService.openModal(ModalBannerDashboardComponent, "600px");
        }
      }
    });
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });
  }

  filterAccounts(filter: string = 'enabled') {
    this.show_account_type = filter;
    localStorage.setItem('show_account_type', filter);
    
    if(!this.list_account_operation)
      return;

    if(filter == 'enabled')
      this.filter_accounts = this.list_account_operation.filter(x => x.status == 2 || x.status == 8);
    if(filter == 'disabled')
      this.filter_accounts = this.list_account_operation.filter(x => x.status != 2 && x.status != 8);
    if(filter == 'all')
      this.filter_accounts = this.list_account_operation;
  }

  openInNewTab(route: string, account: AccountOperationModel) {
    const url = this.router.serializeUrl(this.router.createUrlTree([route]));
    if(account.accept_value_retention || account.coupon_id)
      window.open(url, '_blank');
    else{
      this.modalService.setContent({url, account});
      this.modalService.openModal(WarningActiveComponent, '800px', '400px');
    }
  }

  openTutorial() {
    this.modalService.setContent({title: 'Bem vindo(a) ao Insider Invest', message: '', url: this.configuration.url_tutorial_platform});
    this.modalService.openModal(ModalVideoComponent, '700px');
  }

  viewCredentials(account: AccountOperationModel) {
    this.modalService.setContent(account);
    this.modalService.openModal(AccountInfoComponent, '800px', '400px');
  }

  schedule(value: any = null, id: number, type: number) {
    if(!value)
      value = new Date();
    value = moment(value).format('YYYY-MM-DD');

    this.modalService.setContent({type, schedule: value}); //type: 1 - agendado, 2 - inicio imediato
    this.modalService.openModal(RequestScheduleComponent, '400px', null, null, (data: {accept: boolean}) => {
      if(data && data.accept) {
        this.confirmSchedule(value, id, type);
      }
    });
  }

  confirmSchedule(value: any, id: number, type: number) {
    this.accountOperationCommand.setSchedule(id, value).then(result => {
      if(result){
        this.modalService.setContent({type, schedule: value}); //type: 1 - agendado, 2 - inicio imediato
        this.modalService.openModal(ConfirmScheduleComponent, '400px', null, null);
        this.accountOperationCommand.getSchedule().then((schedule: AccountScheduleModel[]) => {
          if(schedule)
            this.filter_schedule = schedule;
        });
      }
    });

  }

  setDateSchedule(value: any){
    if(!value)
      return this.minDate;
    return new Date(`${value}T00:00`);
  }
}
