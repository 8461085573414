import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ModalService {
  private contentModal: any;
  private dataClose: any = null;

  constructor(private dialog: MatDialog) { }

  openModal(component: any, size: string = '800px', height: string = null, data: any = null, callback = (data?: any) => {}): void {

    const dialogRef = this.dialog.open(component, {
      data : data,
      width: size,
      height: height,
      panelClass: 'bg-color'
    });
    this.dataClose = null;
    dialogRef.afterClosed().subscribe(result => {
      callback(this.dataClose);// lógica a ser executada após a modal ser fechada
    });
  }

  closeModal(data: any = null) {
    this.dataClose = data;
    this.dialog.closeAll();
  }

  setContent(data: any) {
    this.contentModal = data;
  }

  getContent() {
    return this.contentModal;
  }
}