const firefox = [
    {title: "Passo 1", description: "No seu navegador, clique no ícone de Menu e, em seguida, clique em Opções ou Preferências."},
    {title: "Passo 2", description: "Clique em Privacidade e Segurança."},
    {title: "Passo 3", description: "Em Permissões, clique em Configurações… ao lado de Notificações."},
    {title: "Passo 4", description: "Se o endereço https://app.insiderinveste.com.br/ estiver configurado como Bloquear, mude para Permitir."},
    {title: "Passo 5", description: "Clique em Salvar alterações."},
    {title: "Passo rápido", description: "Se preferir, clique no cadeado ao lado de https://app.insiderinveste.com.br/. Se a opção Enviar notificações estiver configurada como Bloqueado, clique no x e atualize a página."},
];

const chrome = [
    {title: "Passo 1", description: "No seu browser, clique em Personalizar e controlar o Google Chrome > Configurações. Ou se preferir, clique em Chrome > Preferências"},
    {title: "Passo 2", description: "Na aba Privacidade e segurança, clique em Configurações do site > Notificações."},
    {title: "Passo 3", description: "Verifique se a opção Perguntar (padrão) está ativada."},
    {title: "Passo 4", description: "Se o endereço https://app.insiderinveste.com.br/ está na lista Bloquear, clique em Mais > Permitir."},
    {title: "Passo rápido", description: "Se preferir, clique no cadeado ao lado de https://app.insiderinveste.com.br/. Se a opção Enviar notificações estiver configurada como Bloqueado, marque como ativo e atualize a página."},
];

const safari = [
    {title: "Passo 1", description: "No seu navegador, clique em Safari > Preferências... > Sites > Notificações."},
    {title: "Passo 2", description: "Se o endereço https://app.insiderinveste.com.br/ estiver configurado como Negar, mude para Permitir."},
];

const default_browser = [
    {title: "Passo 1", description: "No seu navegador, procure a opção de configurações e depois acesse a área de Privacidade e Segurança."},
    {title: "Passo 2", description: "Procure por notificações e acesse as configuraões. Nas configurações marque como permitir que https://app.insiderinveste.com.br/ envie notificações"},
];

export const TutorialNotificacoes = {firefox, chrome, safari, default_browser};