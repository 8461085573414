import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class AccountOperationCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get(account: string = null, showLoading: boolean = true) {
        this.sidenavService.enableProgressBar();

        let filter: string = '';

        if (account) {
            filter = `?account=${account}`;
        }

        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/account/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    acceptActive(account: number) {
        this.api = new ApiRequestService(this.http, "/account-operation/accept-retention/");

        return new Promise(async (resolve) => {

            this.api.post<any>({accept: true, account: account}).subscribe({
                next: async () => {
                    resolve(true);
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                }
            });
        });
    }

    blackArrowRelease() {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            const api = new ApiRequestService(this.http, `/method-payment/black-arrow-release/`);

            api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();

                    resolve(null);
                }
            });
        });
    }

    getSchedule (account: string = null, showLoading: boolean = true) {
        this.sidenavService.enableProgressBar();

        let filter: string = '';

        if (account) {
            filter = `?account=${account}`;
        }

        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/schedule-test/${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    getPublic(email: string = null, plan_id: number = null, platform: number = null) {

        let filter: string = email?`email=${email}&`: '';
        filter = plan_id?`${filter}plan_id=${plan_id}&`: filter;
        filter = platform?`${filter}platform=${platform}&`: filter;

        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account-operation/account/public/?${filter}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                }
            });
        });
    }

    setSchedule(id: number, date: string) {
        this.api = new ApiRequestService(this.http, "/account-operation/schedule-test/create/");

        return new Promise(async (resolve) => {

            this.api.post<any>({id, date}).subscribe({
                next: async () => {
                    resolve(true);
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                }
            });
        });
    }
}