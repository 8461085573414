import { Component } from '@angular/core';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-request-schedule',
  templateUrl: './request-schedule.component.html',
  styleUrls: ['./request-schedule.component.scss']
})
export class RequestScheduleComponent {
  type: number;
  schedule: string;
  constructor(
    private modalService: ModalService,
  ) {
    const {type, schedule}= this.modalService.getContent(); //type: 1 - agendado, 2 - inicio imediato
    this.type = type;
    this.schedule = schedule;
    this.modalService.setContent(null);
  }

  closeModal() {
    this.modalService.closeModal({accept: false});
  }

  confirm() {
    this.modalService.closeModal({accept: true});
  }
}
