<div class="content-body">
    <div class="button-close" (click)="closeModal()"><i class="fa-solid fa-xmark over-text"></i></div>
    <div class="content-body-title">Escolha a opção desejada antes de prosseguir com o pagamento</div>
    <div class="content-body-text over-text">
        <div class="content-body-text-description">
            <span>Identificamos que você possui uma ou mais contas conosco, selecione a opção desejada:</span>
        </div>
        <div class="radio">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="type">
                <mat-radio-button [value]="1" *ngIf="platform == 'MT5'">
                    Contratar nova conta
                    <i class="fa-regular fa-circle-question" matTooltip="Nesta opção você poderá manter mais de um plano ao mesmo tempo."></i>
                </mat-radio-button>
                <mat-radio-button [value]="2">
                    Substituir conta ou resetar conta
                    <i class="fa-regular fa-circle-question" matTooltip="Nessa opção você desativa a sua conta atual ou selecionada. E receberá uma nova conta de negociação."></i>
                </mat-radio-button>
                <mat-radio-button [value]="3">
                    Renovar conta
                    <i class="fa-regular fa-circle-question" *ngIf="platform == 'MT5'" matTooltip="Nessa opção você irá selecionar a conta que deseja renovar por mais 30 dias."></i>
                    <i class="fa-regular fa-circle-question" *ngIf="platform == 'BA'" matTooltip="Nessa opção sua conta será renovada por mais 30 dias."></i>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="desc-input"  *ngIf="type != 1 && accounts.length > 1 && platform == 'MT5'">
            Selecione a conta de negociação 
            <span *ngIf="type == 2">que deseja substituir:</span>
            <span *ngIf="type == 3">que deseja renovar:</span>
        </div>
        <div class="select" *ngIf="type != 1 && accounts.length > 1 && platform == 'MT5'">
            <mat-form-field>
                <mat-select placeholder="Conta de negociação" [(ngModel)]="account">
                    <mat-option *ngFor="let account of accounts" [value]="account.id">
                        <span>{{account.account}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="content-body-footer">
        <button class="button-danger" (click)="closeModal()">Cancelar</button>
        <button class="button-primary" (click)="confirm()">Confirmar</button>
    </div>
</div>