<app-auth-header *ngIf="auth"></app-auth-header>
<app-simple-header *ngIf="!auth"></app-simple-header>

<div class="content" *ngIf="!loading">
    <div class="see-soon" *ngIf="!tournament"><img src="assets/images/em_breve.png"></div>
    <div class="head">
        <div class="info">
            <span class="title">
                {{tournament?.title}}
            </span>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 podium">
            <img *ngIf="tournament?.image" [src]="tournament.image">
            <img *ngIf="!tournament?.image" src="assets/images/trophy.png">
            <span>{{tournament?.subtitle}}</span>
            <button class="button-primary" *ngIf="!tournament.started" [routerLink]="'/torneios/inscricao/'+(tournament.id??1)">QUERO PARTICIPAR</button>
            <button class="button-primary" *ngIf="tournament.started" [routerLink]="'/torneios/ranking/'+(tournament?.id??1)">Ir para o ranking</button>
        </div>
    </div>

    <div class="head">
        <div class="info">
            <span class="title">
                Premiações
            </span>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 gold">
            <img src="assets/images/medal-1.png">
            <span>{{tournament?.first_place}}</span>
        </div>
        <div class="col-12 silver">
            <img src="assets/images/medal-2.png">
            <span>{{tournament?.second_place}}</span>
        </div>
        <div class="col-12 bronze">
            <img src="assets/images/medal-3.png">
            <span>{{tournament?.third_place}}</span>
        </div>
    </div>

    <div class="head">
        <div class="info">
            <span class="title">
                Regulamento do torneio
            </span>
        </div>
    </div>

    <div class="row" *ngIf="tournament">
        <div class="col-12">
            <div [innerHTML]="tournament.regulation"></div>
            <div class="btn-center">
                <button class="button-primary" *ngIf="!tournament.started" [routerLink]="'/torneios/inscricao/'+(tournament.id??1)">QUERO PARTICIPAR</button>
            </div>
        </div>
    </div>
</div>