<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        Limite mínimo da conta (Drawdown)
    </div>
    <div class="over-text steps">
        Não permita que o saldo da sua conta atinja ou exceda esse valor. O Trailing Drawdown aumentará a uma distância definida à medida que o saldo da sua conta aumentar.
    </div>
    <br><br>
    <div class="title">
        Explicação
    </div>

    <div class="over-text steps">
        Suponhamos que você tenha contratado o plano Start Trader.
        <br><br>
        O saldo inicial da sua conta é de $25.000,00 e a perda máxima permitida para ela é de $ 2.000,00, sempre que você alcançar valores mais altos no saldo da sua conta, o limite mínimo da conta será atualizado.
    </div>

    <br><br>
    <div class="title">
        Exemplo 1
    </div>
    <div class="over-text steps">
        A sua conta atual iniciou com o saldo de $ 25.000,00 e a perda máxima é de 2.000,00, ou seja, o limite mínimo da sua conta é $ 23.000,00. Se no seu primeiro trade você obter um resultado positivo de $500,00 o saldo da sua conta agora é $25.500,00, portanto, automaticamente o limite mínimo permitido para a conta é de $23.500,00
    </div>

    <br><br>
    <div class="title">
        Exemplo 2
    </div>
    <div class="over-text steps">
        Agora o saldo da sua conta está em $25.500,00 e a sua perda máxima em R$ 23.500,00. Se neste momento você realizar uma operação de -$300,00 o saldo da sua conta volta para $25.200,00 e o limite mínimo da sua conta permanece em $23.500,00. Observe que quando há perdas, o limite da conta não é atualizado.
    </div>

    <br><br>
    <div class="title">
        Exemplo 3
    </div>
    <div class="over-text steps">
        Para finalizar, agora que o seu saldo está em $25.200,00, e o limite mínimo da sua conta é de $23.500,00. Suponhamos que você realizou um trade de $1.000,00. Agora o saldo da sua conta é $26.200,00 e ele ultrapassou o máximo que ele já havia alcançado anteriormente que era $25.500,00. Então neste momento o saldo da conta é atualizado novamente e dessa vez ele é atualizado para $24.200,00
    </div>

    <br><br>
    <div class="title">
        Base de cálculo
    </div>
    <div class="over-text steps">
        Sempre que a conta atinge valores mais altos que ainda não havia atingido antes, o limite mínimo da conta é atualizado com base no cálculo a seguir:
        <br><br>
        Saldo atual da conta - Perda máxima permitida no plano = Limite mínimo permitido para a conta.

        <br><br>
        Levando em consideração que em nossos exemplos utilizamos o plano Start Trader que permite uma perda máxima de $2.000,00. O cálculo para o nosso exemplo 3 seria: <br>
        $26.200,00 - $2.000,00 = $24.200,00
    </div>

    <br><br>
    <div class="title">
        Últimas observações
    </div>
    <div class="over-text steps">
        O limite mínimo da conta leva em consideração a operação encerrada, e não o valor flutuante da operação. 
        <br><br>
        Quando o limite mínimo da conta alcançar ou saldo inicial da conta, ou seja, nos nossos exemplos acima que utilizamos o plano Start Trader, quando o nosso limite mínimo alcançar o saldo de $25.000,00 ele deixa de ser atualizado automaticamente independente do saldo que a conta alcance. Neste caso a conta só será reprovada quando retornar para o saldo inicial dela.
        <br><br>
        O trader não pode deixar que o saldo da conta atinja o limite mínimo em nenhum momento, caso aconteça a conta será reprovada.
        <br><br>
        Essa regra é a mesma para o período de avaliação e após aprovado também.
    </div>
</div>