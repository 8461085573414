import { Component } from '@angular/core';

@Component({
  selector: 'app-meta-vision-travando',
  templateUrl: './meta-vision-travando.component.html',
  styleUrls: ['./meta-vision-travando.component.scss']
})
export class MetaVisionTravandoComponent {

}
