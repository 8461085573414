<app-auth-header></app-auth-header>

<div class="content">
    <div class="head">
        <div class="info">
            <span class="title">
                Próximos torneios
            </span>
            <span class="subtitle">
                Os maiores desafios do mercado estão te esperando. Veja os torneios disponíveis e participe!
            </span>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <div *ngIf="listTournamentActive.length == 0">Nenhum torneio encontrado.</div>
            <div class="card" *ngIf="listTournamentActive.length > 0">
                <table>
                    <thead>
                        <tr>
                            <th>Torneio</th>
                            <th>Data de inicio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listTournamentActive">
                            <td>{{item.title}}</td>
                            <td>{{item.init_date| date: 'dd/MM/yyyy'}}</td>
                            <td class="action">
                                <a [routerLink]="'/torneios/'+(item.started? 'ranking': 'regras')+'/'+(item.id??1)">
                                    Ver detalhes
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="head" *ngIf="listTournamentExpired.length > 0">
        <div class="info">
            <span class="title">
                Histórico de torneios
            </span>
            <span class="subtitle">
                Os torneios que marcaram a Insider Investe! Veja quais competições já passaram por aqui.    
            </span>
        </div>
    </div>

    <div class="row mb-3" *ngIf="listTournamentExpired.length > 0">
        <div class="col-12">
            <div class="card">
                <table>
                    <thead>
                        <tr>
                            <th>Torneio</th>
                            <th>Data de encerramento</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listTournamentExpired">
                            <td>{{item.title}}</td>
                            <td>{{item.end_date| date: 'dd/MM/yyyy'}}</td>
                            <td class="action">
                                <a [routerLink]="'/torneios/regras/'+item.id">
                                    Ver detalhes
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>