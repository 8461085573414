import { Injectable } from '@angular/core';
import { PagesContentModel } from 'src/app/models/pages-content.model';

@Injectable({
    providedIn: 'root'
})
export class PagesContentService {

    private pages_content: PagesContentModel[] = [];

    constructor() { }

    setPagesContent(pages_content: PagesContentModel[]) {
        this.pages_content = pages_content;
    }

    getPagesContent() {
        return this.pages_content;
    }

    isVisible(slug: string) {
        if (this.pages_content) {
            if (this.pages_content.find((item: PagesContentModel) => item.slug == slug && (item.type == 2 || item.type == 3)))
                return false;
        }

        return true;
    }

    getBlock(slug: string) {
        if(this.pages_content)
            return this.pages_content.find((item: PagesContentModel) => item.slug == slug && (item.type == 1 || item.type == 3));

        return null;
    }
}