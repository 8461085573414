<app-auth-header page="history"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="title">
        Solicitações de Saques
    </div>

    <section class="alert">
        <span>
            ATENÇÃO: Observe que abaixo é permitido selecionar apenas contas reais para realizar saque. 
            Para que você possa ter saldo disponível para saque é preciso ter pelo menos uma conta aprovada na mesa Insider Investe, 
            e a conta real precisa estar ativa cumprindo todos os requisitos para a solicitação do saque
        </span>
    </section>
    <div class="subtitle">
        Selecione a conta de negociação
    </div>
    <div class="account-choice">
        <div class="select">
            <mat-form-field class="account">
                <mat-select placeholder="Conta de negociação" #select (selectionChange)="seach(select.value)" [(ngModel)]="account_operation_selected">
                    <mat-option *ngFor="let account of listAccounts" [value]="account">
                        <span>{{account.account}} - </span>
                        <span>{{account.plan.title}}</span>
                        <span *ngIf="!account.plan.is_stock"> (Índices e derivativos)</span>
                        <span *ngIf="account.plan.is_stock"> (Ações Americanas)</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row withdraw-content">
        <div class="col-12 col-lg-6 col-xl-4">
            <div class="card withdraw">
                <div class="balance">
                    <span class="subtitle">Saldo da conta</span>
                    <span class="desc">Saldo no encerramento do ciclo (último dia do mês anterior)</span>
                    <span class="value" *ngIf="!(account_operation && avaliable)">$ {{0| number:'1.2-2'}}</span>
                    <span class="value" *ngIf="account_operation && avaliable">$ {{avaliable.account_balance| number:'1.2-2'}}</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
            <div class="card withdraw">
                <div class="avaliable">
                    <span class="subtitle info">
                        Disponível para saque 
                        <i class="fa-solid fa-info" matTooltip="Saldo disponível para saque referente ao valor disponível no ciclo que encerrou no dia {{interval_date.last_month | date: 'dd/MM/YYYY'}}"></i></span>
                    <span class="desc">Valor que será transferido após a solicitação do saque</span>
                    <span class="value" *ngIf="!(account_operation && avaliable)">$ {{0 | number:'1.2-2'}}</span>
                    <span class="value" *ngIf="account_operation && avaliable">$ {{avaliable.withdraw_available | number:'1.2-2'}}</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4">
            <div class="card withdraw">
                <div class="interval">
                    <span class="subtitle">Próxima janela de saque</span>
                    <span class="desc">Período em que o saque poderá ser solicitado</span>
                    <span class="value" *ngIf="interval_date">{{interval_date.init_date | date: 'dd/MM/yyyy'}} à {{interval_date.end_date | date: 'dd/MM/yyyy'}}</span>
                </div>
            </div>
        </div>
    </div>

    <section class="row-content withdraw-content mt-21">
        <div class="card withdraw request">
            <span class="subtitle">Solicitar saque</span>
            <span class="desc">Se a sua conta estiver elegível para saque, clique no link abaixo. O pagamento é realizado em até 5 dias úteia após sua solicitação.</span>
            <button class="button-primary" (click)="confirmRequest()">Solicitar saque</button>
        </div>
    </section>

    <section class="row-content withdraw-content">
        <div class="history" *ngIf="listWithDraw.length == 0">
            <span>Nenhuma solicitação ainda realizada</span>
        </div>
        <div class="card history" *ngIf="listWithDraw.length != 0">
            <table>
                <thead>
                    <tr>
                        <th>Atendido em</th>
                        <th>Valor solicitado</th>
                        <th>Status</th>
                        <th>Tipo de transferência</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listWithDraw">
                        <td>{{item.completion_date| date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.value | currency: '$'}}</td>
                        <td>
                            <span class="color-warning" *ngIf="item.status == 1">Aguardando aprovação</span>
                            <span class="color-info" *ngIf="item.status == 2">Aprovado</span>
                            <span class="color-danger" *ngIf="item.status == 3">Negado</span>
                            <span class="color-primary-medium" *ngIf="item.status == 4">Finalizado</span>
                        </td>
                        <td>
                            <span  *ngIf="item.type_payment == 1">PIX</span>
                            <span  *ngIf="item.type_payment == 2">TED</span>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>