import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { PlanCommand } from 'src/app/commands/plan.command';
import { AlertService } from 'src/app/components/alert/alert.service';
import { ModalPayErrorComponent } from 'src/app/components/modal-pay-error/modal-pay-error.component';
import { SidenavService } from 'src/app/components/sidenav/sidenav.service';
import { PayModel } from 'src/app/models/pay.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { TermsComponent } from '../terms/terms.component';
import { TermUseCommand } from 'src/app/commands/term-use.command';
import { TermUseModel } from 'src/app/models/term-use.model';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { ModalAccountPaymentComponent } from 'src/app/components/modal-account-payment/modal-account-payment.component';
import { UserModel } from 'src/app/models/user.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CouponModel } from 'src/app/models/coupon.model';

@Component({
  selector: 'app-payment-black-friday',
  templateUrl: './payment-black-friday.component.html',
  styleUrls: ['./payment-black-friday.component.scss']
})
export class PaymentBlackFridayComponent {
  loading: boolean = false;
  user: UserModel;

  pay_method: string = 'CARTAO';
  formAccount: UntypedFormGroup;
  formBuy: UntypedFormGroup;
  submittedAccount: boolean = false;
  submitted: boolean = false;
  disableSubmitButton: boolean = false;

  platform_selected: string = 'MT5';
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;
  installment_count: number = 12;
  discount: number[] = [0.7, 0.7, 0.65, 0.6, 0.55, 0.5];
  
  years: string[] = Array(21).fill(0).map((x,i)=>(new Date().getFullYear()+i).toString());
  plans: PlanModel[] = [];
  plan: PlanModel;
  ordered_plans: {is_stock: boolean, plans: PlanModel[]}[] = [];
  coupon: CouponModel;
  selectedPlans: {id: number}[] = [];
  termUse: TermUseModel;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private alertComponent: AlertService,
    private activatedRoute: ActivatedRoute,
    private contentPageService: PagesContentService,
    private firebaseService: FirebaseService,
    private inputFormService: InputFormsService,
    private modalService: ModalService,
    private sidenavService: SidenavService,
    private router: Router,
    private accountOperationCommand: AccountOperationCommand,
    private planCommand: PlanCommand,
    private paymentCommand: PaymentCommand,
    private termUseCommand: TermUseCommand,
  ) {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    if(queryParams.platform)
      this.changePlatform(queryParams.platform);
  }

  ngOnInit(): void {
    this.firebaseService.viewScreen('black-friday');
    this.sidenavService.enableProgressBar();
    this.sidenavService.getProgressBar().subscribe(
      value => {
        this.loading = value;
      }
    );
    this.user = JSON.parse(localStorage.getItem('user')) as UserModel;
    this.formAccount = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      email_confirm: ['', [Validators.required]],
      document: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });
    this.formBuy = this.formBuilder.group({
      payment_type: ['CARTAO', [Validators.required]],
      card_number: [null, [Validators.required]],
      card_name: [null, [Validators.required]],
      card_month: [null, [Validators.required]],
      card_year: [null, [Validators.required]],
      card_cvv: [null, [Validators.required]],
      plan_id: [0, [Validators.required]],
      accept_term: [false, [Validators.required, Validators.requiredTrue]],
      save_card: [false, []],
      installment_count: [this.installment_count, []],
      ip: ['172.0.0.1', [Validators.required]],
    });

    Promise.all([
      this.planCommand.getPublic(),
      this.termUseCommand.get(),
    ]).then(([result, term]: [any, TermUseModel]) => {
      if (result && result.results) {
        this.plans = result.results;
        this.blackArrowValue = this.plans.find(item => item.value_black_arrow != null).value_black_arrow;
        this.installment_count = this.plans.find(item => item.installment_count != null)?.installment_count;
        this.ordered_plans = [
          {is_stock: false, plans: this.plans.filter(item => item.is_stock == false)},
          {is_stock: true, plans: this.plans.filter(item => item.is_stock == true)},
        ];
        if(this.user)
          this.formAccount.patchValue({
            name: this.user.name,
            email: this.user.email,
            email_confirm: this.user.email,
            document: this.user.document_number,
            phone: this.user.phone_number,
          });
      } else {
        this.router.navigate(['/erro-pagamento'], {state: {plan: null, type: 'plan-not-found', payment: null}});
      }
      if(term)
        this.termUse = term;
    });

    // setTimeout(() => {
    //   let page_block = this.contentPageService.getBlock('black-friday');
    
    //   if(page_block){
    //     this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
    //     this.router.navigate(['home']);
    //   }
    // },1000);
  }

  get errorAccountCtr() {
    return this.formAccount.controls;
  }

  get errorBuyCtr() {
    return this.formBuy.controls;
  }

  focus(type: string, element: string) {
  }

  updateMask(event, field: string) {
  }

  submit() {
    this.firebaseService.eventClick('buy-plan', 'pagamento');
    this.submitted = true;
    this.disableSubmitButton = true;

    if (this.formAccount.valid && this.formBuy.valid) {
      let payment: PaymentModel = {
        name: this.formAccount.value.name,
        email: this.formAccount.value.email,
        email_confirm: this.formAccount.value.email_confirm,
        document: this.formAccount.value.document,
        phone: this.formAccount.value.phone,
        payment_type: this.formBuy.value.payment_type,
        card_number: this.formBuy.value.card_number,
        card_name: this.formBuy.value.card_name,
        card_month: this.formBuy.value.card_month,
        card_year: this.formBuy.value.card_year,
        card_cvv: this.formBuy.value.card_cvv,
        ip: this.formBuy.value.ip,
        platform: this.platform_selected,
        accept_term: this.formBuy.value.accept_term,
        save_card: this.formBuy.value.save_card,
        coupon: this.platform_selected == 'BA'? 'INSIDER30BLACKFRIDAY': null,
        term_id: this.termUse? this.termUse.id: null,
        installment_count: this.formBuy.value.installment_count,
        type: 1,
      } as PaymentModel;

      if(payment.platform == 'MT5')
        payment.plans = this.selectedPlans;
      else
        payment.plan_id = this.selectedPlans[0].id;

      this.plan = this.createCustomPlan();
      this.coupon = {name: 'Black Friday Insider', type_discount: 1, value_discount: (1 - this.discount[this.selectedPlans.length])*100} as CouponModel;

      if(payment.platform == 'BA'){
        payment.type = 2;
        this.accountOperationCommand.getPublic(payment.email, null, 2).then((result: any) => {
          if(result) {
            this.modalService.setContent({accounts: result, type: payment.type, platform: payment.platform});
            this.modalService.openModal(ModalAccountPaymentComponent, "600px", null, null, (data) => {
              if(data && data.accept){
                payment.type = data.type;
                this.confirmPayment(payment);
              }
            });
          } else {
            this.confirmPayment(payment);
          }
        });
      } else 
        this.confirmPayment(payment);
    }else{
      this.disableSubmitButton = false;
    }
  }

  confirmPayment(payment: PaymentModel) {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    if(payment.platform == 'BA')
      this.paymentCommand.pay(payment, JSON.stringify(queryParams)).then((result: PayModel) => {
        this.computePayment(payment, queryParams, result);
      });
    else
      this.paymentCommand.payBlackFriday(payment, JSON.stringify(queryParams)).then((result: PayModel) => {
        this.computePayment(payment, queryParams, result);
      });
    this.disableSubmitButton = false;
  }

  computePayment(payment:PaymentModel, queryParams: Params, result: PayModel) {
    if(!result.valid){
      this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
      return;
    }
    
    if(this.pay_method == 'CARTAO'){
      if(result.credit_card_status == 'CONFIRMED'){
        window.open('https://insiderinveste.com/index.php/obrigado-black-friday/', '_blank')
        this.router.navigate(['/pagamento-confirmado'], {state: {plan: this.plan, type: 'success', payment: payment, coupon: this.coupon, charge: null}, queryParams: queryParams});
        return;
      }
      if(result.credit_card_status == null){
        setTimeout(() => {
          this.modalService.setContent(result.credit_card_error);
          this.modalService.openModal(ModalPayErrorComponent, "600px");
        }, 750);
        return;
      }
      this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
      return;
    }
    
    if(this.pay_method == 'PIX'){
      if(!result.pix)
        this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
      else
        this.router.navigate([`/cobranca/pix`], {state: {plan: this.plan, pay_pix: result.pix, payment: payment, coupon: this.coupon, charge: null}, queryParams: queryParams});
      return;
    }
    if(this.pay_method == 'BOLETO'){
      if(!result.ticket)
        this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
      else
        this.router.navigate([`/cobranca/boleto`], {state: {plan: this.plan, boleto: result.ticket, payment: payment, coupon: this.coupon, charge: null}, queryParams: queryParams});
      return;
    }
  }

  changePayment(type: string){
    this.pay_method = type;
    this.formBuy.patchValue({payment_type: this.pay_method});
    const keys = ['card_number', 'card_name', 'card_month', 'card_year', 'card_cvv'];
    if(type == 'CARTAO'){
      keys.forEach(key => {
        this.formBuy.controls[key].setValidators([Validators.required]);
        this.formBuy.controls[key].updateValueAndValidity();
      });
    }else{
      keys.forEach(key => {
        this.formBuy.controls[key].setValidators([]);
        this.formBuy.controls[key].updateValueAndValidity();
      });
    }
  }

  changePlatform(type: string) {
    this.platform_selected = type;
  }

  getFinalValue() {
    let value: number = 0;
    this.selectedPlans.forEach(item => {
      value += Number(this.plans.find(plan => plan.id == item.id).value_plan);
    });

    value *= this.discount[this.selectedPlans.length];
    
    if(this.platform_selected == 'BA')
      return value+Number(this.blackArrowValue);
    return value+Number(this.metaTraderValue);
  }

  openModal() {
    this.modalService.setContent(this.termUse);
    this.modalService.openModal(TermsComponent, '800px', '90vh');
  }

  rangeList(value: number = 1): Array<number> {
    if(this.installment_count)  value = this.installment_count;
    return Array(value).fill(0).map((x, i) => i+1);
  }

  back(evt: MatExpansionPanel = null) {
    evt.open();
  }

  next(evt: MatExpansionPanel = null, form: UntypedFormGroup = null) {
    if(form && !form.valid)
      return this.submittedAccount = true;
    evt.open();
    this.submittedAccount = false
    this.submitted = false
  }

  changeQTD(evt: HTMLInputElement, change: number, plan_id: number) {
    if(Number(evt.value) == 0 && change < 0)
      return;

    let qtdPlans = this.selectedPlans.length;

    if (qtdPlans >= 5 && change > 0)
      return;

    if(change == 1)
      this.selectedPlans.push({id: plan_id});
    if(change == -1){
      let index = this.selectedPlans.findIndex(item => item.id == plan_id);
      if (index != -1)
        this.selectedPlans.splice(index, 1);
    }

    evt.value = (Number(evt.value) + change).toString();
  }

  countPlanSelected(plan_id: number) {
    return this.selectedPlans.filter(item => item.id == plan_id).length;
  }

  selectPlanBA(evt: MatExpansionPanel, plan: PlanModel) {
    this.next(evt);
    this.selectedPlans = [{id: plan.id}];
  }

  createCustomPlan() {
    return {
      title: 'Black Friday - Insider Invest',
      value_plan: this.getFinalValue(),
      value_black_arrow: this.blackArrowValue,
    } as PlanModel;
  }
  valueAccount(value: any) {
    return `${Number(value)/1000}K`;
  }
}
