import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
  ) {

  }

  ngOnInit(): void {
    this.firebaseService.viewScreen('not-found');
  }

  goHome(){
    this.router.navigateByUrl('home')
  }
}
