import { Component } from '@angular/core';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-confirm-delete-card',
  templateUrl: './confirm-delete-card.component.html',
  styleUrls: ['./confirm-delete-card.component.scss']
})
export class ConfirmDeleteCardComponent {

  constructor(
    private modalService: ModalService,
    private paymentCommand: PaymentCommand,
  ) {}

  cancel() {
    this.modalService.closeModal();
  }
  
  delete() {
    const id = this.modalService.getContent() as number;
    this.modalService.setContent(null);
    this.paymentCommand.deleteCreditCard(id);
    this.modalService.closeModal();
  }
}
