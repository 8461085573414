<app-auth-header page="trading-account"></app-auth-header>

<div class="content">
    <div class="title">Habilite o Algotrading do Insider Investe</div>

    <div class="steps">
        <div class="step-title margin-top">Passo 1</div>
        <div class="over-text">Abra o Meta Trader 5, e siga os seguintes passos.</div> <br>
        <div class="over-text"><strong>a. </strong>No canto superior do Meta Trader 5, identifique o botão chamado Algotrading, clique sobre ele para que o ícone dele fique verde. Isso significa que ele estará habilitado.</div>
        <div class="over-text"><strong>b. </strong>Acesse a janela do <strong>Navegador</strong> do Meta Trader,
            identifique o item <strong>Consultor expert</strong> e clique no ícone '+' para expandir mais opções.</div>
        <div class="over-text"><strong>c. </strong>Após expandir as opções do Consultor expert, identifique o item
            <strong>Insider Investe Real Time</strong>, de um clique sobre ele e arraste para cima de um gráfico
        </div>

        <div class="color-warning-medium"><strong>ATENÇÃO 1:</strong> Recomendamos que você arraste o Insider Investe Real Time para o gráfico de Usatec (com vencimento) porque ele estará sempre aberto e em andamento. </div>
            <div class="over-text"><strong>d) </strong>Após arrastar o Insider Investe Real Time para o gráfico, irá aparecer a
                janela de confirmação, marque a opção <strong>Permitir DLL externo</strong> e clique em <strong>OK</strong>.</div>
            <div class="color-warning-medium"><strong>ATENÇÃO 2:</strong> NÃO UTILIZE ESTE GRÁFICO PARA OPERAR. Após habilitar o Insider Investe Real Time no gráfico, <strong>deixe o gráfico minimizado ou em segundo plan</strong>o. O importante é deixar ele habilitado e operar em outros gráficos. </div>
            
        <div class="inline">
            <a href="assets/images/tutorial_install_metavision/step4.PNG" target="_blank">
                <img class="step4" src="assets/images/tutorial_install_metavision/step4.PNG" alt="step 4">
            </a>
        </div>

        <div class="step-title margin-top">Passo 2</div>
        <div class="over-text">Revise os seguintes passos:</div> <br>
        <div class="over-text"><strong>a. </strong>O gráfico que você arrastou o Insider Investe Real Time, irá receber o ícone
            exibido na imagem abaixo. Se o ícone estiver azul, significa que a instalação foi realizada com sucesso.
        </div>
        <div><strong>ATENÇÃO: NÃO UTILIZE ESTE GRÁFICO PARA OPERAR. Após habilitar o Insider Investe Real Time no gráfico, <strong>deixe o gráfico minimizado ou em segundo plan</strong>o. O importante é deixar ele habilitado e operar em outros gráficos.</strong></div>
        <div class="over-text"><strong>b. </strong>O gráfico que você escolheu para habilitar o Insider Investe Real Time, precisa
            estar <strong>aberto</strong> ou em <strong>segundo plano</strong>, ou seja, ele não precisa estar visível
            na tela, desde que ele esteja em segundo plano e aparecendo conforme mostra a seta <strong>B</strong> da
            imagem a seguir</div>
        <div class="over-text"><strong>c. </strong>Conforme a imagem a seguir, verifique se o Algotrading está com o
            ícone de "play", caso não esteja, clique sobre ele para habilitá-lo</div>

        <div class="inline">
            <a href="assets/images/tutorial_install_metavision/step6.PNG" target="_blank">
                <img class="step6" src="assets/images/tutorial_install_metavision/step6.PNG" alt="step 6">
            </a>
        </div>

        <div class="over-text margin-top">Tudo pronto! Você habilitou o Algotrading na sua plataforma, qualquer dúvida, não
            deixe de contatar o nosso suporte através do e-mail <span
                class="color-highlight">suporte@insiderinveste.com.br</span> </div> <br>

    </div>
</div>