<app-auth-header page="planos"></app-auth-header>

<div class="content">
    <div class="content-plan">
        <div class="title">Planos</div>

        <div class="description-plan">
            Ao realizar a solicitação de uma nova conta do <strong>mesmo plano</strong> que você já possui sua conta atual será desativada e uma conta nova será disponibilizada pra você.
            <br>
            <br>
            Ao realizar a solicitação de um <strong>novo plano</strong> você poderá manter o teste nas duas contas ao mesmo tempo.
        </div>

        <section class="section-plans">
            <app-card-plan></app-card-plan>
        </section>
    </div>
</div>