import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetPlanModel } from 'src/app/models/asset-plan.model';

@Component({
  selector: 'app-table-assets',
  templateUrl: './table-assets.component.html',
  styleUrls: ['./table-assets.component.scss']
})
export class TableAssetsComponent {
  @Input() assets: Array<AssetPlanModel[]> = [];
  @Input() showContract: boolean = true;
  @Input() showPaginator: boolean = true;
  @Input() page: number = 0;
  @Output() pageChange = new EventEmitter<number>();

  listPages: number[] = [];
  listCompletePages: number[] = [];

  ngOnInit() {
    for(let i = 1; i<=this.assets.length; i++)
      this.listCompletePages.push(i);
    this.setListPages();
  }

  timeFormat(value:string) {
    if(value)
      value = value.substring(0, 5);
    return value;
  }

  alterPage(type: number) {
    this.page += type;
    this.setListPages();
    this.pageChange.emit(this.page);
  }

  setPage(page: number) {
    this.page = page;
    this.setListPages();
    this.pageChange.emit(this.page);
  }

  setListPages() {
    if(this.page == 0)
      this.listPages =  this.listCompletePages.slice(0, 4);

      console.log(this.page, this.page+3, this.assets.length, this.assets.length - 4);
      
    if(this.page > 0 && this.page < this.assets.length-3){
      this.listPages =  this.listCompletePages.slice(this.page, this.page+3);
      this.listPages.unshift(1);
    }
    if(this.page < this.assets.length - 3)
      this.listPages.push(this.assets.length);
  }
}
