import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss']
})
export class ModalVideoComponent {
  title: string;
  message: string;
  url: SafeResourceUrl;

  constructor(
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    const {title, message, url} = this.modalService.getContent();
    this.modalService.setContent(null);
    this.title = title;
    this.message = message;
    this.url = this.getSafeUrlYoutube(url);
  }

  getSafeUrlYoutube(url: any) {
    if (!url) return;
    if (typeof url === "object") return url; //Url has already undergone treatment

    if (url.includes('watch?v=')) url = url.replace('watch?v=', 'embed/');
    if (url.includes('youtu.be')) url = url.replace('youtu.be', 'youtube.com/embed');
    if (url.includes('&')) url = url.substring(0, url.indexOf('&'));
    if (url.includes('vimeo.com') && !url.includes('player.vimeo.com')) url = url.replace('vimeo.com', 'player.vimeo.com/video');

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
