import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class BannerReadCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get() {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account/banners-read/");

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    if(result && result.results)
                        resolve(result);
                    else
                        resolve(null);

                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    setRead(id: number){
        this.api = new ApiRequestService(this.http, "/account/banners-read/");

        return new Promise(async (resolve) => {

            this.api.post<any>({banner: id}).subscribe({
                next: async (result: any) => {
                    if (result) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                }
            });
        });
    }
}