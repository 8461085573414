<app-auth-header></app-auth-header>

<div class="content">
    <div class="head">
        <div class="info">
            <span class="title">
                Certificados
            </span>
            <span class="subtitle">
                Nessa seção você pode baixar seus certificados. A cada aprovação um novo certificado é liberado a você.
            </span>
        </div>
        <div class="filter">
            <div class="select">
                <mat-form-field class="account">
                    <mat-select placeholder="Escolha um tipo de certificado" #cert_select (selectionChange)="search(1, cert_select.value)" [value]="-1">
                        <mat-option [value]="-1">Todos os certificados</mat-option>
                        <mat-option [value]="true">Certificados liberados</mat-option>
                        <mat-option [value]="false">Certificados bloqueados</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 col-md-6 col-xl-4" *ngFor="let certificate of listCertificate">
            <div class="card">
                <div class="logo">
                    <img [src]="certificate.file_certificate">
                    <img *ngIf="!certificate.activated" class="block" src="assets/icons/lock.svg" alt="Bloqueado">
                    <i class="fa-solid fa-circle-info" [class.lock]="!certificate.activated" matTooltip="{{certificate.certificate.description}}"></i>
                </div>
                <a [href]="certificate.file_certificate" target="_blank" download #link></a>
                <button class="button-primary" (click)="link.click()" [disabled]="!certificate.activated">
                    <img src="assets/icons/sidenav-download.svg" alt="Download">
                    <span>Baixar certificado</span>
                </button>
            </div>
        </div>
        <div class="col-12" *ngIf="listCertificate.length == 0"><span>Nenhum certificado encontrado.</span></div>
    </div>

    <div class="head" *ngIf="false">
        <div class="info">
            <span class="title">
                Conquistas
            </span>
            <span class="subtitle">
                Nessa seção você encontra suas conquistas. Atinja novos objetivos para liberar os marcadores de conquistas.
            </span>
        </div>
        <div class="filter">
            <div class="select">
                <mat-form-field class="account">
                    <mat-select placeholder="Escolha um tipo de conquista" #arch_select (selectionChange)="search(2, arch_select.value)" [value]="-1">
                        <mat-option [value]="-1">Todas as conquistas</mat-option>
                        <mat-option [value]="true">Conquistas liberadas</mat-option>
                        <mat-option [value]="false">Conquistas bloqueadas</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="false">
        <div class="col-12 col-md-6 col-xl-4" *ngFor="let achievement of listAchievement">
            <div class="card">
                <div class="logo">
                    <img [src]="achievement.file_certificate">
                    <img *ngIf="!achievement.activated" class="block" src="assets/icons/lock.svg" alt="Bloqueado">
                    <i class="fa-solid fa-circle-info" [class.lock]="!achievement.activated" matTooltip="{{achievement.certificate.description}}"></i>
                </div>
                <a [href]="achievement.file_certificate" download #link></a>
                <button class="button-primary" (click)="link.click()" [disabled]="!achievement.activated">
                    <img src="assets/icons/sidenav-download.svg" alt="Download">
                    <span>Baixar certificado</span>
                </button>
            </div>
        </div>
        <div class="col-12" *ngIf="listAchievement.length == 0"><span>Nenhuma conquista encontrada.</span></div>
    </div>
</div>