import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    private run = new Subject<string>();

    call(event_name: string) {
        this.run.next(event_name);
    }

    get_event(): Subject<string> {
        return this.run;
    }
}