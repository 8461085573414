import { Component } from '@angular/core';
import { PlanCommand } from 'src/app/commands/plan.command';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-consistency',
  templateUrl: './consistency.component.html',
  styleUrls: ['./consistency.component.scss']
})
export class ConsistencyComponent {
  listPlans: PlanModel[] = [];
  plan_id: number;
  plan: PlanModel;
  lastFilter: string = '';
  page: number = 0;

  constructor(
    private planCommand: PlanCommand,
  ){
  }

  ngOnInit() {
    this.planCommand.getPublic().then((result: any) => {
      if(result && result.results)
        this.listPlans = result.results;
    });
  }

  seach() {
    this.plan = null;
    setTimeout(() => {
      this.plan = this.listPlans.find(item => item.id == this.plan_id);
      this.page = 0;
    }, 100);
  }
}
