import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-icon-label',
  templateUrl: './button-icon-label.component.html',
  styleUrls: ['./button-icon-label.component.scss']
})
export class ButtonIconLabelComponent {
  @Input() icon: string;
  @Input() description: string;
}
