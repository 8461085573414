<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        Consistência Aprovado
    </div>
    <div class="over-text steps">
        Após aprovado o trader deve ser capaz de demonstrar que continua operando com consistência. Caso seja aprovado antes do limite mínimo de dias para aprovação, os próximos Trades devem ser feitos com pelo menos 60% dos lotes da quantidade de lotes que você usou no seu melhor trade no ativo em que operar, e a duração das operações deve ser de pelo menos 50% da duração do seu melhor trade realizado naquele ativo em que operar.
        <br><br>
        Em outras palavras, quando você alcança a meta <strong>antes dos 20 dias operados</strong>. É necessário <strong>completar os 20 dias operando com consistência</strong>. Para evitar que trades sejam abertos e encerrados apenas para contabilizar o dia como operado. No ativo que você está operando é preciso obter o melhor trade que você realizou nele. E os trades que você for realizar a partir deste momento devem ter pelo menos 60% de contratos e pelo menos 50% da duração deste melhor trade. 
        <br><br>
        O objetivo dessa regra é apenas levar o trader a continuar operando com a consistência que ele demonstrou durante o período em que atingiu o valor da meta

    </div>
    <br><br>
    <div class="title">
        Exemplo
    </div>

    <div class="over-text steps">
        Suponhamos que o seu plano é o Pro Trader, e no seu melhor trade, ou seja, aquele trade que você obteve o resultado mais positivo você operou com 1.0 contrato de UsaTec, e que a operação durou 20 minutos.
        <br>
        A partir desse momento que você atingiu a meta e ainda precisa operar para completar os dias operados, os seus próximos trades deverão ter <strong>pelo menos</strong> 0.6 de contratos e pelo menos 10 minutos de duração.
        <br> <br>

        Se no mesmo teste você operou, por exemplo, Bra50 e no seu melhor resultado nesse ativo a operação foi realizada com 30 contratos e o trade durou 30 minutos.
        <br>
        A partir desse momento que você atingiu a meta e ainda precisa operar para completar os dias operados, os seus próximos trades deverão ter <strong>pelo menos</strong> 18 de contratos e pelo menos 15 minutos de duração.
       
    </div>
</div>