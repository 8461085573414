import { Component } from '@angular/core';
import { CertificateCommand } from 'src/app/commands/certificate.command';
import { CertificateUserModel } from 'src/app/models/certificate.model';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrl: './certificate.component.scss'
})
export class CertificateComponent {
  certificate_type: boolean = null;
  achievement_type: boolean = null;

  allCertificate: CertificateUserModel[] = [];
  listCertificate: CertificateUserModel[] = [];
  listAchievement: CertificateUserModel[] = [];

  constructor(
    private certificateCommand: CertificateCommand,
  ){ }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.certificateCommand.getCertificate().then((result: CertificateUserModel[]) => {
      if(result) {
        this.allCertificate = result;
        this.listCertificate = result.filter(item => item.certificate.type == 1);
        this.listAchievement = result.filter(item => item.certificate.type == 2);
      }
    })
  }

  search(type: number, status: any){
    if(type == 1)
      this.listCertificate = this.allCertificate.filter(item => item.certificate.type == type && ((status != -1 && item.activated == status) || status == -1));
    if(type == 2)
      this.listAchievement = this.allCertificate.filter(item => item.certificate.type == type && ((status != -1 && item.activated == status) || status == -1));
  }
}
