import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetPlanModel } from 'src/app/models/asset-plan.model';

@Component({
  selector: 'app-table-assets',
  templateUrl: './table-assets.component.html',
  styleUrls: ['./table-assets.component.scss']
})
export class TableAssetsComponent {
  @Input() assets: Array<AssetPlanModel[]> = [];
  @Input() showContract: boolean = true;
  @Input() page: number = 0;
  @Output() pageChange = new EventEmitter<number>();

  timeFormat(value:string) {
    if(value)
      value = value.substring(0, 5);
    return value;
  }

  alterPage(type: number) {
    this.page += type;
    this.pageChange.emit(this.page);
  }
}
