<app-auth-header></app-auth-header>

<div class="content">
    <div class="head">
        <div class="info">
            <span class="title">
                {{tournament?.title}}
            </span>
            <span class="subtitle">
                {{tournament?.subtitle}}
            </span>
            <a [routerLink]="'/torneios/regras/'+(tournament?.id??1)" class="link-rules color-primary-medium">Consultar regulamento</a>
        </div>
    </div>

    <div class="row mb-3" *ngIf="tournament">
        <div class="col-12">
            <div class="card podium">
                <div class="podium-item" *ngFor="let podium of listPodium; let index = index">
                    <div class="avatar" [ngClass]="{'gold': index==0, 'silver': index==1, 'bronze': index==2,}">
                        <img *ngIf="podium.user.profile_image" [src]="podium.user.profile_image">
                        <span *ngIf="!podium.user.profile_image" class="name-initials">{{ initialsName(podium.user.name)}}</span>
                        <span class="order">{{index+1}}</span>
                    </div>
                    <div class="desc">
                        <span class="name">{{podium.user.name.split(" ").slice(0, 2).join(" ")}}</span>
                        <span class="value">{{podium.account_balance | currency: 'R$'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-3" *ngIf="tournament">
        <div class="col-12">
            <div class="card">
                <div class="card-paginator">
                    <mat-paginator [length]="count" [pageSize]="15" [showFirstLastButtons]="false" (page)="alterPage($event)"
                        [hidePageSize]="true" [pageIndex]="pageIndex">
                    </mat-paginator>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th class="pos">Posição</th>
                            <th>Participante</th>
                            <th>Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rank of listRanking; let index = index">
                            <td class="pos">{{calcRank(index)}}°</td>
                            <td>{{rank.user.name}}</td>
                            <td>{{rank.account_balance | currency: 'R$'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>