import { Component } from '@angular/core';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { CreditCardModel } from 'src/app/models/credit-card.model';
import { ModalService } from 'src/app/services/utils/modal.service';
import { ConfirmDeleteCardComponent } from './confirm-delete-card/confirm-delete-card.component';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  loading: boolean = false;
  listCreditCard: CreditCardModel[] = [];

  constructor(
    private paymentCommand: PaymentCommand,
    private modalService: ModalService,
  ){ }

  ngOnInit() {
    this.loading = true;
    this.paymentCommand.getCreditCard().then((result: any) => {
      if(result.results)
        this.listCreditCard = result.results as CreditCardModel[];
        
      this.loading = false;
    });
  }

  deleteCard(id: number) {
    this.modalService.setContent(id);
    this.modalService.openModal(ConfirmDeleteCardComponent, '400px', null);
  }
}
