import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ConfigurationModel } from '../models/configuration.model';
import { ConfigurationCommand } from '../commands/configuration.command';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard  {
    configuration: ConfigurationModel;

    constructor(private router: Router,
        private configurationCommand: ConfigurationCommand) { }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        await this.configurationCommand.get().then((result: ConfigurationModel) => {
            this.configuration = result;
            
        });

        this.checkMaintenance();

        if (!this.configuration.under_maintence) {
            this.router.navigateByUrl("/home");
            return false
        }
        return true;
    }

    checkMaintenance() {
        if (this.configuration && this.configuration.under_maintence) {
            localStorage.setItem('maintenance', 'Y');
        } else {
            localStorage.setItem('maintenance', 'N');
        }
    }
}
