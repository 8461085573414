<div class="body">
    <section class="body-content">
        <div class="body-title">Pagamento com Pix</div>
        <span class="body-text color-over">
            Para ativar sua conta de negociação, escaneie o código de barras no aplicativo do seu banco ou de pagamento Pix, 
            realize o pagamento e em até 30 minutos sua conta estará ativada automaticamente.
        </span>
        <div class="qr-code">
            <img *ngIf="pix" src="data:image/jpeg;base64, {{pix.encodedImage}}"/>
        </div>
    </section>
    
    <section class="footer-buttons">
        <button class="button-secondary" [cdkCopyToClipboard]="pix.payload" *ngIf="pix" (click)="copyPix()">Copiar código PIX</button>
        <button class="button-transparent" (click)="closeModal()">Cancelar</button>
    </section>
</div>