<app-simple-header></app-simple-header>

<div class="content">

    <div class="card" *ngIf="!success_add">
        <div class="card-title">Olá, preencha o formulário e faça o seu cadastro</div>

        <form [formGroup]="formValidation">
    
            <div class="input">
                <span id="label-email" class="input-text">Email*</span>
                <input class="input-box" type="text" placeholder="Informe seu email" [class.input-box-error]="submitted && (errorCtr.email.errors?.required || errorCtr.email.errors?.pattern)"
                formControlName="email" (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
    
                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.required">
                    O campo e-mail é obrigatório
                </div>
                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.pattern">
                    Preencha corretamente o campo
                </div>
            </div>
    
            <div class="input">
                <span id="label-name" class="input-text">Nome completo*</span>
                <input class="input-box" type="text" (focus)="focus('focus', 'name')" [class.input-box-error]="submitted && errorCtr.name.errors?.required"
                    (blur)="focus('unfocus', 'name')" formControlName="name" placeholder="Informe seu nome completo">
    
                <span class="error-form" *ngIf="submitted && errorCtr.name.errors?.required">
                    O campo nome é obrigatório
                </span>
            </div>
            
            <div class="input">
                <span id="label-phone_number">Telefone*</span>
                <input class="input-box" type="text" placeholder="Informe seu telefone" [class.input-box-error]="submitted && errorCtr.phone_number.errors?.required"
                formControlName="phone_number" (focus)="focus('focus', 'phone_number')" (blur)="focus('unfocus', 'phone_number')" 
                mask="(00) 0000-0000||(00) 0 0000-0000">
                
                <span class="error-form" *ngIf="submitted && errorCtr.phone_number.errors?.required">
                    O campo telefone é obrigatório
                </span>
            </div>
            
            <div class="input">
                <span id="label-coupon">Cupom*</span>
                <input class="input-box" type="text" placeholder="Informe o código do cupom" [class.input-box-error]="submitted && errorCtr.coupon.errors?.required"
                formControlName="coupon" (focus)="focus('focus', 'coupon')" (blur)="focus('unfocus', 'coupon')">
                
                <span class="error-form" *ngIf="submitted && errorCtr.coupon.errors?.required">
                    O campo cupom é obrigatório
                </span>
            </div>
        </form>

        <button class="button-primary" (click)="submit()">Salvar</button>
    </div>

    
    <div class="help" *ngIf="!success_add">
        <div>Bem vindo(a) ao Insider Invest, encontrou algum problema ou está com dificuldade em utilizar a plataforma? Contate o suporte através do email 
            <strong>suporte@insiderinveste.com.br</strong>, seu feedback é muito importante e nos ajuda a melhorar sua experiência.</div>
    </div>

    <div class="success" *ngIf="success_add">

        <div class="title">Olá <span class="color-primary">{{first_name}}</span>, seu cadastro foi criado com sucesso, dentro de instantes você irá receber as suas credênciais no seu e-mail. Clique no botão abaixo para acessar a plataforma.</div>

        <button class="button-primary" [routerLink]="'/login'">Acessar plataforma</button>
    </div>
</div>