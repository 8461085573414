import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserCommand } from 'src/app/commands/user.command';
import { UserModel } from 'src/app/models/user.model';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { EventsService } from 'src/app/services/utils/events.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/components/alert/alert.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { SidenavService } from 'src/app/components/sidenav/sidenav.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss']
})
export class UserDataComponent implements OnInit {
  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  userEdit: UserModel;
  loading: boolean = false;
  first_letter_name: string;


  constructor(
    public formBuilder: UntypedFormBuilder,
    public inputFormService: InputFormsService,
    public userCommand: UserCommand,
    public modalService: ModalService,
    private eventService: EventsService,
    private firebaseService: FirebaseService,
    private contentPageService: PagesContentService,
    private alertComponent: AlertService,
    private router: Router,
    private sidenavService: SidenavService,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('minha-conta');
    this.sidenavService.getProgressBar().subscribe(
      value => {
        this.loading = value;
      }
    );
    this.formValidation = this.formBuilder.group({
      email: { value: '', disabled: true },
      name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      surname: ['', []],
    });

    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('minha-conta');
    
      if(page_block){
        this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
        this.router.navigate(['home']);
      }
    },1000);

    this.getUser();

    this.eventService.get_event().subscribe((page: string) => {
      if (page == 'delete-profile-image')
        this.getUser();
    })
  }

  getUser() {
    this.userCommand.get().then((result: UserModel) => {
      this.userEdit = result;
      this.first_letter_name = this.userEdit.name.substring(0,1).toUpperCase()
      this.formValidation.patchValue(result);
    })
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  updateMask(event, field: string) {
    this.inputFormService.updateMask(this.formValidation, field, event);
  }

  async submit() {
    this.firebaseService.eventClick('update-user-info', 'minha-conta');
    this.submitted = true;
    let user: UserModel = this.formValidation.value as UserModel;

    if (this.formValidation.valid) {
      this.userCommand.update(user);
    }
  }

  openImageSelection(): void {
    this.firebaseService.eventClick('open-image-selector', 'minha-conta');

    const imageInput = document.getElementById('imageInput');
    imageInput.click();
  }

  handleImageSelection(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];

    this.userCommand.get().then((result: UserModel) => {
      result.profile_image = file;

      this.userCommand.update(result, false, true).then((result: boolean) => {
        this.userEdit = JSON.parse(localStorage.getItem('user')) as UserModel;
      });
    })
  }

  deleteImage() {
    this.firebaseService.eventClick('delete-image', 'minha-conta');

    this.modalService.openModal(ConfirmDeleteComponent, '400px');
  }
}
