import { Component } from '@angular/core';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss']
})
export class SupportButtonComponent {
  support() {
    window.open("https://wa.me/5562994788905?text=Olá, estou precisando de ajuda do suporte.", '_blank');
  }
}
