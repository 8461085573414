<div class="side-content" [class.retract]="retract">
    <div class="logo" (click)="openPage('home')">
        <img src="assets/icons/sidenav-logo.svg">
    </div>
    <div class="expand-button">
        <button class="button-primary" [class.flip]="!retract" (click)="toggleMenu()"><img src="assets/icons/sidenav-expand.svg"></button>
    </div>
    <section class="items-menu color-dark">
        <div class="item" [class.active]="itemSelected == 'home'" (click)="openPage('home')">
            <div class="menu-icon"><img src="assets/icons/sidenav-category.svg"> </div>
            <span>Início</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'ativos-permitidos'" (click)="openPage('ativos-permitidos')"
            *ngIf="pagesContentService.isVisible('ativos-permitidos')">
            <div class="menu-icon"><img src="assets/icons/sidenav-operations.svg"> </div>
            <span>Ativos Permitidos</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'regras-objetivos'" (click)="openPage('regras-objetivos')"
            *ngIf="pagesContentService.isVisible('regras-objetivos')">
            <div class="menu-icon rules"><img src="assets/icons/sidenav-checklist.svg"> </div>
            <span>Regras e Objetivos</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'minhas-transacoes'" (click)="openPage('minhas-transacoes')"
            *ngIf="pagesContentService.isVisible('minhas-transacoes')">
            <div class="menu-icon"><img src="assets/icons/sidenav-cart.svg"> </div>
            <span>Minha compras</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'saque'" (click)="openPage('saque')"
            *ngIf="pagesContentService.isVisible('saque')">
            <div class="menu-icon"><img src="assets/icons/sidenav-withdraw.svg"> </div>
            <span>Solicitar saque</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'downloads'" (click)="openPage('downloads')"
            *ngIf="pagesContentService.isVisible('downloads')">
            <div class="menu-icon"><img src="assets/icons/sidenav-download.svg"> </div>
            <span>Downloads</span>
        </div>
    </section>
</div>