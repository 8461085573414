import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "../components/alert/alert.service";
import { SidenavService } from "../components/sidenav/sidenav.service";
import { ImportFileModel } from "../models/import-file.model";

@Injectable({
    providedIn: 'root'
})
export class ImportCommand {

    private api: ApiRequestService;

    constructor(
        private alert: AlertService,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get(account: number) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/operating/import-files/?account_operation=${account}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    page(pg = '') {
        this.sidenavService.enableProgressBar();
        const params = pg.split('?');
        if(params){
            const api = new ApiRequestService(this.http, `/operating/import-files/?${params[1]}`);
            return new Promise((resolve) => {
    
                api.get<any>().subscribe({
                    next: (result: any) => {
    
                        resolve(result);
                        this.sidenavService.disableProgressBar();
                    },
                    error: (err) => {
                        this.requestError.handleError(err);
                        resolve(null);
                        this.sidenavService.disableProgressBar();
                    }
                });
            });
        }else{
            return new Promise((resolve) => {
                resolve(null);
                this.sidenavService.disableProgressBar();
            });
        }
    }

    import(import_file: ImportFileModel) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/operating/import-files/");

        return new Promise(async (resolve) => {
            let formData = new FormData();

            for (let [key, value] of Object.entries(import_file)) {
                if (value != null || value == '') {
                    formData.append(key, value);
                }
            }
            this.api.post<any>(formData).subscribe({
                next: async (result: ImportFileModel) => {
                    if (result) {
                        this.alert.presentAlert("success", "Importação concluída", "Importação realizada com sucesso.");
                        resolve(true);
                    } else {
                        this.alert.presentAlert("error", "Falha na importação", "Não foi possível realizar a importação.");
                        resolve(false);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    delete(id:number, account: number) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, `/operating/import-files/`);

        return new Promise(async (resolve) => {

            this.api.deleteFilter(id, `account_operation=${account}`).subscribe({
                next: async (result: any) => {
                    resolve(true);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}