import { Component } from '@angular/core';

@Component({
  selector: 'app-account-leverage',
  templateUrl: './account-leverage.component.html',
  styleUrls: ['./account-leverage.component.scss']
})
export class AccountLeverageComponent {

}
