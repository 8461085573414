<app-simple-header page="payment"></app-simple-header>

<div class="content" *ngIf="!loading">

    <div class="card">
        <div class="card-header">
            <div class="logo" *ngIf="plan">
                <img *ngIf="!plan.image" src="assets/images/insider_logo.png" />
                <img *ngIf="plan.image" [src]="plan.image" />
            </div>
            <div class="description">
                <span class="title" *ngIf="type == 'success'">Bem-vindo(a) ao plano 
                    <span *ngIf="plan">
                        {{plan.title}}&nbsp;
                        <span *ngIf="plan.is_stock != null && !plan.is_stock">(Índices e derivativos)</span>
                        <span *ngIf="plan.is_stock != null && plan.is_stock">(Ações Americanas)</span>
                    </span> da Insider Investe
                </span>
                <span class="title" *ngIf="type == 'error'">Mensagem sobre sua tentativa de compra do Plano <span *ngIf="plan">{{plan.title}}</span></span>
                <span class="title" *ngIf="type == 'plan-not-found'">Plano não encontrado</span>
                <span class="title" *ngIf="type == 'charge-not-found'">Cobrança não encontrada</span>
            </div>
        </div>

        <div class="buy-detail" *ngIf="type == 'success'">
            <span class="detail">Resumo da compra</span>
            <div class="buy">
                <div class="">
                    <span class="title">Insider Investe - Mesa proprietária</span>
                    <span class="title selected-platform" *ngIf="plan && plan.value_black_arrow">
                        + Plataforma
                        <span *ngIf="payment.platform == 'MT5'">Meta trader 5 - {{metaTraderValue | currency: 'R$'}}</span>
                        <span *ngIf="payment.platform == 'BA'">Black Arrow - {{blackArrowValue | currency: 'R$'}}</span>
                    </span>
                    <div class="coupon-label" *ngIf="coupon">
                        <span> {{ coupon.name }} - </span>
                        <span *ngIf="coupon.type_discount == 0">{{coupon.value_discount | currency: 'R$'}}</span>
                        <span *ngIf="coupon.type_discount == 1">{{coupon.value_discount | number:'1.0-0' }}%</span>
                    </div>
                </div>
                <span class="price" *ngIf="plan">{{plan.value_plan | currency: 'R$'}}</span>
            </div>
        </div>

        <div class="message" [class.error-message]="type == 'error' || type == 'plan-not-found' || type == 'charge-not-found'">
            <span *ngIf="type == 'success'">
                Parabéns! Seu pagamento foi recebido com sucesso e sua conta de negociação está pronta para uso. 
                Aguarde alguns instantes e sua conta será ativada. 
                Se precisar de ajuda ou tiver alguma dúvida, estamos aqui para ajudar. Bons negócios!
            </span>
            <span *ngIf="type == 'error' && !message">
                <p>Ops! Parece que algo deu errado. Por favor, verifique se os dados foram informados corretamente e tente novamente.</p>
                <p>Se o problema persistir, entre em contato conosco para obter assistência adicional. Pedimos desculpas pelo inconveniente!</p>
            </span>
            <span *ngIf="type == 'error' && message">
                <p>{{message}}</p>
            </span>
            <span *ngIf="type == 'plan-not-found'">
                <p>O link pertence a um plano que não está mais disponível, por favor, acesse a nossa página de vendas e selecione o plano que deseja realizar a compra.</p>
            </span>
            <span *ngIf="type == 'charge-not-found'">
                <p>A cobrança não foi encontrada. Verifique a url acessada, ou visite o nosso <a href="https://insiderinveste.com/">site</a> para escolher o plano desejado</p>
            </span>
        </div>
        <div class="button-center">
            <button class="button-primary" [routerLink]="'/home'" *ngIf="type == 'success'">Acessar a Insider Investe</button>
            <button class="button-primary" (click)="resend()" *ngIf="type == 'error'">Tentar novamente</button>
            <button class="button-primary" (click)="openSalesPage()" *ngIf="type == 'plan-not-found'">Acessar página de vendas</button>
        </div>
    </div>
</div>