<app-simple-header></app-simple-header>

<div class="content" *ngIf="termUse">
    <div class="title">
        {{termUse.title}}
    </div>

    <div class="term" *ngIf="termUse && termUse.content">
        <div [innerHTML]="termUse.content"></div>
    </div>
</div>