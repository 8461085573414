<section class="alert-default alert-default-{{alert.type}}">
    <div class="alert-content">
        <i class=""></i>
        <i *ngIf="alert.type == 'error'" class="icon-status fa-solid fa-circle-exclamation" name="close-circle"></i>
        <i *ngIf="alert.type == 'warning'" class="icon-status fa-solid fa-triangle-exclamation" name="alert-circle"></i>
        <i *ngIf="alert.type == 'success'" class="icon-status fa-solid fa-check" name="checkmark-circle"></i>

        <span [innerHTML]="alert.description"></span>
        <i class="icon-close fa-solid fa-xmark" name="close-outline" (click)="close()"></i>
    </div>
</section>