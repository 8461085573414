import { Component } from '@angular/core';

@Component({
  selector: 'app-drawdown',
  templateUrl: './drawdown.component.html',
  styleUrls: ['./drawdown.component.scss']
})
export class DrawdownComponent {

}
