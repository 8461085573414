<section>
    <div class="subtitle">Por que o meu Meta Trader 5 está travando enquanto eu opero?</div>
    <div class="over-text">Quando o Insider Investe Real Time está habilitado no mesmo gráfico que você está operando, sempre que o tempo gráfico é
        alterado o sistema operacional (Windows)
        encerra o Plugin e inicia novamente, quando essa mudança de tempo gráfico acontece várias em um curto espaço de
        tempo o sistema operacional cria vários processos
        de inicialização do Plugin até que o próprio sistema operacional trava a plataforma.
    </div>

    <div class="subtitle margin-top">SOLUÇÃO</div>
    <div class="over-text">Para o funcionamento correto do Insider Investe é preciso atender aos seguintes requisitos:</div>
    <br>
    <div class="over-text"><strong>a. </strong>O gráfico que ele está habilitado precisa estar andando</div>
    <div class="over-text"><strong>b. </strong>O gráfico precisa estar aberto ou em segundo plano</div>
    <br>
    <div class="over-text">Para resolver o problema de travamento da plataforma, basta habilitar o Insider Investe Real Time em
        um gráfico que você não está operando, mas que está em andamento. Como mostra no exemplo abaixo:</div>

    <div class="subtitle margin-top">Passo 1</div>
    <div class="over-text">Na imagem abaixo, abrimos um gráfico de UsaTecSep23, onde habilitamos o Insider Investe Real Time
    </div>
    <div class="inline">
        <a href="assets/images/faq/meta_trader1.PNG" target="_blank">
            <img class="step3" src="assets/images/faq/meta_trader1.PNG" alt="meta_trader1">
        </a>
    </div>

    <div class="subtitle margin-top">Passo 2</div>
    <div class="over-text">Já na imagem abaixo abrimos <span class="color-highlight"><strong>outro</strong></span> gráfico
        de UsaTecSep23 <i>(nesse caso você pode abrir qualquer gráfico do ativo que você vai operar)</i> e deixamos o
        gráfico que habilitamos o Insider Investe Real Time em segundo plano <i>(como pode ver, ele ficou minimizado)</i></div>
    <div class="inline">
        <a href="assets/images/faq/meta_trader2.PNG" target="_blank">
            <img class="step3" src="assets/images/faq/meta_trader2.PNG" alt="meta_trader2">
        </a>
    </div>

    <div class="subtitle margin-top">Conclusão</div>
    <div class="over-text">Seguindo os passos de habilitar o Insider Investe Real Time em um gráfico e deixá-lo ele em segundo plano
        para que operar os ativos que deseja em outras janelas de gráfico irá solucionar o problema de travamento da
        plataforma do Meta Trader 5.</div>

    <div class="over-text margin-top">Qualquer dúvida, não deixe de contatar o nosso suporte através do e-mail <span
            class="color-highlight">suporte&#64;insiderinveste.com.br</span> </div> <br>
</section>