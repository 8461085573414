<app-auth-header page="meta-vision"></app-auth-header>

<div class="content" *ngIf="configuration">
    <div>
        <section>
            <div class="title">Atualização do Insider Investe Real Time</div>
            <div class="over-text">Olá <span class="color-highlight">{{user.name.split(' ')[0]}}</span>, assista o vídeo a seguir e siga o passo a passo para realizar a atualização do Insider Investe Real Time.</div>

            <div class="tutorial">
                <iframe class="video" [src]="configuration.url_tutorial_update_meta_vision"  width="640" height="360" frameborder="0" title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen *ngIf="this.configuration.url_tutorial_update_meta_vision"></iframe>
    
                <button class="button-primary" (click)="download_file()"><i class="fa-solid fa-download"></i>Baixar Insider Investe</button>
                <button class="button-outline" *ngIf="configuration.url_tutorial_update_meta_vision" [routerLink]="'/configurar-conta-manual'">Atualizar manualmente</button>
            </div>
        </section>
       
       
    </div>
    
</div>