import { Component } from '@angular/core';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { PaymentHistoryModel } from 'src/app/models/payment.model';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PixModalComponent } from './pix-modal/pix-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-history-transactions',
  templateUrl: './history-transactions.component.html',
  styleUrls: ['./history-transactions.component.scss']
})
export class HistoryTransactionsComponent {
  loading: boolean = false;
  listTransactions: PaymentHistoryModel[] = [];

  constructor(
    private paymentCommand: PaymentCommand,
    private modalService: ModalService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.paymentCommand.getHistory().then((result: any) => {
      if(result)
        this.listTransactions = result.results as PaymentHistoryModel[];

      this.loading = false;
    });
  }

  openPix(id: number) {
    this.paymentCommand.getPix(id).then((result: any) => {
      if(result){
        this.modalService.setContent(result);
        this.modalService.openModal(PixModalComponent, '500px', null);
      }else{
        this._snackBar.open('Pagamento pix não encontrado.', '',  {duration: 2000});
      }
    });
  }

  openBoleto(url: string) {
    window.open(url, "_blank");
  }

  verifyDueDate(dueDate: string) {
    const today = new Date();
    const dueDateDoc = new Date(dueDate+'T00:00');

    return today<=dueDateDoc;
  }
}
