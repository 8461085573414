import { Injectable } from "@angular/core";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";
import { AlertService } from "../components/alert/alert.service";
import { CouponModel } from "../models/coupon.model";
import { RequestErrorService } from "../services/utils/request_error.service";

@Injectable({
    providedIn: 'root'
})
export class CouponCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
        private alert: AlertService,
    ) {
    }

    post(coupon: string, email: string, plan: number) {
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account/coupon/check-coupon/`);

            if (!coupon || !email || !plan){
                this.alert.presentAlert("error", "Cupom e/ou e-mail inválidos", "Verifique se os campos estão preenchidos corretamente.");
                resolve(null);
            }

            this.api.post<any>({coupon, email, plan}).subscribe({
                next: (result: CouponModel) => {
                    result.value_discount = Number(result.value_discount);
                    resolve(result);
                },
                error: (err) => {
                    if (err.status != 404)
                        this.requestError.handleError(err);
                    resolve(null);
                }
            });
        });
    }
}