<app-auth-header></app-auth-header>

<div class="content">
    <section>
        <div class="title">FAQ</div>
    </section>
    

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        Porque o meu meta trader está travando enquanto opero?
                    </div>
                </mat-panel-title>
                
            </mat-expansion-panel-header>
            <mat-panel-description>
                <app-meta-vision-travando></app-meta-vision-travando>
            </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="over-text">
                        O ícone azul do Insider Investe Real Time não está ficando azul, o que fazer?
                    </div>
                </mat-panel-title>
                
            </mat-expansion-panel-header>
            <app-habilitar-algotrading></app-habilitar-algotrading>
        </mat-expansion-panel>
    </mat-accordion>
</div>