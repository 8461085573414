import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ConfigurationModel } from "../models/configuration.model";
import { PagesContentService } from "../services/utils/pages-content.service";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class ConfigurationCommand {
    api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private router: Router,
        private http: HttpClient,
        private pagesContentService: PagesContentService,
        private sidenavService: SidenavService,
    ) {
    }

    get() {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/account/app-configuration/");
        return new Promise((resolve) => {

            this.api.get<any>().subscribe({
                next: (result: ConfigurationModel) => {

                    if (result) {
                        this.pagesContentService.setPagesContent(result.pages_content)
                        resolve(result);
                    }
                    else {
                        resolve(null);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    this.requestError.handleError(err);
                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}