<app-auth-header page="rules-objectives"></app-auth-header>

<div class="content">
    <div class="title">
        Regras e Objetivos
    </div>
    <section class="row-content">
        <div class="subtitle">
            Selecione o tipo de plano
        </div>
        <div class="container-tabs">
            <div class="tabs">
                <input type="radio" id="assets" name="tabs" checked (click)="filterPlan('asset')"/>
                <label class="tab" for="assets">
                    <img src="assets/icons/world_icon.svg">
                    Índices e derivativos
                </label>
                <input type="radio" id="stock" name="tabs" (click)="filterPlan('stock')"/>
                <label class="tab" for="stock">
                    <img src="assets/icons/usa_flag.svg">
                    Ações americanas
                </label>
                <span class="glider"></span>
            </div>
        </div>
        <div class="subtitle">
            Saldo da conta
        </div>
        <div class="plan-choice" *ngIf="filter_plans && plan">
            <button *ngFor="let item of filter_plans" (click)="changePlan(item)" 
                [class.button-primary]="plan.id == item.id"> 
                    <span class="symbol">$</span> {{item.value_account | number:'1.2-2'}}
            </button>
        </div>
    </section>

    <section class="row-content" *ngIf="filter_plans">
        <app-table-rules-objectives id="web" [plans]="filter_plans"></app-table-rules-objectives>
        <app-table-rules-objectives id="mobile" [plans]="mobileFilterPlan()"></app-table-rules-objectives>
    </section>
</div>