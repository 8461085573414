<app-auth-header page="meta-vision"></app-auth-header>

<div class="content" *ngIf="configuration">
    <div>
        <section>
            <div class="title">Configuração do teste pelo celular</div>

            <section class="alert">
                <div class="alert-title">LEIA COM ATENÇÃO</div>
                <div class="alert-description">Para realizar o teste pelo celular é preciso ter o Meta Trader 5 configurado em um computador. 
                    <br><br>
                    <strong>Durante o período que as operações estiverem sendo realizadas pelo celular é obrigatório que o computador esteja ligado com o Meta Trader 5 aberto e o Insider Investe Real Time devidamente configurado.</strong></div>
            </section>

            <div class="over-text">
                Siga o passo a passo para acessar a conta através do celular:
            </div>
            <br><br>
            <div class="over-text steps">
                1. Acesse a loja do seu dispositivo e baixe o aplicativo MetaTrader 5. <br>
                2. Após abrir o aplicativo, acesso o menu e clique em Nova conta. <br>
                3. No campo de corretora digite <strong>Activ</strong> e selecione a opção <strong>ActivTrades Corp</strong> <br>
                4. <a [routerLink]="'/home'" target="_blank">Clique aqui</a> para acessar a Home da Insider, no card da sua conta de negociação terá o botão de credenciais, clique nele para ter acesso ao número e senha da sua conta. <br>
                5. De volta ao aplicativo, digite o número da conta no campo de Login, e a senha. <br>
                6. Clique em entrar <br>
                <strong>7. Durante o período que as operações estiverem sendo realizadas pelo celular é obrigatório que o computador esteja ligado com o Meta Trader 5 aberto e o Insider Investe Real Time devidamente configurado.</strong>
            </div>
        </section>


    </div>

</div>