<app-simple-header></app-simple-header>

<div class="content">
    <div class="card">
    <div class="card-title">Informe sua nova senha</div>

    <form [formGroup]="formValidation">

        <div class="input input-new-password">
            <input class="input-box" type="password" (focus)="focus('focus', 'password')" [class.input-box-error]="submitted && (errorCtr.password.errors?.required || errorCtr.password.errors?.minlength)"
                (blur)="focus('unfocus', 'password')" formControlName="password" placeholder="Nova senha">

            <span class="error-form" *ngIf="submitted && errorCtr.password.errors?.required">
                O campo senha é obrigatório
            </span>

            <span class="error-form" *ngIf="submitted && errorCtr.password.errors?.minlength">
                A senha deve conter pelo menos 08 caracteres
            </span>
        </div>

        <div class="input">
            <input class="input-box" type="password" (focus)="focus('focus', 'password2')" [class.input-box-error]="submitted && (errorCtr.password2.errors?.required || (!errorCtr.password2.errors?.required && formValidation.hasError('mismatchedPasswords')))"
                (blur)="focus('unfocus', 'password2')" formControlName="password2" placeholder="Repita sua nova senha">


            <span class="error-form" *ngIf="submitted && errorCtr.password2.errors?.required">
                O campo confirmar senha é obrigatório
            </span>

            <span class="error-form"
                *ngIf="submitted && (!errorCtr.password2.errors?.required && formValidation.hasError('mismatchedPasswords'))">
                As senhas não conferem
            </span>
        
            <span class="error-form"
                *ngIf="submitted && messageError">
                {{messageError}}
            </span>
        </div>
    </form>

    <button class="button-primary" (click)="submit()">Salvar nova senha</button>
    </div>
</div>