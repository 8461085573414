import { Component, OnInit } from '@angular/core';
import { TutorialNotificacoes } from 'src/app/services/utils/tutorial-notifications.content';

@Component({
  selector: 'app-active-notifications',
  templateUrl: './active-notifications.component.html',
  styleUrls: ['./active-notifications.component.scss']
})
export class ActiveNotificationsComponent implements OnInit{
  listSteps: Array<any> = [];

  ngOnInit() {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Chrome') > -1) {
      this.listSteps = TutorialNotificacoes.chrome;
    } else if (userAgent.indexOf('Firefox') > -1) {
      this.listSteps = TutorialNotificacoes.firefox;
    } else if (userAgent.indexOf('Safari') > -1) {
      this.listSteps = TutorialNotificacoes.safari;
    } else {
      this.listSteps = TutorialNotificacoes.default_browser;
    }
  }
}
