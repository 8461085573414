import { Component } from '@angular/core';
import { UtilsCommand } from 'src/app/commands/utils.command';
import { UserModel } from 'src/app/models/user.model';
import { EventsService } from 'src/app/services/utils/events.service';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {

  constructor(public utilsCommand: UtilsCommand,
    private eventService: EventsService,
    private modalService: ModalService) {}

  cancel() {
    this.modalService.closeModal();
  }
  
  delete() {
    let user: UserModel = JSON.parse(localStorage.getItem('user')) as UserModel;
    
    this.utilsCommand.deleteImg(user.id, 1).then((result: boolean) => {
      this.eventService.call('delete-profile-image');
      this.cancel();
    });
  }
}
