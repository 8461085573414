import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PlanCommand } from 'src/app/commands/plan.command';
import { AssetPlanModel } from 'src/app/models/asset-plan.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent {
  listPlans: PlanModel[] = [];
  filter_plans: PlanModel[] = [];
  plan: PlanModel;
  listAssets: Array<AssetPlanModel[]> = [];
  lastFilter: string = '';
  page: number = 0;
  showAuthHeader: boolean = false;

  constructor(
    private planCommand: PlanCommand,
    private router: Router,
  ){
    this.showAuthHeader = this.router.url.split('/')[1] == 'ativos-permitidos';
  }

  ngOnInit() {
    this.planCommand.getPublic().then((result: any) => {
      if(result && result.results){
        this.listPlans = result.results;
        this.filterPlan('asset');
      }
    });
  }

  filterPlan(type: string) {
    if(type == 'asset')
      this.filter_plans = this.listPlans.filter(item => !item.is_stock);
    else
      this.filter_plans = this.listPlans.filter(item => item.is_stock);

    this.changePlan(this.filter_plans[0]);
  }

  changePlan(plan: PlanModel) {
    this.plan = null;
    setTimeout(() => {
      this.plan = plan;
      this.page = 0;
      this.filterAssets('');
    }, 100);
  }

  filterAssets(filterText: string){
    filterText = filterText.toLowerCase();
    if(this.lastFilter != filterText){
      this.lastFilter = filterText;
      this.page = 0;
    }
    if(filterText.length > 0)
      this.paginate(this.plan.assets.filter(item => item.asset.toLowerCase().includes(filterText) || item.description.toLowerCase().includes(filterText)));
    else
      this.paginate(this.plan.assets);
  }

  paginate(listFilterAssets: AssetPlanModel[] = []){
    this.listAssets = [];
    if(listFilterAssets)
      for(let offset = 0; offset <= listFilterAssets.length; offset+=15)
        this.listAssets.push(listFilterAssets.slice(offset, offset+15));
  }
}
