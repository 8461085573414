<app-auth-header page="user-data"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="title">
        Minhas compras
    </div>
    <div class="card">
        <table>
            <thead>
                <tr>
                    <th>Plano</th>
                    <th>Plataforma</th>
                    <th>Data de criação</th>
                    <th>Status</th>
                    <th>Vencimento</th>
                    <th>Forma de pagamento</th>
                    <th>Conta de negociação</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listTransactions">
                    <td><!--nome do plano--></td>
                    <td>
                        <span *ngIf="item.platform == 'MT5' || !item.platform">Meta Trader 5</span>
                        <span *ngIf="item.platform == 'BA'">Black Arrow</span>
                    </td>
                    <td>{{item.date_created | date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <span class="color-primary-medium" *ngIf="item.status == 'CONFIRMED' || item.status == 'RECEIVED'">Aprovado</span>
                        <span class="color-warning" *ngIf="item.status == 'PENDING' && verifyDueDate(item.dueDate)">Pendente</span>
                        <span class="color-danger" *ngIf="item.status == 'OVERDUE' || (item.status == 'PENDING' && !verifyDueDate(item.dueDate))">Vencido</span>
                    </td>
                    <td>{{item.dueDate| date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <div class="payment-type" *ngIf="item.billingType == 'BOLETO'"> <img src="assets/icons/boleto.svg"> <span>Boleto</span></div>
                        <div class="payment-type" *ngIf="item.billingType == 'CREDIT_CARD'"> <img src="assets/icons/credit.svg"> <span>Cartão</span></div>
                        <div class="payment-type" *ngIf="item.billingType == 'PIX'"> <img src="assets/icons/pix_logo.svg"> <span>Pix</span></div>
                    </td>
                    <td>
                        <a class="account-link" *ngIf="item.account_operation" [routerLink]="'/desempenho/' + item.account_operation.account">{{item.account_operation.account}}</a>
                    </td>
                    <td class="action">
                        <div *ngIf="item.billingType == 'BOLETO' && item.status == 'PENDING' && verifyDueDate(item.dueDate)" (click)="openBoleto(item.bankSlipUrl)">
                            <i class="fa-solid fa-download"></i><span> Baixar</span>
                        </div>
                        <div *ngIf="item.billingType == 'PIX' && item.status == 'PENDING' && verifyDueDate(item.dueDate)" (click)="openPix(item.id)">
                            <i class="fa-regular fa-eye"></i><span> Visualizar</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>