<div class="side-content">
    <div class="close-button" (click)="closeMenu()">
        <i class="fa-solid fa-xmark"></i>
    </div>
    <section class="items-menu color-over">
        <div class="item" [class.active]="itemSelected == 'home'" [routerLink]="'/home'"
            (click)="openPage('home')">
            <div class="menu-icon"> <img src="assets/icons/sidenav-category.svg"> </div>
            <span>Início</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'ativos-permitidos'" (click)="openPage('ativos-permitidos')"
            *ngIf="pagesContentService.isVisible('ativos-permitidos')">
            <div class="menu-icon"><img src="assets/icons/sidenav-operations.svg"> </div>
            <span>Ativos Permitidos</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'regras-objetivos'" (click)="openPage('regras-objetivos')"
            *ngIf="pagesContentService.isVisible('regras-objetivos')">
            <div class="menu-icon"><img src="assets/icons/sidenav-checklist.svg"> </div>
            <span>Regras e Objetivos</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'certificados'" (click)="openPage('certificados')"
            *ngIf="pagesContentService.isVisible('certificados')">
            <div class="menu-icon"><img src="assets/icons/sidenav-certificate.svg"> </div>
            <span>Certificados</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'torneios'" (click)="openPage('torneios')"
            *ngIf="pagesContentService.isVisible('torneios')">
            <div class="menu-icon"><img src="assets/icons/sidenav-chapionship.svg"> </div>
            <span>Torneios Insider</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'saque'" (click)="openPage('saque')"
            *ngIf="pagesContentService.isVisible('saque')">
            <div class="menu-icon"><img src="assets/icons/sidenav-withdraw.svg"> </div>
            <span>Solicitar saque</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'minhas-transacoes'" (click)="openPage('minhas-transacoes')"
            *ngIf="pagesContentService.isVisible('minhas-transacoes')">
            <div class="menu-icon"><img src="assets/icons/sidenav-cart.svg"> </div>
            <span>Minha compras</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'downloads'" (click)="openPage('downloads')"
            *ngIf="pagesContentService.isVisible('downloads')">
            <div class="menu-icon"><img src="assets/icons/sidenav-download.svg"> </div>
            <span>Downloads</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'planos'" (click)="openPage('planos')"
            *ngIf="pagesContentService.isVisible('planos')">
            <div class="menu-icon"><img src="assets/icons/rules_green.svg"> </div>
            <span>Nova conta Insider</span>
        </div>
    </section>
    <section class="items-menu color-over logout-mobile">
        <div class="item"  [class.active]="itemSelected == 'perguntas-frequentes'"
            (click)="openPage('perguntas-frequentes')">
            <div class="menu-icon"> <img src="assets/icons/sidenav-faq.svg"></div>
            <span>Perguntas frequentes</span>
        </div>
        <div class="item" (click)="support()">
            <div class="menu-icon"> <img src="assets/icons/sidenav-support.svg"></div>
            <span>Suporte</span>
        </div>
        <div class="item" [class.active]="itemSelected == 'minha-conta'" (click)="openPage('minha-conta')"
            *ngIf="pagesContentService.isVisible('minha-conta')">
            <div class="menu-icon"> <img src="assets/icons/sidenav-user.svg"></div>
            <span>Meus dados</span>
        </div>
        <div class="item logout" (click)="logout()">
            <div class="menu-icon"> <img src="assets/icons/sidenav-logout.svg" alt=""></div>
            <span>Sair</span>
        </div>
    </section>
</div>