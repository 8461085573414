import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";

import moment from 'moment';
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "../models/user.model";
import { AlertService } from "../components/alert/alert.service";
import { UserRegisterModel } from "../models/user-register.model";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class UserCommand {

    private api: ApiRequestService;

    constructor(
        private alert: AlertService,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    get(showLoading: boolean = true) {
        if(showLoading)
            this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account/authentication/user/");

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    localStorage.setItem("user", JSON.stringify(result));
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    update(user: UserModel, editDate: boolean = false, editImage: boolean = false, showAlert: boolean = true) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/account/authentication/user/info/");
        return new Promise(async (resolve) => {

            if (editDate)
                user.birth_date = moment(user.birth_date, "DD/MM/YYYY").format('YYYY-MM-DD');

            if(user.document_number)
                user.document_number = user.document_number.replace(/[^a-zA-Z0-9]/g, "");

            let formData = new FormData();

            for (let key of Object.keys(user)) {
                if (user[key] || key == 'profile_image') {
                    if (key != 'profile_image')
                        formData.append(key, user[key]);
                    else if (editImage) {
                        if (user[key]) {
                            formData.append(key, user[key], 'profile_picture.jpg');
                        } else {
                            formData.append(key, user[key]);
                        }
                    }
                }
            }

            this.api.put<UserModel>(formData, false).subscribe({
                next: async (result: UserModel) => {
                    if (result) {
                        localStorage.setItem("user", JSON.stringify(result));

                        if (showAlert)
                            await this.alert.presentAlert("success", "Enviado!", "Os seus dados foram atualizados com sucesso", "Continuar");

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    updateDeviceToken(token: string, showAlert: boolean = true) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/account/authentication/user/device-token/update/");
        return new Promise(async (resolve) => {

            this.api.post<any>({"device_token": token}).subscribe({
                next: async (result) => {
                    if (result) {
                        localStorage.setItem('lastTokenUpdate', new Date().toISOString());

                        if (showAlert)
                            await this.alert.presentAlert("success", "Enviado!", "Os seus dados foram atualizados com sucesso", "Continuar");

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    activateAccount(token: string) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account/account-confirm/${token}/`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    register(userRegister: UserRegisterModel) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account/user-coupon/register/`);

            this.api.post<any>(userRegister).subscribe({
                next: (result: any) => {
                    resolve(true);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status == 400) {
                        this.alert.presentAlert("error", "", err.error.message, "")
                    } else {
                        if (err.status != 404) {
                            this.requestError.handleError(err);
                        }
                    }

                    resolve(false);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}