<div class="plans-view" *ngIf="plans && plans.length != 0">
    <div class="card plan">
        <div class="card-header">
            Nova conta Insider
        </div>
        <div class="container-tabs">
            <div class="tabs">
                <input type="radio" id="assets" name="tabs" checked (click)="filterPlan('asset')"/>
                <label class="tab" for="assets">
                    <img src="assets/icons/world_icon.svg">
                    Índices e derivativos
                </label>
                <input type="radio" id="stock" name="tabs" (click)="filterPlan('stock')"/>
                <label class="tab" for="stock">
                    <img src="assets/icons/usa_flag.svg">
                    Ações americanas
                </label>
                <span class="glider"></span>
            </div>
        </div>
        <div class="subtitle">
            Saldo da conta
        </div>
        <div class="plan-choice">
            <button *ngFor="let plan of filter_plans" (click)="selected_plan = plan" 
                [class.button-primary]="selected_plan.id == plan.id"> 
                    <span class="symbol">$</span> {{plan.value_account | number:'1.2-2'}}
            </button>
        </div>
        <div class="plan-detail" *ngIf="selected_plan">
            <div class="list-itens">
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Meta de Lucro</span>
                        <span> ${{selected_plan.profit_target | number:'1.2-2'}}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Perda máxima</span>
                        <span> ${{selected_plan.trailing_drawdown | number:'1.2-2'}}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Mínimo de Dias Operados</span>
                        <span> {{selected_plan.minimum_days_operation}}</span>
                    </div>
                </div>
            </div>
            <div class="list-itens">        
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Perda diária</span>
                        <span *ngIf="selected_plan.limit_loss_day > 0"> ${{selected_plan.limit_loss_day | number:'1.2-2'}}</span>
                        <span *ngIf="selected_plan.limit_loss_day <= 0">Ilimitada</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Repasse</span>
                        <span> {{selected_plan.profit}}% de Lucro</span>
                    </div>
                </div>
                <div class="item" *ngIf="!selected_plan.is_stock">
                    <div class="item-inner">
                        <span class="main">Ativos</span>
                        <span>{{selected_plan.assets_description}}</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Plataformas</span>
                        <div class="platforms">
                            <img src="assets/images/plat_mt5.png">
                            <img src="assets/images/plat_ba.png" *ngIf="selected_plan.value_black_arrow">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card buy">
        <div class="plan-detail" *ngIf="selected_plan">
            <div class="card-header">
                <div class="logo">
                    <img *ngIf="!selected_plan.image" src="assets/images/insider_logo.png" />
                    <img *ngIf="selected_plan.image" [src]="selected_plan.image" />
                </div>
                <div class="description">
                    <span class="title">Insider Investe - Mesa propritária</span>
                    <span class="value" *ngIf="selected_plan">{{selected_plan.value_plan | currency: 'R$'}}</span>
                    <span class="plan-title" *ngIf="selected_plan">
                        PLANO {{selected_plan.title}}&nbsp;
                        <span *ngIf="!selected_plan.is_stock">(Índices e derivativos)</span>
                        <span *ngIf="selected_plan.is_stock">(Ações Americanas)</span>
                    </span>
                </div>
            </div>
            <div class="list-itens">
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Formas de pagamento</span>
                        <div class="payment-type">
                            <div class="payment">
                                <img src="assets/icons/credit.svg"/>
                                <span>Cartão de crédito</span>
                            </div>
                            <div class="payment">
                                <img src="assets/icons/pix_logo.svg"/>
                                <span>Pix</span>
                            </div>
                            <div class="payment">
                                <img src="assets/icons/boleto.svg"/>
                                <span>Boleto</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item" *ngIf="selected_plan.installment_count">
                    <div class="item-inner">
                        <span class="main">Parcelamento</span>
                        <span> Até {{selected_plan.installment_count}}x s/ juros</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <span class="main">Termos e condições</span>
                        <span> Consulte nossos</span> <span class="color-primary-medium term" (click)="openModal()"> Termos e condições</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-buy">
            <button class="button-primary" (click)="openPlan(selected_plan)">Comprar agora</button>
        </div>
    </div>
</div>