import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { AssetPlanModel } from 'src/app/models/asset-plan.model';

@Component({
  selector: 'app-assets-allowed',
  templateUrl: './assets-allowed.component.html',
  styleUrls: ['./assets-allowed.component.scss']
})
export class AssetsAllowedComponent {
  listAccounts: AccountOperationModel[] = [];
  account: string;
  account_operation: AccountOperationModel;
  listAssets: Array<AssetPlanModel[]> = [];
  lastFilter: string = '';
  page: number = 0;
  nav: any;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private router: Router,
  ){
    this.nav = this.router.getCurrentNavigation();
    if(this.nav.extras.state && this.nav.extras.state.account){
      this.account = this.nav.extras.state.account;
    }
  }

  ngOnInit() {
    this.accountOperationCommand.get().then((result: AccountOperationModel[]) => {
      if (result) 
        this.listAccounts = result;
      if(this.account)
        this.seach();
    });
  }

  seach() {
    this.account_operation = null;
    setTimeout(() => {
      this.account_operation = this.listAccounts.find(item => item.account == this.account);
      this.page = 0;
      this.filterAssets('');
    }, 100);
  }

  filterAssets(filterText: string){
    filterText = filterText.toLowerCase();
    if(this.lastFilter != filterText){
      this.lastFilter = filterText;
      this.page = 0;
    }
    if(filterText.length > 0)
      this.paginate(this.account_operation.plan.assets.filter(item => item.asset.toLowerCase().includes(filterText) || item.description.toLowerCase().includes(filterText)));
    else
      this.paginate(this.account_operation.plan.assets);
  }

  paginate(listFilterAssets: AssetPlanModel[] = []){
    this.listAssets = [];
    if(listFilterAssets)
      for(let offset = 0; offset <= listFilterAssets.length; offset+=15)
        this.listAssets.push(listFilterAssets.slice(offset, offset+15));
  }
}
