import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TournamentCommand } from 'src/app/commands/tournament.command';
import { TournamentModel } from 'src/app/models/tournament.model';

@Component({
  selector: 'app-rule-championship',
  templateUrl: './rule-championship.component.html',
  styleUrl: './rule-championship.component.scss'
})
export class RuleChampionshipComponent {
  tournament: TournamentModel;
  loading: boolean = false;
  auth: boolean = false;

  constructor(
    private tournamentCommand: TournamentCommand,
    private routeInfo: ActivatedRoute,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        const path = event.url.split('?')[0];
        this.auth = path.includes('torneios');
      }
    });
  }

  ngOnInit() {
    this.routeInfo.params.subscribe(async (params: any) => {
      if (params.id) {
        this.loading = true;
        this.tournamentCommand.getPublic(params.id, null).then((result: TournamentModel) => {
          if(result){
            this.tournament = result;
            this.loading = false;
          }else{
            this.router.navigateByUrl('/not-found');
          }
        });
      }
    });
  }

  validDate() {
    if(this.tournament){
      const endDate = new Date(`${this.tournament.end_date}T00:00`), currentDate = new Date();
      return currentDate < endDate;
    }
    return false;
  }
}
