import { Component } from '@angular/core';
import { BannerReadCommand } from 'src/app/commands/banner-read.command';
import { BannerReadModel } from 'src/app/models/banner-read.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-modal-banner-dashboard',
  templateUrl: './modal-banner-dashboard.component.html',
  styleUrls: ['./modal-banner-dashboard.component.scss']
})
export class ModalBannerDashboardComponent {
  messages: BannerReadModel;

  constructor(
    private modalService: ModalService,
    private bannerReadCommand: BannerReadCommand,
  ) { }

  ngOnInit() {
    this.messages = this.modalService.getContent() as BannerReadModel;
    this.modalService.setContent(null);
  }

  closeModal() {
    if(this.messages.expired_date){
      this.bannerReadCommand.setRead(this.messages.id);
    }
    this.modalService.closeModal();
  }
}
