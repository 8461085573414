import { Component } from '@angular/core';

@Component({
  selector: 'app-consistency-approved',
  templateUrl: './consistency-approved.component.html',
  styleUrls: ['./consistency-approved.component.scss']
})
export class ConsistencyApprovedComponent {

}
