
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/components/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class RequestErrorService {
  constructor(private alert: AlertService,
    private router: Router) { }

  public handleError(err: any, type: string = '') {

    switch (err.status) {
      case 400:
        this.handle400(err);
        break;
      case 401:
        this.handle401(type);
        break;
      case 403:
        this.handle403();
        break;
      case 404:
        this.handle404();
        break;
      case 302:
        if (type == 'login')
          this.alert.presentAlert("warning", "Ops!", "Seu e-mail ainda não foi confirmado. Acesse seu e-mail para realizar a confirmação da conta.")
        else
          this.alert.presentAlert("error", "Ops!", "Não foi possível concluir a sua requisição. Entre em contato com o suporte.");
        break;
      case 500:
        this.handle500();
        break;
      default:
        this.alert.presentAlert('warning', '', err.message);
        break;
    }
  }

  private handle400(err: any) {
    if (err.error.message)
      this.alert.presentAlert('error', '', err.error.message);
    else
      this.alert.presentAlert('error', '', 'Erro ao conectar no servidor. Contate o administrador');
  }

  private handle401(type: string) {
    switch (type) {
      case "login":
        this.alert.presentAlert("error", "Ops!", "E-mail ou senha inválido");
        break;
      case "register":
        this.alert.presentAlert("error", "Ops!", "Não foi possível realizar o seu cadastro");
        break;
      default:
        localStorage.clear();
        this.router.navigateByUrl('login');
        break;
    }
  }

  private handle403() {
    this.alert.presentAlert('error', '', 'Sem autorização');
  }

  private handle404() {
    this.alert.presentAlert('warning', '', 'Nenhum item encontrado');
  }

  private handle500() {
    this.alert.presentAlert('error', '', 'Erro do servidor. Contate o administrador!');
  }
}



