<div class="modal">
    <div class="content-body">
        <img class="close-modal" (click)="closeModal()" src="assets/icons/close.svg">
    
        <div class="content-body-title">Configurar conta?</div>
        
        <section class="content-body-info">
            <div class="content-body-info-item">
                <span>
                    Estou ciente das regras que precisam ser cumpridas e objetivos que precisam ser alcançados conforme descritos na <strong (click)="openRules()"> página de regras</strong>
                </span>
            </div>
            <div class="content-body-info-item">
                <span>Neste momento, haverá retenção dos valores pagos (taxa de realização do certame) em 
                    virtude dos custos administrativos e despesas de manutenção. Caso o teste seja iniciado, 
                    não haverá a restituição dos valores pagos. Deseja continuar?
                </span>
            </div>
        </section>
        
    </div>
    <section class="body-footer">
        <button class="button-primary" (click)="accept()">Continuar</button>
    </section>
</div>