import { Component } from '@angular/core';

@Component({
  selector: 'app-active-algotrading',
  templateUrl: './active-algotrading.component.html',
  styleUrls: ['./active-algotrading.component.scss']
})
export class ActiveAlgotradingComponent {

}
