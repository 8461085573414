import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserPasswordCommand } from 'src/app/commands/user-password.command';
import { UserAlterPassword } from 'src/app/models/user-alter-password.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  messageError: string = '';

  constructor(
    public inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    private userPasswordCommand: UserPasswordCommand,
    private firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('alterar-senha');
    this.formValidation = this.formBuilder.group({
      current_password: [null, [Validators.required]],
      new_password1: [null, [Validators.required, Validators.minLength(8)]],
      new_password2: [null, [Validators.required]],
    }, { validator: this.inputFormService.matchingPasswords('new_password1', 'new_password2') });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  submit() {
    this.firebaseService.eventClick('submit-change-password', 'alterar-senha');
    this.submitted = true;
    this.messageError = '';

    if (this.formValidation.valid) {

      let userNewPasswordModel: UserAlterPassword = this.formValidation.value as UserAlterPassword;
      
      this.userPasswordCommand.alterPassword(userNewPasswordModel).then((result: any) => {
        if(result && result.message) {
          this.messageError = result.message;
        }
      });
    }
  }
}
