import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { LoginModel } from "../models/login.model";
import { AlertService } from "../components/alert/alert.service";
import { SidenavService } from "../components/sidenav/sidenav.service";

@Injectable({
    providedIn: 'root'
})
export class LoginCommand {

    private api: ApiRequestService;

    constructor(
        private alert: AlertService,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    login(login: LoginModel) {
        this.sidenavService.enableProgressBar();

        return new Promise(async (resolve) => {
            
            this.api = new ApiRequestService(this.http, "/account/token/");


            this.api.post(login).subscribe({
                next: (result: any) => {

                    if (result.access) {

                        localStorage.setItem("token", result.access);
                        localStorage.setItem("refresh", result.refresh);
                        localStorage.setItem("user", JSON.stringify(result.user));

                        resolve(result.user);
                    }
                    else {
                        this.alert.presentAlert("error", "Erro", "Erro ao fazer login. Confira suas credênciais de acesso", "Fechar");
                        resolve(null);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    this.requestError.handleError(err, 'login');
                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    logout(token: string) {
        this.sidenavService.enableProgressBar();

        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account/authentication/user/device-token/");

            this.api.post({ device_token: token }).subscribe({
                next: (result: any) => {
                    resolve(true);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    refreshToken(refresh: string) {
        this.sidenavService.enableProgressBar();
        return new Promise((resolve) => {
            this.api = new ApiRequestService(this.http, "/account/token/refresh/");

            this.api.post({ refresh: refresh }).subscribe({
                next: (result: any) => {
                    if (result.access) {

                        localStorage.setItem("token", result.access);
                        resolve(true);
                    }
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}