<app-simple-header *ngIf="!showAuthHeader"></app-simple-header>
<app-auth-header *ngIf="showAuthHeader"></app-auth-header>

<div class="content">
    <div class="title">
        Ativos permitidos para negociações
    </div>
    <section class="row-content">
        <div class="container-tabs">
            <div class="tabs">
                <input type="radio" id="assets" name="tabs" checked (click)="filterPlan('asset')"/>
                <label class="tab" for="assets">
                    <img src="assets/icons/world_icon.svg">
                    Índices e derivativos
                </label>
                <input type="radio" id="stock" name="tabs" (click)="filterPlan('stock')"/>
                <label class="tab" for="stock">
                    <img src="assets/icons/usa_flag.svg">
                    Ações americanas
                </label>
                <span class="glider"></span>
            </div>
        </div>
        <div class="subtitle">
            Saldo da conta
        </div>
        <div class="plan-choice" *ngIf="filter_plans && plan">
            <button *ngFor="let item of filter_plans" (click)="changePlan(item)" 
                [class.button-primary]="plan.id == item.id"> 
                    <span class="symbol">$</span> {{item.value_account | number:'1.2-2'}}
            </button>
        </div>
    </section>

    <div class="plan_title" *ngIf="plan">
        <div>
            <div class="title">
                {{plan.title}}
            </div>
            <div class="subtitle">
                <span>Saldo inicial da conta: </span>
                <span class="value">{{plan.value_account | currency: '$'}}</span>
            </div>
        </div>
        <div class="input">
            <input class="input-box" type="text" placeholder="Procurar" #filter (keyup)="filterAssets(filter.value)">
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>
    </div>
    <span class="desc desc-alert" *ngIf="plan && plan.is_stock">
        <span class="color-danger">* </span> No âmbito do plano de ações, as contas disponíveis não possuem alavancagem. É de
        responsabilidade exclusiva do trader calcular os lotes que podem ser operados com base em seu
        saldo atual. Esta responsabilidade inclui a avaliação cuidadosa das posições que o trader
        pretende assumir, assegurando que estas não ultrapassem o saldo disponível na conta. 
        <br><br>
        Para calcular o tamanho de sua posição é necessário multiplicar a quantidade de contratos com o valor
        atual da ação. Essa regra leva em consideração todas as posições que o trader deseja abrir,
        todas elas somadas não deve ultrapassar o saldo atual da conta. <a [routerLink]="'/poder-de-compra'" target="_blank">Clique aqui</a> para saber mais sobre o poder de compra.
        <br><br>
    </span>
    <section class="table-assets" *ngIf="plan">
        <app-table-assets *ngIf="listAssets" [assets]="listAssets" [showContract]="!plan.is_stock" [showPaginator]="plan.is_stock" [(page)]="page"></app-table-assets>
    </section>
</div>