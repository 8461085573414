<div class="content-body">
    <div class="button-close" (click)="closeModal()"><i class="fa-solid fa-xmark over-text"></i></div>
    <div class="content-body-title">Agendamento para início do teste</div>
    <div class="content-body-text over-text">
        <span *ngIf="type == 1">
            Deseja realmente confirmar o início do teste para o dia {{schedule| date: 'dd/MM/yyyy'}}?
        </span>
        <span *ngIf="type == 2">
            Ao confirmar o início agora, a conta será disponibilizada imediatamente na aba de contas ativas da plataforma. Deseja continuar?
        </span>
    </div>
    <div class="content-body-footer">
        <button class="button-danger" (click)="closeModal()">Cancelar</button>
        <button class="button-primary" (click)="confirm()">Confirmar</button>
    </div>
</div>