<div class="body">
    <section class="body-content">
        <div class="body-title">Excluir Cartão de Crédito</div>
        <span class="body-text color-over">Deseja realmente excluir esse cartão de crédito?</span>
    </section>
    
    <section class="footer-buttons">
        <button class="button-transparent" (click)="cancel()">Cancelar</button>
        <button class="button-danger" (click)="delete()">Excluir</button>
    </section>
</div>