import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeCommand } from 'src/app/commands/unsubscribe.command';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss'
})
export class UnsubscribeComponent {
  title: string;
  description: string;

  constructor(
    private routeInfo: ActivatedRoute,
    private firebaseService: FirebaseService,
    private unsubscribeCommand: UnsubscribeCommand,
  ) { }
  
  ngOnInit() {
    this.firebaseService.viewScreen('unsubscribe');
    this.routeInfo.queryParams.subscribe((params: any) => {
      if (params) {
        const email: string = params.email;

        if (email) {
          this.unsubscribeCommand.unsubscribe(email).then((result: number) => {
            switch (result) {
              case 200:
                this.title = 'É uma pena que vamos perder um pouco do nosso contato.';
                this.description = 'Removemos o seu e-mail, você deixará de receber informações sobre nossas promoções e novidades. Mas não se preocupe, você pode voltar quando quiser. Basta entrar em contato conosco.';
                break;
              case 404:
                this.title = 'Não encontramos o seu e-mail na nossa base de dados';
                this.description = 'Não encontramos o seu e-mail na nossa base de dados, pode ser que você já tenha removido ele anteriormente.';
                break;
              default:
                this.title = 'Ops :(';
                this.description = 'Houve um erro ao remover o seu e-mail, tente novamente mais tarde.';
                break;
            }
          });
        } else {
          this.title = 'Ops :(';
          this.description = 'Não foi possível identificar o email.';
        }
      }
    });
  }
}
