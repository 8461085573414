<app-simple-header page="payment"></app-simple-header>

<div class="content" *ngIf="!loading && plan">

    <div class="card">
        <div class="card-header">
            <div class="logo">
                <img *ngIf="!plan.image" src="assets/images/insider_logo.png" />
                <img *ngIf="plan.image" [src]="plan.image" />
            </div>
            <div class="description">
                <span class="title" *ngIf="!charge">Insider Investe - Mesa propritária</span>
                <span class="title" *ngIf="charge">{{charge.description}}</span>
                <span class="author">Autor: PRIME INVESTE</span>
                <span class="value" *ngIf="plan && !charge">{{plan.value_plan | currency: 'R$'}}</span>
                <span class="value" *ngIf="charge">{{charge.value | currency: 'R$'}}</span>
                <span class="plan" *ngIf="plan">
                    PLANO {{plan.title}}&nbsp;
                    <span *ngIf="!plan.is_stock">(Índices e derivativos)</span>
                    <span *ngIf="plan.is_stock">(Ações Americanas)</span>
                </span>
            </div>
        </div>

        <form [formGroup]="formValidation">
            <div class="input input-space-min">
                <input class="input-box" type="text" placeholder="Informe seu nome completo"
                    [class.input-box-error]="submitted && errorCtr.name.errors?.required" formControlName="name"
                    (focus)="focus('focus', 'name')" (blur)="focus('unfocus', 'name')">
                <span class="error-form" *ngIf="submitted && errorCtr.name.errors?.required">
                    O campo nome é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="email" placeholder="Informe seu email" 
                [class.input-box-error]="submitted && errorCtr.email.errors?.required" formControlName="email"
                    (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
                <span class="error-form" *ngIf="submitted && errorCtr.email.errors?.required">
                    O campo email é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="email" placeholder="Confirme seu email" 
                [class.input-box-error]="submitted && errorCtr.email_confirm.errors?.required" formControlName="email_confirm"
                    (focus)="focus('focus', 'email_confirm')" (blur)="focus('unfocus', 'email_confirm')">
                <span class="error-form" *ngIf="submitted && errorCtr.email_confirm.errors?.required">
                    O campo confirmação de email é obrigatório
                </span>
            </div>
            <div class="input input-space-min">
                <input class="input-box" type="text" placeholder="CPF" 
                [class.input-box-error]="submitted && errorCtr.document.errors?.required" formControlName="document"
                    (focus)="focus('focus', 'document')" (blur)="focus('unfocus', 'document')"
                    mask="000.000.000-00||00.000.000/0000-00">
                <span class="error-form" *ngIf="submitted && errorCtr.document.errors?.required">
                    O campo confirmação de email é obrigatório
                </span>
            </div>
            <div class="input">
                <input class="input-box" type="text" placeholder="Informe seu telefone"
                    [class.input-box-error]="submitted && errorCtr.phone.errors?.required" formControlName="phone"
                    (focus)="focus('focus', 'phone')" (blur)="focus('unfocus', 'phone')"
                    mask="(00) 0000-0000||(00) 0 0000-0000">
                <span class="error-form" *ngIf="submitted && errorCtr.phone.errors?.required">
                    O campo telefone é obrigatório
                </span>
            </div>

            <div class="payment-type">
                <div class="card-payment" [class.active]="pay_method == 'CARTAO'" (click)="changePayment('CARTAO')">
                    <img src="assets/icons/credit.svg"/>
                    <span>Cartão de crédito</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'PIX'" (click)="changePayment('PIX')">
                    <img src="assets/icons/pix_logo.svg"/>
                    <span>Pix</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'BOLETO'" (click)="changePayment('BOLETO')">
                    <img src="assets/icons/boleto.svg"/>
                    <span>Boleto</span>
                </div>
                <div class="card-payment" [class.active]="pay_method == 'GLOBAL'" (click)="changePayment('GLOBAL')">
                    <img src="assets/icons/global.svg"/>
                    <span>Pagamento do exterior</span>
                </div>
            </div>

            <div class="payment-credit" *ngIf="pay_method == 'CARTAO'">
                <span class="detail">Dados do cartão</span>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe o numero do cartão" 
                    [class.input-box-error]="submitted && errorCtr.card_number.errors?.required" formControlName="card_number"
                        (focus)="focus('focus', 'card_number')" (blur)="focus('unfocus', 'card_number')"
                        mask="0000 0000 0000 0000">
                    <span class="error-form" *ngIf="submitted && errorCtr.card_number.errors?.required">
                        O campo numero do cartão é obrigatório
                    </span>
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe o nome do titular"
                        [class.input-box-error]="submitted && errorCtr.card_name.errors?.required" formControlName="card_name"
                        (focus)="focus('focus', 'card_name')" (blur)="focus('unfocus', 'card_name')">
                    <span class="error-form" *ngIf="submitted && errorCtr.card_name.errors?.required">
                        O campo nome do titular é obrigatório
                    </span>
                </div>
                <div class="card-back">
                    <div class="select">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.card_month.errors?.required">
                            <mat-select placeholder="Mês de vencimento" formControlName="card_month"
                                (focus)="focus('focus', 'card_month')" (blur)="focus('unfocus', 'card_month')">
                                <mat-option [value]="'01'">Janeiro</mat-option>
                                <mat-option [value]="'02'">Fevereiro</mat-option>
                                <mat-option [value]="'03'">Março</mat-option>
                                <mat-option [value]="'04'">Abril</mat-option>
                                <mat-option [value]="'05'">Maio</mat-option>
                                <mat-option [value]="'06'">Junho</mat-option>
                                <mat-option [value]="'07'">Julho</mat-option>
                                <mat-option [value]="'08'">Agosto</mat-option>
                                <mat-option [value]="'09'">Setembro</mat-option>
                                <mat-option [value]="'10'">Outubro</mat-option>
                                <mat-option [value]="'11'">Novembro</mat-option>
                                <mat-option [value]="'12'">Dezembro</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.card_month.errors?.required">
                            O campo mês é obrigatório
                        </span>
                    </div>
                    <div class="select">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.card_year.errors?.required">
                            <mat-select placeholder="Ano de vencimento" formControlName="card_year"
                                (focus)="focus('focus', 'card_year')" (blur)="focus('unfocus', 'card_year')">
                                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.card_year.errors?.required">
                            O campo ano é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o CVV"
                            [class.input-box-error]="submitted && errorCtr.card_cvv.errors?.required" formControlName="card_cvv"
                            (focus)="focus('focus', 'card_cvv')" (blur)="focus('unfocus', 'card_cvv')">
                        <span class="error-form" *ngIf="submitted && errorCtr.card_cvv.errors?.required"
                        mask="000">
                            O campo CVV é obrigatório
                        </span>
                    </div>
                </div>

                <div class="save-card">
                    <mat-checkbox formControlName="save_card">Salvar cartão para compras futuras</mat-checkbox>
                </div>
            </div>
        </form>

        <form [formGroup]="formCoupon" class="coupon">
            <span class="detail">Cupom</span>
            <div class="coupon-form">
                <div class="input">
                    <input class="input-box" type="text" placeholder="Informe o cupom"
                        [class.input-box-error]="applied && errorCtrCoupon.coupon.errors?.required" formControlName="coupon"
                        (focus)="focus('focus', 'coupon')" (blur)="focus('unfocus', 'coupon')" mask="" (keyup)="toUpper()">
                    <span class="error-form" *ngIf="applied && errorCtrCoupon.coupon.errors?.required">
                        Não é possível enviar um cupom em branco
                    </span>
                    <span class="error-form" *ngIf="applied && !errorCtrCoupon.coupon.errors?.required && errorCtr.email.errors?.required">
                        Preencha o campo de E-mail
                    </span>
                </div>
                <button class="button-secondary" (click)="applyCoupon()">Aplicar</button>
            </div>
        </form>

        <div class="platforms" *ngIf="plan && plan.value_black_arrow && pay_method != 'GLOBAL'">
            <span class="detail">Selecione a plataforma de negociação:</span>
            <div class="card-platform" [class.active]="platform_selected == 'MT5'" (click)="changePlatform('MT5')">
                <div class="logo"><img src="assets/icons/metatrader.svg" /></div>
                <div class="description">
                    <span class="title">Meta trader 5</span>
                    <span class="value">{{metaTraderValue | currency: 'R$'}}</span>
                    <span class="obs"><span class="color-danger">*</span> Disponibilizado imediatamente após a confirmação do pagamento</span>
                </div>
            </div>
            <div class="card-platform" [class.active]="platform_selected == 'BA'" (click)="changePlatform('BA')">
                <div class="logo"><img src="assets/icons/blackarrow.svg" /></div>
                <div class="description">
                    <span class="title">Black Arrow</span>
                    <span class="value">{{blackArrowValue | currency: 'R$'}}</span>
                    <span class="obs"><span class="color-danger">*</span> Disponibilizado em até 24 horas no horário comercial após a confirmação do pagamento</span>
                </div>
            </div>
        </div>

        <form [formGroup]="formValidation">
            <div class="payment-credit" *ngIf="pay_method == 'CARTAO'">
                <div class="detail installment" *ngIf="(!charge && plan.installment_count) || (charge && charge.installment_count)">Parcelamento</div>
                <div class="split" *ngIf="(!charge && plan.installment_count) || (charge && charge.installment_count)">
                    <div class="select input-space-min">
                        <mat-form-field [class.input-box-error]="submitted && errorCtr.installment_count.errors?.required">
                            <mat-select placeholder="Parcelas" formControlName="installment_count"
                                (focus)="focus('focus', 'installment_count')" (blur)="focus('unfocus', 'installment_count')">
                                <mat-option *ngFor="let item of rangeList()" [value]="item">Em {{item}}x de {{(getFinalValue()/item) | currency: 'R$'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtr.installment_count.errors?.required">
                            O campo Estado é obrigatório
                        </span>
                    </div>
                </div>
            </div>
            
            <div class="payment-code" *ngIf="pay_method == 'PIX' || pay_method == 'BOLETO'">
                <div class="warning" *ngIf="pay_method == 'PIX'">
                    <p>Ao prosseguir com a configuração da sua conta de negociação, você receberá um código de barras e um código Pix exclusivos. O código de barras pode ser escaneado usando qualquer aplicativo de pagamento Pix, enquanto o código Pix pode ser copiado e colado no seu aplicativo bancário.</p>
                    <p>Por favor, observe que o código gerado tem uma validade de 15 minutos. Realize o pagamento dentro deste período para evitar inconvenientes.</p>
                    <p>Após a confirmação do seu pagamento, sua conta de negociação será ativada dentro de até 30 minutos. Após a configuração, enviaremos uma confirmação por e-mail.</p>
                    <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco através do e-mail suporte&#64;insiderinveste.com.br.</p>
                </div>
                <div class="warning" *ngIf="pay_method == 'BOLETO'">
                    <p>Ao prosseguir com a configuração da sua conta de negociação, um boleto será gerado automaticamente com vencimento para daqui a 5 dias.</p>
                    <p>Após a configuração, enviaremos uma confirmação por e-mail.</p>
                    <p>Estamos aqui para ajudar em cada etapa do processo. Se tiver alguma dúvida ou precisar de assistência adicional, entre em contato conosco.</p>
                </div>
            </div>
        </form>

        <div class="payment-credit global" *ngIf="pay_method == 'GLOBAL'">
            <span class="detail">Pagamentos do exterior</span>
            <p>Para pagamentos realizados no exterior, entre em contato com o nosso suporte através do e-mail suporte&#64;insiderinveste.com.br, que disponibilizaremos um link exclusivo para realizar o pagamento em outra moeda.</p>
        </div>

        <div class="buy-detail" *ngIf="pay_method != 'GLOBAL'">
            <span class="detail">Detalhes da compra</span>
            <div class="buy">
                <div class="">
                    <span class="title">Insider Investe - Mesa proprietária</span>
                    <span class="title selected-platform" *ngIf="plan && plan.value_black_arrow">
                        + Plataforma
                        <span *ngIf="platform_selected == 'MT5'">Meta trader 5 - {{metaTraderValue | currency: 'R$'}}</span>
                        <span *ngIf="platform_selected == 'BA'">Black Arrow - {{blackArrowValue | currency: 'R$'}}</span>
                    </span>
                    <div class="coupon-label" *ngIf="coupon">
                        <span> {{ coupon.name }} - </span>
                        <span *ngIf="coupon.type_discount == 0">{{coupon.value_discount | currency: 'R$'}}</span>
                        <span *ngIf="coupon.type_discount == 1">{{coupon.value_discount | number:'1.0-0' }}%</span>
                        <i class="icon-close fa-solid fa-xmark" name="close-outline" (click)="removeCoupon()"></i>
                    </div>
                </div>
                <span class="price" *ngIf="plan">{{getFinalValue() | currency: 'R$'}}</span>
            </div>
        </div>

        <div class="terms">
            <form [formGroup]="formValidation">
                <mat-checkbox formControlName="accept_term">Declaro que li e concordo com os</mat-checkbox>&nbsp;<strong (click)="openModal()">Termos e Condições</strong>
                <span class="error-form" *ngIf="submitted && errorCtr.accept_term.errors?.required">
                    O aceite dos termos e condições é obrigatório
                </span>
            </form>
        </div>
        <div class="button-center" *ngIf="pay_method != 'GLOBAL'">
            <button class="button-primary" (click)="submit()" [disabled]="disableSubmitButton">Comprar agora</button>
        </div>
    </div>
</div>