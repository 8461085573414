<div class="content-body" *ngIf="withdraw">
    <div class="button-close" (click)="closeModal()"><i class="fa-solid fa-xmark over-text"></i></div>
    <div class="content-body-title">Método de pagamento</div>
    <div class="content-body-text over-text">
        <div class="tab">
            <span class="item" (click)="changeView('pix')" [class.active]="view == 'pix'">PIX</span>
            <span class="item" (click)="changeView('deposit')" [class.active]="view == 'deposit'">Depósito</span>
        </div>
        <div class="pages">
            <form [formGroup]="formPix" *ngIf="view == 'pix'">
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe a chave do pix"
                        [class.input-box-error]="submitted && errorCtrlPix.pix.errors?.required" formControlName="pix"
                        (focus)="focus('focus', 'pix', formPix)" (blur)="focus('unfocus', 'pix')">
                    <span class="error-form" *ngIf="submitted && errorCtrlPix.pix.errors?.required">
                        O campo pix é obrigatório
                    </span>
                </div>
                <div class="button-center">
                    <button class="button-primary" (click)="submit('pix')">Solicitar</button>
                </div>
            </form>
            <form [formGroup]="formDeposit" *ngIf="view == 'deposit'">
                <div class="form">
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o banco"
                            [class.input-box-error]="submitted && errorCtrlDeposit.bank.errors?.required" formControlName="bank"
                            (focus)="focus('focus', 'bank', formDeposit)" (blur)="focus('unfocus', 'bank')">
                        <span class="error-form" *ngIf="submitted && errorCtrlDeposit.bank.errors?.required">
                            O campo banco é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe a agência"
                            [class.input-box-error]="submitted && errorCtrlDeposit.agency.errors?.required" formControlName="agency"
                            (focus)="focus('focus', 'agency', formDeposit)" (blur)="focus('unfocus', 'agency')">
                        <span class="error-form" *ngIf="submitted && errorCtrlDeposit.agency.errors?.required">
                            O campo agência é obrigatório
                        </span>
                    </div>
                    <div class="input input-space-min">
                        <input class="input-box" type="text" placeholder="Informe o número da conta"
                            [class.input-box-error]="submitted && errorCtrlDeposit.account.errors?.required" formControlName="account"
                            (focus)="focus('focus', 'account', formDeposit)" (blur)="focus('unfocus', 'account')">
                        <span class="error-form" *ngIf="submitted && errorCtrlDeposit.account.errors?.required">
                            O campo número da conta é obrigatório
                        </span>
                    </div>
                    <div class="select">
                        <mat-form-field [class.input-box-error]="submitted && errorCtrlDeposit.type_transfer.errors?.required">
                            <mat-select placeholder="Tipo de conta" formControlName="type_transfer"
                                (focus)="focus('focus', 'type_transfer')" (blur)="focus('unfocus', 'type_transfer')">
                                <mat-option [value]="1">Conta Corrente</mat-option>
                                <mat-option [value]="2">Conta Poupança</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="error-form" *ngIf="submitted && errorCtrlDeposit.type_transfer.errors?.required">
                            O campo tipo é obrigatório
                        </span>
                    </div>
                </div>
                <div class="button-center">
                    <button class="button-primary" (click)="submit('deposit')">Solicitar</button>
                </div>
            </form>
        </div>
    </div>
</div>