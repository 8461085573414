<app-auth-header></app-auth-header>

<div class="content">
    <div class="title">
        Downloads
    </div>

    <div class="row">
        <div class="col-12 col-md-6 col-xl-4">
            <div class="card">
                <div class="logo">
                    <img src="assets/icons/sidenav-logo.svg">
                    <span>Insider Invest Real Time</span>
                </div>
                <button class="button-primary" [routerLink]="'/configurar-conta'">
                    <img src="assets/icons/sidenav-download.svg" alt="Download">
                    <span>Baixar Real Time</span>
                </button>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4">
            <div class="card">
                <div class="logo">
                    <img src="assets/images/plat_mt5_mini.png">
                    <span>Meta Trader 5</span>
                </div>
                <button class="button-primary" (click)="downloadMT5()">
                    <img src="assets/icons/sidenav-download.svg" alt="Download">
                    <span>Baixar Meta Trader 5</span>
                </button>
            </div>
        </div>
    </div>
</div>