import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { SidenavService } from "../components/sidenav/sidenav.service";
import { PaymentModel } from "../models/payment.model";
import { PayModel } from "../models/pay.model";
import { AddressModel } from "../models/address.model";

@Injectable({
    providedIn: 'root'
})
export class PaymentCommand {

    private api: ApiRequestService;

    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    pay(pay: PaymentModel, params: string = null) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/method-payment/make-payment/");
        let post: any = pay;
        post.params = params;

        return new Promise<PayModel>(async (resolve) => {
            this.api.post<any>(post).subscribe({
                next: async (result: PayModel) => {
                    result.valid = true;
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve({valid: false, message: err.error.message} as PayModel);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    payBlackFriday(pay: PaymentModel, params: string = null) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/method-payment-black-friday/make-payment/");
        let post: any = pay;
        post.params = params;

        return new Promise<PayModel>(async (resolve) => {
            this.api.post<any>(post).subscribe({
                next: async (result: PayModel) => {
                    result.valid = true;
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve({valid: false, message: err.error.message} as PayModel);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    getPix(id: number) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/method-payment/pix/${id}/`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();

                    resolve(null);
                }
            });
        });
    }

    getHistory(){
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/method-payment/history-payment/`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();

                    resolve(null);
                }
            });
        });
    }

    getCreditCard() {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/method-payment/credit-card/`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();

                    resolve(null);
                }
            });
        });
    }

    deleteCreditCard(id: number) {
        this.sidenavService.enableProgressBar();
        this.api = new ApiRequestService(this.http, "/method-payment/credit-card/");

        return new Promise(async (resolve) => {

            this.api.delete(id).subscribe({
                next: async (result: any) => {
                    resolve(true);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    public viaCep(cep: String) {
        return new Promise<any>((resolve) => {
            this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`).subscribe({
                next: async (result: AddressModel) => {
                    if(result.erro)
                        resolve(null);
                    resolve(result);
                },
                error: (err) => {
                    resolve(null);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }
                }
            });
        });
    }
}