import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TournamentCommand } from 'src/app/commands/tournament.command';
import { RankingTournamentModel, TournamentModel } from 'src/app/models/tournament.model';

@Component({
  selector: 'app-rank-championship',
  templateUrl: './rank-championship.component.html',
  styleUrl: './rank-championship.component.scss'
})
export class RankChampionshipComponent {
  tournament: TournamentModel;
  listRanking: RankingTournamentModel[] = [];
  listPodium: RankingTournamentModel[] = [];
  count: number = 0;
  pageIndex: number = 0;
  pagination = {
    status: false,
    next: '',
    prev: '',
  };

  constructor(
    private tournamentCommand: TournamentCommand,
    private routeInfo: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.routeInfo.params.subscribe(async (params: any) => {
      if (params.id)
        Promise.all([
          this.tournamentCommand.get(params.id),
          this.tournamentCommand.getRanking(params.id, 18),
        ]).then(([tournament, ranking]: [TournamentModel, any]) => {
          if(tournament){
            this.tournament = tournament;

            if(ranking && ranking.results){
              this.count = ranking.count - 3;
              this.listRanking = ranking.results.slice(3) as RankingTournamentModel[];
              this.listPodium = ranking.results.slice(0, 3) as RankingTournamentModel[];
            }
            if(ranking.next || ranking.previous){
              this.pagination.status = true;
              this.pagination.next = ranking.next ?? '';
              this.pagination.prev = ranking.previous ?? '';
            }
          } else {
            this.router.navigateByUrl('/not-found');
          }
        });
    });
  }

  alterPage(evt: PageEvent) {
    let page = (evt.pageIndex > evt.previousPageIndex? this.pagination.next: this.pagination.prev).replace('limit=18', 'limit=15');
    this.pageIndex = evt.pageIndex;
    if(page.endsWith('offset=3'))
      page = page.replace('limit=15&offset=3', 'limit=18');
    if(page){
      this.tournamentCommand.pageRanking(this.tournament.id, page).then((result: any) => {
        if(page.endsWith('limit=18')){
          this.listRanking = result.results.slice(3) as RankingTournamentModel[];
          this.listPodium = result.results.slice(0, 3) as RankingTournamentModel[];
        } else {
          this.listRanking = result.results as RankingTournamentModel[];
        }

        if(result.next || result.previous){
          this.pagination.status = true;
          this.pagination.next = result.next ?? '';
          this.pagination.prev = result.previous ?? '';
        }
      });
    }
  }

  calcRank(pos: number): number {
    return pos + 4 + this.pageIndex*15;
  }

  initialsName(str: string) {
    const names = str.split(' ').filter(name => name.length>0);
    if(names.length > 1)
      return (names[0].charAt(0) + names[1].charAt(0)).toLocaleUpperCase();
    return (names[0].charAt(0) + names[0].charAt(1)).toLocaleUpperCase();
  }
}
