import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginCommand } from 'src/app/commands/login.command';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from '../alert/alert.service';
import { SidenavService } from '../sidenav/sidenav.service';

@Component({
  selector: 'app-sidenav-web',
  templateUrl: './sidenav-web.component.html',
  styleUrls: ['./sidenav-web.component.scss']
})
export class SidenavWebComponent {
  retract: boolean = true;
  itemSelected: string = 'home';

  constructor(
    private router: Router,
    public pagesContentService: PagesContentService,
    private alertComponent: AlertService,
    private sidenavService: SidenavService,
  ){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        const path = event.url.split('?')[0].split('/')[1];
        if(path)
          this.itemSelected = path
      }
    });
  }

  openPage(page: string){

    let page_block = this.pagesContentService.getBlock(page);

    if (page_block) {
      this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message, '', 3);
    } else {
      this.itemSelected = page;
      this.router.navigate([`/${page}`]);
    }
  }

  toggleMenu() {
    this.retract = !this.retract;
    this.sidenavService.onLeftSidenavToggle();
  }
}
