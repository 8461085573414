import { Injectable } from "@angular/core";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { HttpClient } from "@angular/common/http";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { UserRecoveryPasswordModel } from "../models/user-recovery-password.model";
import { UserNewPasswordModel } from "../models/user-new-password.model";
import { UserAlterPassword } from "../models/user-alter-password.model";
import { AlertService } from "../components/alert/alert.service";
import { SidenavService } from "../components/sidenav/sidenav.service";


@Injectable({
    providedIn: 'root'
})
export class UserPasswordCommand {

    private api: ApiRequestService;

    constructor(
        private alert: AlertService,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private sidenavService: SidenavService,
    ) {
    }

    requestRecovery(userRecovery: UserRecoveryPasswordModel) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {

            this.api = new ApiRequestService(this.http, "/account/password-reset/");

            this.api.post<any>(userRecovery).subscribe({
                next: (result: any) => {
                    this.alert.presentAlert("success", "Concluído!", "Enviamos as instrução de recuperação de senha para o seu e-mail", "Continuar");
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {
                    if (err.status != 404) {
                        this.requestError.handleError(err);
                    }

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    generateNewPassword(userNewPassword: UserNewPasswordModel) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {

            this.api = new ApiRequestService(this.http, "/account/password-reset/confirm/");
            
            this.api.post<any>(userNewPassword).subscribe({
                next: (result: any) => {
                    this.alert.presentAlert("success", "Concluído!", "Sua nova senha foi gerada com sucesso", "Continuar");
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        if(err.status != 400)
                            this.requestError.handleError(err);
                        else
                            resolve({
                                message: 'Informe uma senha mais forte, que contenha caracteres especiais, letras maiúsculas e números. Ela também não pode conter uma palavra que esteja presente no seu nome ou e-mail.'
                            });
                    } else
                        this.alert.presentAlert("error", "Ops!", "A sua solicitação é inválida ou seu token expirou, solicite uma nova recuperação de senha e tente novamente!", "Continuar");

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }

    alterPassword(alterPassword: UserAlterPassword) {
        this.sidenavService.enableProgressBar();
        return new Promise(async (resolve) => {

            this.api = new ApiRequestService(this.http, "/account/authentication/user/info/");

            this.api.patch<any>(alterPassword, false).subscribe({
                next: (result: any) => {
                    this.alert.presentAlert("success", "Concluído!", "Sua senha foi alterada com sucesso!", "Continuar");
                    resolve(result);
                    this.sidenavService.disableProgressBar();
                },
                error: (err) => {

                    if (err.status != 404) {
                        if(err.status != 400)
                            this.requestError.handleError(err);
                        else
                            resolve({
                                message: 'Informe uma senha mais forte, que contenha caracteres especiais, letras maiúsculas e números. Ela também não pode conter uma palavra que esteja presente no seu nome ou e-mail.'
                            });
                    } else
                        this.alert.presentAlert("error", "Ops!", "A sua senha atual está inválida", "Tentar novamente");

                    resolve(null);
                    this.sidenavService.disableProgressBar();
                }
            });
        });
    }
}