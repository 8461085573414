import { Component, OnInit } from '@angular/core';
import { ResetInfoCommand } from 'src/app/commands/reset-info.command';
import { ResetInfoModel } from 'src/app/models/reset-info.model';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-reset-info',
  templateUrl: './reset-info.component.html',
  styleUrls: ['./reset-info.component.scss']
})
export class ResetInfoComponent implements OnInit {

  resetInfo: ResetInfoModel;
  
  constructor(private resetInfoCommand: ResetInfoCommand, 
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.resetInfoCommand.get().then((result: ResetInfoModel) => {
      this.resetInfo = result;
    })
  }

  openLinkReset() {
    window.location.href = this.resetInfo.link;
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
