<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        Poder de compra
    </div>
    <div>
        <p>No âmbito do plano de ações, as contas disponíveis não possuem alavancagem. Ou seja, a alavancagem é de 1:1</p> 
        <p>É de responsabilidade exclusiva do trader calcular os lotes que podem ser operados com base em seu saldo atual. Esta responsabilidade inclui a avaliação cuidadosa das posições que o trader pretende assumir, assegurando que estas não ultrapassem o saldo disponível na conta.</p> 
        <p>Para calcular o tamanho de sua posição é necessário multiplicar a quantidade de contratos com o valor atual da ação. Essa regra leva em consideração todas as posições que o trader deseja abrir, todas elas somadas não deve ultrapassar o saldo atual da conta. É crucial que cada trader compreenda e gerencie seu risco adequadamente para evitar reprovações.</p> 
        <p>Nossa plataforma oferece ferramentas e suporte para ajudar os traders, mas a responsabilidade final pela gestão do risco e cálculo dos lotes é do próprio trader.</p>
    </div>
    <br><br>
    <div class="title">
        Cálculo
    </div>
    <div>
        <p>A sua conta possui um saldo conforme o plano contratado:</p> 
        <p>
            <strong>Light Trader: </strong>$50.000,00<br>
            <strong>Start Trader: </strong>$100.000,00<br>
            <strong>Pro Trader: </strong>$200.000,00<br>
        </p> 
        <p><strong>Em nosso exemplo vamos tomar como exemplo o plano Start Trader, que possui o saldo de $100.000,00.</strong></p> 
        <p>Para saber se a sua operação irá ultrapassar o seu poder de compra, obtenha o valor da ação, conforme a imagem abaixo, estamos verificando o preço de Apple que está ao preço de $222,54.</p>

        <div class="img-center">
            <a href="assets/images/account_leverage.png" target="_blank">
                <img class="conta" src="assets/images/account_leverage.png" alt="Poder de compra">
            </a>
        </div>

        <p>Agora que temos o preço da ação precisamos realizar o cálculo: 
            <br>
            <strong>preco_acao * quantidade_de_contratos = tamanho_da_posicao</strong>
            <br><br>
            <i>A quantidade de contratos é definida por você pelo tamanho da posição que você deseja assumir.</i>
        </p>
    </div>
    <br><br>
    <div class="title">
        Exemplo 1
    </div>
    <p>Neste exemplo eu quero que entrar em minha operação com 500 contratos de Apple. Então vamos ao cálculo:</p>
    <p><strong>$222,54 * 500 = $111.270,00</strong></p>
    <p>Observe que o tamanho da minha posição ultrapassou o saldo da minha conta que é de $100.000,00. Neste caso a minha operação será encerrada pelo Insider Investe Real Time e haverá perca de capital referente ao spread ou variação do mercado no momento do encerramento. Se a operação não for encerrada pelo Real Time, a operação será avaliada pelo nosso time podendo ser invalidada e não ser considerada para avaliação.</p>
    <br><br>
    <div class="title">
        Exemplo 2
    </div>
    <p>Neste exemplo eu já compreendo como calcular o meu poder de compra e em como gerenciar o meu risco portanto, eu vou entrar na operação com apenas 200 contratos.</p>
    <p><strong>$222,54 * 200 = $44.508,00</strong></p>
    <p>Observe que o tamanho da minha posição não ultrapassou o saldo da minha conta, pelo contrário, agora eu tenho margem para abrir novas operações. Neste exemplo, ao assumir um tamanho de posição de $44.508,00 eu ainda tenho um saldo $55.492,00 para abrir novas posições.</p>
    <br><br>
    <div class="title">
        Conclusão
    </div>
    <p>O tamanho da posição que o trader deseja assumir leva em consideração <strong>TODAS</strong> as operações que estão abertas, ou seja, caso deseje abrir mais de uma operação ao mesmo tempo, você precisa que o tamano da sua posição seja menor que o saldo da sua conta para que você tenha poder de compra para abrir novas operações.</p>
</div>